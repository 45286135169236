import React, { useContext, useReducer } from 'react';
import { loadState, saveState } from 'library/helpers/localStorage';
import shortid from 'shortid';

export const BusinessOrderCartContext = React.createContext({});

const initialState = {
  businessId: null,
  items: [],
  resetKey: shortid.generate(),
  address: null,
  slot: null,
  checkoutOption: "DELIVERY",
  paymentOption: "COD"
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'INCREMENT':
    case 'DECREMENT':
    case 'REMOVE':
    case 'ADDRESS':
    case 'SLOT':
    case 'PAYMENT_OPTION':
    case 'CHECKOUT_OPTION': {
      let newState = { ...state, ...action.payload, resetKey: shortid.generate() }
      if (!newState.items.length) {
        newState = { ...initialState }
      }
      saveState("_business_cart", newState)
      return newState
    }
    case 'SYNC': {
      let newState = { ...state, ...action.payload }
      if (!newState.items.length) {
        newState = { ...initialState }
      }
      saveState("_business_cart", newState)
      return newState
    }
    case 'RESET': {
      saveState("_business_cart", initialState)
      return { ...initialState };
    }
    default: { }
  }
}

const BusinessOrderCartProvider = (props) => {
  const cartFromLocalStorage = loadState("_business_cart") || initialState;
  const [state, dispatch] = useReducer(reducer, cartFromLocalStorage)

  const findItemIndexInCart = (productId, variantId) => {
    return state.items?.findIndex(item => item.variantId === variantId && item.productId === productId)
  }

  const incrementItem = (businessId, productId, variantId) => {
    const existingItemIndex = findItemIndexInCart(productId, variantId)
    let newItems = [...state.items]
    if (existingItemIndex >= 0) {
      newItems[existingItemIndex].quantity = newItems[existingItemIndex].quantity + 1
    }
    else {
      newItems = [...state.items, { productId, variantId, quantity: 1 }]
    }
    dispatch({ type: 'INCREMENT', payload: { businessId, items: newItems } });
  }

  const decrementItem = (businessId, productId, variantId) => {
    const existingItemIndex = findItemIndexInCart(productId, variantId)
    let newItems = [...state.items]
    if (existingItemIndex >= 0) {
      newItems[existingItemIndex].quantity = newItems[existingItemIndex].quantity - 1
    }
    else {
      newItems = [...state.items, { productId, variantId, quantity: 1 }]
    }
    dispatch({ type: 'DECREMENT', payload: { businessId, items: newItems.filter(i => i.quantity > 0) } });
  }


  const removeItem = (businessId, productId, variantId) => {
    const newItems = state.items.filter(item => !(item.variantId === variantId && item.productId === productId))
    dispatch({ type: 'REMOVE', payload: { businessId, items: newItems } });
  }

  const syncItems = (businessId, validItems = []) => {
    const newItems = state.items.filter(item => validItems.find(valid => valid.productId === item.productId && valid.variantId === item.variantId))
    dispatch({ type: 'SYNC', payload: { businessId, items: newItems } });
  }

  const resetCart = () => {
    dispatch({ type: 'RESET' });
  }

  const setCheckoutOption = (checkoutOption) => {
    if (checkoutOption === "PICKUP") {
      dispatch({ type: 'CHECKOUT_OPTION', payload: { checkoutOption: checkoutOption, address: null, slot: null } });
    }
    dispatch({ type: 'CHECKOUT_OPTION', payload: { checkoutOption: checkoutOption } });
  }

  const setPaymentOption = (paymentOption) => {
    dispatch({ type: 'PAYMENT_OPTION', payload: { paymentOption: paymentOption } });
  }

  const setDeliveryAddress = (address) => {
    dispatch({ type: 'ADDRESS', payload: { address: address, slot: null } });
  }

  const setDeliverySlot = (slot) => {
    dispatch({ type: 'SLOT', payload: { slot: slot } });
  }

  const isItemInCart = (productId, variantId) => {
    return findItemIndexInCart(productId, variantId) > -1
  }

  const findItemInCart = (productId, variantId) => {

    const index = findItemIndexInCart(productId, variantId);
    return index > -1 ? state.items[index] : null
  }

  return (
    <BusinessOrderCartContext.Provider
      value={{
        state,
        incrementItem,
        decrementItem,
        removeItem,
        resetCart,
        isItemInCart,
        findItemInCart,
        setDeliveryAddress,
        setDeliverySlot,
        setPaymentOption,
        setCheckoutOption,
        syncItems
      }}
    >
      <>{props.children}</>
    </BusinessOrderCartContext.Provider>
  );
};

export const useBusinessOrderCart = () => useContext(BusinessOrderCartContext)

export default BusinessOrderCartProvider;
