import React, { useState } from 'react';
import { Marker } from 'react-google-maps';
// import BusinessInfoCard from './BusinessInfoCard';

const StaticMapWithDetailedMarker = ({ data }) => {
  const [isOpen, setIsOpen] = useState(false);

  const infoWindowToggle = (index) => {
    setIsOpen(!isOpen);
  };

  return data.map((item, index) => {
    return (
      <Marker
        key={index}
        position={{
          lat: parseFloat(item.location.coordinates[0]),
          lng: parseFloat(item.location.coordinates[1]),
        }}
        onClick={() => infoWindowToggle(item.id)}
      >
      </Marker>
    );
  });
};

export default StaticMapWithDetailedMarker;
