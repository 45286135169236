import React from 'react'
import SiteDataRichTextWrapper from './SiteDataRichText.style';

const SiteDataRichText = ({ html = "", style = {} }) => {
    return (
        <SiteDataRichTextWrapper style={style}>
            <div dangerouslySetInnerHTML={{ __html: html }} />
        </SiteDataRichTextWrapper>
    )
}


export default SiteDataRichText
