import React, { useEffect } from "react";
import { Col, Row } from "antd";
import { generatePath } from "react-router-dom";

import useDataApi from "library/hooks/useDataApi";
import { addQueryToUrl } from "library/helpers/url_handler";
import { STORE_PRODUCT_PAGE } from "settings/constant";
import { useStoreCart } from "context/StoreCartProvider";
import { StoreProductLoader } from "components/UI/ContentLoader/ContentLoader";
import StickyBottomBox from "components/StickeyBottomBox/StickyBottomBox";
import SectionGrid from "components/SectionGrid/SectionGrid";
import ProductCard from "components/ProductCard/ProductCard";
import StoreNavMenuBar from "components/StoreSubCategoryNavMenu/NavMenuBar";
import StoreWrapper, { ProductsWrapper } from "./StoreSearch.style";
import SortAndFilter, { MobileSortAndFilter } from "./SortAndFilter";
import { get } from "lodash";

const StoreSearch = ({ history, location }) => {
  const defaultSearchQuery = { status: "ACTIVE", search: true };
  const { addOrRemoveInCart, setShowCart } = useStoreCart();
  const url = `${process.env.REACT_APP_API_URL}/search/store-product`;
  const { data, doFetch, loading, showLoadMore, loadMoreData, limit } =
    useDataApi(
      addQueryToUrl(url + location.search || "", { ...defaultSearchQuery }),
      60
    );

  const products = data || [];

  useEffect(() => {
    doFetch(
      addQueryToUrl(url + location.search || "", { ...defaultSearchQuery }) ||
        ""
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  return (
    <StoreWrapper>
      <StoreNavMenuBar />
      <ProductsWrapper>
        <Row gutter={24}>
          <Col xs={0} sm={8} md={8} lg={6} xl={4}>
            <SortAndFilter history={history} location={location} />
          </Col>
          <Col xs={24} sm={16} md={16} lg={18} xl={20}>
            <SectionGrid
              data={products}
              loading={loading}
              limit={limit}
              showLoadMoreButton={showLoadMore}
              handleLoadMore={loadMoreData}
              columnStyle={{ px: [0, 10, 10, 10, 12, 12] }}
              columnWidth={[0.5, 0.5, 0.33, 0.33, 0.2, 0.2]}
              placeholder={<StoreProductLoader />}
              renderComponent={(item) => (
                <ProductCard
                  styleProps={{ borderRadius: [0, 10, 12, 20, 20, 20] }}
                  link={`${generatePath(STORE_PRODUCT_PAGE, {
                    productId: item.id,
                    variantId: get(item, "variants.0.id"),
                  })}`}
                  onActionButtonClick={(variant) => {
                    addOrRemoveInCart(item.id, variant.id, 1, item);
                    setShowCart(true);
                  }}
                  {...item}
                />
              )}
            />
          </Col>
          <Col xs={24} sm={0}>
            <StickyBottomBox>
              <MobileSortAndFilter history={history} location={location} />
            </StickyBottomBox>
          </Col>
        </Row>
      </ProductsWrapper>
    </StoreWrapper>
  );
};

export default StoreSearch;
