import React from "react";
import { useParams, generatePath } from "react-router-dom";
import { DateTime } from "luxon";
import { get } from "lodash";
import { Col, Row, Tag, Empty, Result, Card, Divider } from "antd";

import { getStateFromUrl } from "library/helpers/url_handler";
import useDataApi from "library/hooks/useDataApi";
import {
  formatCurrency,
  getStoreOrderStateDetail,
} from "library/helpers/utils";
import {
  HOME_PAGE,
  MY_ORDERS_PAGE,
  STORE_PRODUCT_PAGE,
} from "settings/constant";
import Image from "components/UI/Image/Image";
import Text from "components/UI/Text/Text";
import Box from "components/UI/Box/Box";
import Button from "components/UI/Button/Button";
import { useBaseData } from "context/BaseDataProvider";
import orderFailedImage from "assets/images/order-failed.jpg";
import Container from "components/UI/Container/Container";
import Loader from "components/Loader/Loader";
import TextLink from "components/UI/TextLink/TextLink";
import Heading from "components/UI/Heading/Heading";
import { DiskountoCoinIcon } from "components/Icon/Icon";
import { OrderDetailsWrapper } from "./Order.style";

const OrderDetails = ({ history, location }) => {
  const { orderId, subOrderId } = useParams();
  const { orderSuccess } = getStateFromUrl(location);
  const { data = [], loading } = useDataApi(
    `${process.env.REACT_APP_API_URL}/store/my-orders/${orderId}`,
    1000
  );

  if (loading) {
    return <Loader />;
  }

  if (!loading && data.length === 0) {
    if (String(orderSuccess) === "true") {
      return (
        <>
          <Box flexBox flexDirection="column" flex={1} mb={2} bg="#f2f2f2">
            <Result
              status={"error"}
              title="Your payment could not be verified"
              subTitle={
                <>
                  <div>
                    If your payment was deducted then if will be automatically
                    refunded to the source within 3-5 working days.
                  </div>
                  <div>
                    Please contact support for more information or try again
                    later.
                  </div>
                  <div>Reference ID : {orderId}</div>
                </>
              }
              extra={
                <Button
                  type="primary"
                  onClick={() => window.location.replace("/")}
                >
                  Back to Home
                </Button>
              }
            />
          </Box>
          <Box flexBox justifyContent="center">
            <Image src={orderFailedImage} width="500px" alt="Order Failed" />
          </Box>
        </>
      );
    }
    return (
      <Row gutter={30}>
        <Col xs={24} lg={16} push={4}>
          <Box height="50vh" marginTop="20vh">
            <Empty description="You have not placed any order !">
              <Button onClick={() => history.push(HOME_PAGE)}>Shop Now</Button>
            </Empty>
          </Box>
        </Col>
      </Row>
    );
  }

  const subOrder = data.find((order) => order.subOrderId === subOrderId);
  const otherSubOthers = data.filter(
    (order) => order.subOrderId !== subOrderId
  );
  return (
    <OrderDetailsWrapper>
      <Container fluid={false}>
        {String(orderSuccess) === "true" && (
          <Box my={2} bg="#f2f2f2">
            <Result
              status={"success"}
              title={
                <Text
                  fontSize={["16px", "18px", "20px", "22px", "24px"]}
                  content="Congratulations! Your order is successful"
                />
              }
              subTitle={
                <Text
                  fontSize={["12px", "12px", "14px"]}
                  content={`Order ID: ${orderId}`}
                />
              }
              extra={
                <Button
                  onClick={() =>
                    history.push(`${MY_ORDERS_PAGE}?orderType=store`)
                  }
                >
                  View all orders
                </Button>
              }
            />
          </Box>
        )}
        {String(orderSuccess) !== "true" && (
          <Box my={3}>
            <TextLink
              to={`${MY_ORDERS_PAGE}?orderType=store`}
              fontWeight={300}
              fontSize={16}
              content="< Back to orders"
            />
          </Box>
        )}
        <ProductHeading subOrders={!!subOrder ? [subOrder] : otherSubOthers} />
        {!!subOrder && <ProductCard order={subOrder} />}
        {!!subOrder && otherSubOthers.length > 0 && (
          <Heading mt={4} content="Other Items in this order" />
        )}
        {otherSubOthers.map((subOrder) => (
          <ProductCard key={subOrder.id} order={subOrder} />
        ))}
      </Container>
    </OrderDetailsWrapper>
  );
};

const ProductHeading = ({ subOrders = [] }) => {
  const orderId = get(subOrders, "0.orderId", "");
  const subOrderId = get(subOrders, "0.subOrderId", "");
  const createdAt = get(subOrders, "0.createdAt", "");
  const address = get(subOrders, "0.address", "");
  const customerToPay = subOrders.reduce(
    (sum, current) => sum + current.pricing.customerToPay,
    0
  );
  const loyalityReward = subOrders.reduce(
    (sum, current) => sum + current.reward.self.loyality,
    0
  );

  return (
    <Card>
      <Row gutter={30}>
        <Col xs={24} md={7}>
          <Box>
            <Text fontWeight="600" content="Order ID" />
            <Text content={[orderId, subOrderId].filter(Boolean).join("-")} />
          </Box>
          <Box mt={2}>
            <Text fontWeight="600" content="Ordered At" />
            <Text
              content={DateTime.fromISO(createdAt).toFormat(
                "dd/MM/yyyy hh:mm a"
              )}
            />
          </Box>
          <Box mt={2}>
            <Text fontWeight="600" content={"Order Amount"} />
            <Text content={formatCurrency(customerToPay, 2)} />
          </Box>
        </Col>
        <Col xs={0} md={1}>
          <Divider type="vertical" />
        </Col>
        <Col xs={24} md={8}>
          <Box flexBox flex={1} flexDirection="column">
            <Text fontWeight="600" content="Your Rewards" />
            <Box flexBox flexDirection="row" alignItems="center">
              <DiskountoCoinIcon />
              <Text ml={1} content={loyalityReward} />
            </Box>
          </Box>
        </Col>
        <Col xs={0} md={1}>
          <Divider type="vertical" />
        </Col>
        <Col xs={24} md={7}>
          <Box
            mt={2}
            flexBox
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box flexBox flexDirection="column">
              <Text fontWeight="600" content="Delivery Address" />
              <Text content={`${address.name}`} />
              <Text as="address" content={`${address.address}`} />
              <Text
                as="address"
                content={`${address.city} ${address.state} ${address.pin}`}
              />
              <Text content={`Mobile: ${address.mobileNo}`} />
            </Box>
            {/* <Button type="dashed">Change</Button> */}
          </Box>
        </Col>
      </Row>
    </Card>
  );
};

const ProductCard = ({ order }) => {
  const { getAssetUrl } = useBaseData();
  return (
    <Card style={{ marginTop: 12 }}>
      <Row>
        <Col xs={24} sm={10}>
          <Box flexBox flex={1}>
            <Image
              src={getAssetUrl(order.meta.photo.path)}
              alt={order.meta.productName}
              width="100px"
            />
            <Box
              flexBox
              flex={1}
              flexDirection="column"
              justifyContent="center"
              ml={3}
              mr={3}
            >
              <TextLink
                to={generatePath(STORE_PRODUCT_PAGE, {
                  productId: order.productId,
                })}
                mr={2}
                fontWeight="600"
                content={`${order.meta.brand.name} ${order.meta.productName}`}
              />
              {order.meta.variant.size !== "Default" && (
                <Text
                  color="gray"
                  content={`Size: ${order.meta.variant.size}`}
                />
              )}
              <Text color="gray" content={`Quantity: ${order.quantity}`} />
              <Text
                fontWeight={600}
                fontSize={16}
                content={formatCurrency(order.pricing.totalSellingPrice)}
              />
            </Box>
          </Box>
        </Col>
        <Col xs={24} sm={7}>
          <Box
            flexBox
            height="100%"
            flexDirection="row"
            alignItems="center"
            justifyContent="flex-end"
          >
            <Tag color={getStoreOrderStateDetail(order.orderState, 'color')}>
              {getStoreOrderStateDetail(order.orderState, 'customerText')}
            </Tag>
          </Box>
        </Col>
      </Row>
    </Card>
  );
};

export default OrderDetails;
