import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";
  
export const Form = styled.form`
  padding: 0 60px 30px;
  color: ${themeGet('text.0', '#2C2C2C')};
  background-color: ${themeGet('color.2', '#F7F7F7')};
  
  @media only screen and (max-width: 991px) {
    margin-top: 45px;
    margin-bottom: 15px;
    padding: 45px 50px;
  }
  @media only screen and (max-width: 767px) {
    padding: 0 30px;
    background-color: transparent;
  }
  @media only screen and (max-width: 480px) {
    padding: 0 25px;
  }

  .form-control {
    margin-bottom: 30px;
  }

  .ant-checkbox-wrapper {
    .ant-checkbox {
      + span {
        font-weight: 600;
      }
    }
  }

  .ant-input-group-addon {
    .ant-btn {
      span {
        font-weight: 600;
        color: ${themeGet("text.0", "#2C2C2C")};
      }
    }
  }

  .submit-container {
    margin-bottom: 0;
  }
`;
