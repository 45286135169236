import React, { useState } from "react";
import { Button, Drawer, Checkbox } from "antd";
import useSWR from 'swr';

import Heading from "components/UI/Heading/Heading";
import { setStateToUrl, getStateFromUrl } from "library/helpers/url_handler";
import { IoIosArrowDown } from "react-icons/io";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

import { SEARCH_BUSINESS_PAGE } from "settings/constant";

import {
  FilterArea,
  FilterElementsWrapper,
  ButtonGroup,
} from "./SearchFilterDrawer.style";

const FilterDrawer = ({ history, location }) => {
  const { data: categoryData = [] } = useSWR(`${process.env.REACT_APP_API_URL}/category`)
  const searchParams = getStateFromUrl(location);
  const [toggle, setToggle] = useState(false);
  const [category, setCategory] = useState(searchParams.category || []);

  const handleToggleDrawer = () => {
    setToggle(!toggle);
  };

  const onChangeProperty = (checkedCategory) => {
    setCategory(checkedCategory);
  };

  const handleApplyFilter = () => {
    const search = setStateToUrl({
      category: category,
    });
    history.push({
      pathname: SEARCH_BUSINESS_PAGE,
      search: search,
    });
    setToggle(false);
  };
  const handleCloseDrawer = () => {
    setStateToUrl(null);
    setCategory([]);
    setToggle(false);
  };

  return (
    <FilterArea>
      <Button className={toggle ? "active" : ""} onClick={handleToggleDrawer}>
        { !toggle ? "More filters" : "Close filters" } 
      </Button>
      <Drawer
        className="filter_drawer"
        height="100vh"
        placement="bottom"
        closable={false}
        maskClosable={false}
        onClose={handleCloseDrawer}
        open={toggle}
        maskStyle={{ backgroundColor: "transparent" }}
      >
        <FilterElementsWrapper>
          <Accordion allowZeroExpanded={true}>
            {/* Start property type filter element */}
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <Heading as="h4" content="Category" />
                  <IoIosArrowDown />
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <Checkbox.Group
                  options={categoryData.map((item) => ({
                    label: item.name,
                    value: item.value,
                  }))}
                  value={category}
                  onChange={onChangeProperty}
                />
              </AccordionItemPanel>
            </AccordionItem>
            {/* End of property type filter element */}
          </Accordion>

          <ButtonGroup>
            <Button onClick={handleCloseDrawer}>Cancel Filter</Button>
            <Button type="primary" onClick={handleApplyFilter}>
              Apply Filter
            </Button>
          </ButtonGroup>
        </FilterElementsWrapper>
      </Drawer>
    </FilterArea>
  );
};

export default FilterDrawer;
