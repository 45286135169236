import React, { useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Modal } from 'antd';

import { LOGIN_PAGE } from 'settings/constant';
import { AuthContext } from 'context/AuthProvider';
import Button from 'components/UI/Button/Button';
import { get } from 'lodash';
import { formatCurrency } from 'library/helpers/utils';

const ActionButton = ({ btnText = "CHECKOUT", cartDetails, showPricing=false, isValidating, onSuccess = () => { } }) => {
  const history = useHistory();
  const location = useLocation();
  const { loggedIn } = useContext(AuthContext);

  let pricing= ""
  if(showPricing) {
    const customerToPay = get(cartDetails, "pricing.customerToPay", )
    pricing = customerToPay ? `( ${formatCurrency(customerToPay)} )` : ""
  }

  return (
    <Button
      loading={isValidating}
      type="primary"
      onClick={() => {
        if (cartDetails?.items.find(p => !p.inStock)) {
          Modal.error({
            title: "Oh! No",
            content: <div>It seems like some of the products in your cart are not available at the moment. Please remove them to proceed.</div>,
          });
        }
        else if (!loggedIn) {
          Modal.confirm({
            title: "Oh! No",
            content: <div>It seems like you are not logged in. Please login to place an order.</div>,
            onOk: () => {
              history.push({
                pathname: LOGIN_PAGE,
                state: { loginRequiredMessage: false, previousPath: `${location.pathname}`, previousSearch: `${location.search}` }
              })
            }
          });
        }
        else {
          onSuccess();
        }
      }}
    >
      {`${btnText} ${pricing}`}
    </Button>

  )
}

export default ActionButton
