import { get, isEqual, isFinite, lowerCase, round } from "lodash";
import moment from 'moment';

export const loadScript = (src) => {
    return new Promise((resolve) => {
        const script = document.createElement('script')
        script.src = src
        script.onload = () => {
            resolve(true)
        }
        script.onerror = () => {
            resolve(false)
        }
        document.body.appendChild(script)
    })
}

export const isProduction = process.env.NODE_ENV === "production";

export const isB2B = process.env.REACT_APP_IS_B2B_WEBSITE === "true";

export const showBetaFeature = () => !isProduction || process.env.REACT_APP_ENABLE_BETA_FEATURE === "true";

export const getOrderStateColor = (state) => {
    switch (state) {
        case "PENDING": return "grey"
        case "CREATED": return "orange"
        case "PROCESSING": return "purple"
        case "CANCELLED": return "red"
        case "SHIPPED": return "blue"
        case "COMPLETE": return "green"
        default: return ""
    }
}

export const getOrderStateTextForMerchant = (state) => {
    switch (state) {
        case "PENDING": return "IN PROGRESS"
        case "CREATED": return "NEW"
        case "PROCESSING": return "ACCEPTED"
        case "CANCELLED": return "CANCELLED"
        case "SHIPPED": return "PACKED"
        case "COMPLETE": return "DELIVERED"
        default: return ""
    }
}

export const getOrderTypeTextForMerchant = (state) => {
    switch (state) {
        case "OFFLINE": return "Offline"
        case "BOOM_PICKUP": return "Self Pickup"
        case "BOOM_OFFLINE": return "Self Pickup (Offline)"
        case "BOOM_DELIVERY": return "Home Delivery"
        default: return ""
    }
}

export const getPaymentTypeTextForMerchant = (state) => {
    switch (state) {
        case "COD": return (isB2B ? "Pay to Company" : "Cash on Delivery")
        case "PREPAID": return "Prepaid"
        default: return ""
    }
}

export const storeOrderStates = [
    { value: "WAITING_TO_ACCEPT", text: "New", customerText: "Processing", color: "#f58231" },
    { value: "ACCEPTED_BY_SELLER", text: "Accepted", customerText: "Processing", color: "#469990" },
    { value: "REJECTED_BY_SELLER", text: "Rejected", customerText: "Cancelled By Seller", color: "#800000" },
    { value: "PACKED_BY_SELLER", text: "Packed", customerText: "Packed", color: "#4bc34a" },
    { value: "READY_FOR_PICKUP", text: "Pickup Scheduled",customerText: "Packed",  color: "#9bc34a" },
    { value: "SHIPPED", text: "Shipped", customerText: "On The Way",  color: "#4363d8" },
    { value: "DELIVERED", text: "Delivered",  customerText: "Delivered", color: "#3cb44b" },
    { value: "RETURN_TO_ORIGIN", text: "Returned to origin", customerText: "Returned", color: "#808000" },
    { value: "CANCELLED", text: "Cancelled", customerText: "Cancelled", color: "#e6194B" },
    // { value: "REFUND_INITIATED", text: "Refund Inititated", color: "#a9a9a9" },
    // { value: "REFUNDED", text: "Refund Processed", color: "#808000" },
];

export const getStoreOrderStateDetail = (state, detail) => {
   const orderState = storeOrderStates.find(item => item.value === state)
   if(detail){
        return get(orderState, detail)
   }
   return orderState
}

export const getOrderStateTextForCustomer = (state, isDeliveryOrder = false) => {
    switch (state) {
        case "PENDING": return "Payment in progress"
        case "CREATED": return "Processing"
        case "PROCESSING": return "Processing"
        case "CANCELLED": return "Cancelled"
        case "SHIPPED": return isDeliveryOrder ? "On the way" : "Ready for pickup"
        case "COMPLETE": return "Delivered"
        default: return ""
    }
}

export const getBankAccountStatusColor = (state) => {
    switch (state) {
        case "NA": return "blue"
        case "PENDING": return "orange"
        case "SUBMITTED": return "orange"
        case "REJECTED": return "red"
        case "APPROVED": return "green"
        default: return ""
    }
}

export const getSettlementStatusColor = (state) => {
    switch (state) {
        case "PENDING": return "orange"
        case "INITIATED": return "blue"
        case "PROCESSED": return "green"
        case "FAILED": return "red"
        case "REFUNDED": return "red"
        default: return ""
    }
}

export const getSettlementStatusText = (state) => {
    switch (state) {
        case "PENDING": return "Approved"
        case "INITIATED": return "Sent to Bank"
        case "PROCESSED": return "Transferred"
        case "FAILED": return "Rejected by Bank"
        case "REFUNDED": return "Refunded"
        default: return ""
    }
}

export const getModifiedFields = (oldData = {}, newData = {}) => {
    return Object.keys(newData).filter(key => !isEqual(get(oldData, key, ""), get(newData, key, "")));
}

export const getBusinessProductPriceBreakup = ({ productSellingPrice = 0, commissionPercent = 0, processingFeePercent = 0, gstPercent = 0 }) => {
    const sellingPrice = round(productSellingPrice, 2);
    const commission = round(sellingPrice * commissionPercent / 100, 2);
    const processingFee = round(sellingPrice * processingFeePercent / 100, 2);
    const gst = round((commission + processingFee) * gstPercent / 100, 2);
    const finalAmount = round(sellingPrice - commission - processingFee - gst, 2);

    return {
        sellingPrice: sellingPrice.toFixed(2),
        commission: commission.toFixed(2),
        processingFee: processingFee.toFixed(2),
        gst: gst.toFixed(2),
        finalAmount: finalAmount.toFixed(2),
    }
}

export const filterArray = (array = [], key = "name", searchText = "") => {
    return array.filter(item => lowerCase(get(item, key, "").split(" ").join()).includes(lowerCase(searchText.split(" ").join())))
}


export const formatArrayLength = (array = [], suffix = "", plural = "s") => {
    switch (array.length) {
        case 0: return `No ${suffix}${plural}`
        case 1: return `${array.length} ${suffix}`
        default: return `${array.length} ${suffix}${plural}`
    }
}

export const userHasAnyRole = (user, roles = []) => {
    let hasRole = false;
    for (const role of roles) {
        if (user?.roles?.includes(role)) {
            hasRole = true;
        }
    }
    return hasRole;
}

export const getOrderPaymentDetails = (transaction = {}) => {
    const { payment = {} } = transaction
    switch (payment.method) {
        case 'card': {
            return `${get(payment, "data.card.network", "Card")} **** ${get(payment, "data.card.last4", "****")}`
        }
        case 'netbanking': {
            return `Netbanking`
        }
        case 'wallet': {
            return `Wallet`
        }
        case 'emi': {
            return `EMI **** ${get(payment, "data.card.last4", "****")}`
        }
        case 'upi':
        case 'vpa': {
            return `${get(payment, "data.vpa", "UPI")}`
        }
        default: return ""
    }
}

export const showBoomOrderNowButton = (business = {}) => {
    const isBoomPartner = get(business, 'isBoomPartner', false);
    const isOpen = get(business, 'isOpen', false);
    const isTemporaryDisabled = get(business, 'isBoomActive', false);

    return isBoomPartner && isOpen && isTemporaryDisabled;
}

export const getDateRangeTillNow = (lastNoOfDays = 0) => [[moment().subtract(lastNoOfDays, "days").startOf('day').toISOString(false), moment().endOf('day').toISOString(false)]]

export const getDateRangeForDay = (lastNoOfDays = 0) => [[moment().subtract(lastNoOfDays, "days").startOf('day').toISOString(false), moment().subtract(lastNoOfDays, "days").endOf('day').toISOString(false)]]

export const getDateRangeForMonth = (lastNoOfMonths = 0) => [[moment().subtract(lastNoOfMonths, "months").startOf('month').toISOString(false), moment().subtract(lastNoOfMonths, "months").endOf('month').toISOString(false)]]

export const getTodaysDate = () => moment().startOf('day').toISOString(false)

export const getYesterdayDate = () => moment().subtract(1, "days").startOf('day').toISOString(false)

export const formatCurrency = (number = "0", precision = 0, hidePrecesionWhenZero = false, currency = "INR") => {
    if (!isFinite(number)) {
        return ""
    }
    const numbersAfterDecimals = hidePrecesionWhenZero && number % 1 === 0 ? 0 : precision
    if (currency === "INR") {
        return `₹${number.toFixed(numbersAfterDecimals)}`
    }
    return number
    //return Intl.NumberFormat(locale, { style: 'currency', currency, minimumFractionDigits: numbersAfterDecimals, maximumFractionDigits: numbersAfterDecimals }).format(number)
}

export const handlePlural = (
    value = 0,
    text = "",
    pluralSuffix = ""
  ) => {
    return value > 1 ? `${value} ${text}${pluralSuffix}` : `${value} ${text}`;
  };
  