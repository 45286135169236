import React, { useState } from "react";
import { Alert, Card, Divider, Input } from "antd";
import { round } from "lodash";
import { CiDiscount1 } from "react-icons/ci";

import { formatCurrency, handlePlural } from "library/helpers/utils";
import Button from "components/UI/Button/Button";
import Box from "components/UI/Box/Box";
import Text from "components/UI/Text/Text";
import { useStoreCart } from "context/StoreCartProvider";
import FormControl from "components/UI/FormControl/FormControl";

const PriceSummary = ({ canProceedToPay, isPaymentInProgress, onMakePayment }) => {
  const { cart = [], getTotalPrice } = useStoreCart();
  const [showPromoError, setPromoCodeError] = useState(false);

  const totalMaximumPrice = getTotalPrice("maximumPrice");
  const totalSellingPrice = getTotalPrice("sellingPrice");
  const totalDiscountAmount = totalMaximumPrice - totalSellingPrice;
  const totalDiscountPercent = round(
    (totalDiscountAmount * 100) / totalMaximumPrice
  );

  const convenienceCharge = 0;
  const deliveryCharge = 0;

  return (
    <Card
      bordered
      title={
        <Text
          fontWeight={600}
          fontSize={["14px", "14px", "14px", "16px"]}
          content="Price Breakup"
        />
      }
    >
      <Box flexBox justifyContent="space-between" mb={2}>
        <Text
          fontSize={["12px", "12px", "14px", "14px"]}
          content={`MRP (${handlePlural(cart.length, "item", "s")})`}
        />
        <Text
          fontSize={["12px", "12px", "14px", "14px"]}
          content={`${formatCurrency(totalMaximumPrice)}`}
        />
      </Box>
      <Box flexBox justifyContent="space-between" mb={2}>
        <Box flexBox flexDirection="row">
          <Text
            fontSize={["12px", "12px", "14px", "14px"]}
            content={`Discount`}
            mr={1}
          />
          {totalDiscountPercent > 0 ? (
            <Text
              fontSize={["12px", "12px", "14px", "14px"]}
              color="indianred"
              content={`(${totalDiscountPercent}%)`}
            />
          ) : null}
        </Box>
        <Text
          fontSize={["12px", "12px", "14px", "14px"]}
          content={`- ${formatCurrency(totalDiscountAmount)}`}
        />
      </Box>
      <Box flexBox justifyContent="space-between" mb={2}>
        <Text
          fontSize={["12px", "12px", "14px", "14px"]}
          content={`Platform Charge`}
        />
        <Text content={`${formatCurrency(convenienceCharge)}`} />
      </Box>
      <Box flexBox justifyContent="space-between" mb={2}>
        <Text
          fontSize={["12px", "12px", "14px", "14px"]}
          content={`Delivery Charge`}
        />
        <Text
          fontSize={["12px", "12px", "14px", "14px"]}
          color="green"
          content={`${deliveryCharge === 0 ? "Free" : deliveryCharge}`}
        />
      </Box>
      <Alert
        type="success"
        message={`Yay! You are saving ${totalDiscountPercent}% on MRP`}
        icon={<CiDiscount1 size={30} />}
        showIcon
      />
      <Divider />
      <FormControl label="Do you have a promo code ?" htmlFor="promo-code">
        <Input
          id="promo-code"
          size="small"
          onChange={() => setPromoCodeError(false)}
          addonAfter={
            <Button type="link" onClick={() => setPromoCodeError(true)}>
              Apply
            </Button>
          }
        />
        {showPromoError && (
          <Text color="red" content="The promo code is not valid" />
        )}
      </FormControl>
      <Box flexBox justifyContent="space-between" mb={2}>
        <Text fontSize="20" fontWeight="600" content={`You Pay`} />
        <Text
          fontSize="20"
          fontWeight="600"
          content={`${formatCurrency(totalSellingPrice)}`}
        />
      </Box>
      <Box flexBox justifyContent="space-between">
        <Button
          disabled={!canProceedToPay}
          size="large"
          type="primary"
          fullWidth
          loading={isPaymentInProgress}
          onClick={onMakePayment}
        >
          Pay Now
        </Button>
      </Box>
    </Card>
  );
};

export default PriceSummary;
