import React from "react";
import { useHistory } from "react-router-dom";
import { Alert, Badge, Drawer } from "antd";
import { FiShoppingCart } from "react-icons/fi";

import useWindowSize from "library/hooks/useWindowSize";
import { useStoreCart } from "context/StoreCartProvider";
import Text from "components/UI/Text/Text";
import Box from "components/UI/Box/Box";
import Button from "components/UI/Button/Button";
import { formatCurrency } from "library/helpers/utils";
import { STORE_CHECKOUT_PAGE } from "settings/constant";
import StoreCartList from "./StoreCartList";

const StoreCart = ({ className = "" }) => {
  const history = useHistory();
  const {
    cart = [],
    addOrRemoveInCart,
    getTotalPrice,
    showCart,
    setShowCart,
    isAnyProductOutOfStock
  } = useStoreCart();
  const { width } = useWindowSize();

  const sellingPrice = getTotalPrice("sellingPrice");
  const maximumPrice = getTotalPrice("maximumPrice");


  const getDrawerWidth = (width) => {
    if(width >= 1600) {
      return '40vw'
    }
    if(width >= 1200) {
      return '50vw'
    }
    if(width >= 992) {
      return '60vw'
    }
    if(width >= 768) {
      return '100vw'
    }
    if(width >= 576) {
      return '100vw'
    }
    return '100vw'
  }

  return (
    <span className={className}>
      <Button
        type="link"
        onClick={(e) => {
          e.preventDefault();
          setShowCart(true);
        }}
      >
        <Badge
          className="cart"
          size="small"
          count={cart.reduce((sum, item) => sum + item.quantity, 0)}
        >
          <FiShoppingCart size={width > 767 ? 20 : 16} color="black" />
        </Badge>
      </Button>

      <Drawer
        title={"Cart"}
        width={getDrawerWidth(width)}
        onClose={() => setShowCart(false)}
        open={showCart}
        footer={
          cart.length ? (
            <Box flexBox justifyContent="space-between" alignItems="center" p={2}>
              <Box
                flexBox
                flexDirection="column"
                justifyContent="space-between"
              >
                <Text
                  fontSize={[10, 12, 14]}
                  fontWeight="300"
                  content="Total Amount"
                ></Text>
                <Box flexBox flexDirection="row">
                  {sellingPrice !== maximumPrice && <Text
                    fontWeight="300"
                    fontSize={[16, 18, 20]}
                    content={formatCurrency(maximumPrice)}
                    pl={1}
                    pr={2}
                    style={{ textDecoration: "line-through" }}
                  />}
                  <Text
                    fontWeight="600"
                    fontSize={[16, 18, 20]}
                    content={formatCurrency(sellingPrice)}
                  />
                </Box>
              </Box>
              <Button
                size="large"
                type="primary"
                disabled={isAnyProductOutOfStock()}
                onClick={() => {
                  history.push(STORE_CHECKOUT_PAGE);
                  setShowCart(false);
                }}
              >
                <Text
                  fontSize={[12, 14, 16]}
                  content="PROCEED TO CHECKOUT"
                ></Text>
              </Button>
            </Box>
          ) : null
        }
        bodyStyle={{ paddingBottom: 80 }}
      >
        <StoreCartList
          cart={cart}
          showCart={showCart}
          addOrRemoveInCart={addOrRemoveInCart}
        />
        {cart.length > 0 && (
          <Alert
            message={`Yay! You are saving ${formatCurrency(
              getTotalPrice("maximumPrice") - getTotalPrice("sellingPrice")
            )} on MRP`}
            showIcon={false}
            style={{ marginTop: 8, textAlign: "center" }}
          />
        )}
      </Drawer>
    </span>
  );
};

export default StoreCart;
