import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { Menu } from 'antd';
import { AuthContext } from 'context/AuthProvider';
import { useStoreCart } from 'context/StoreCartProvider';
import {
  HOME_PAGE,
  ACCOUNT_SETTINGS_PAGE,
  MY_ORDERS_PAGE
} from 'settings/constant';

const MobileMenu = ({ className, onClick }) => {
  // auth context
  const { loggedIn, logOut } = useContext(AuthContext);
  const { updateCart } = useStoreCart()

  const handleLogout = () => {
    logOut();
    updateCart([]);
  };

  return (
    <Menu className={className}>
      <Menu.Item key="0" onClick={onClick}>
        <NavLink exact to={HOME_PAGE}> Home</NavLink>
      </Menu.Item>
      {loggedIn && (
        <Menu.Item key="3" onClick={onClick}>
          <NavLink to={MY_ORDERS_PAGE}>My Orders</NavLink>
        </Menu.Item>
      )}
      {loggedIn && (
        <Menu.Item key="5" onClick={onClick}>
          <NavLink to={ACCOUNT_SETTINGS_PAGE}>Account Settings</NavLink>
        </Menu.Item>
      )}
      {loggedIn && (
        <Menu.Item key="6" onClick={onClick}>
          <button onClick={handleLogout}>Log Out</button>
        </Menu.Item>
      )}
    </Menu>
  );
};

export default MobileMenu;
