import React from "react";
import PropTypes from "prop-types";
import HtmlLabel from "../HtmlLabel/HtmlLabel";
import Wrapper from "./FormControl.style";
import { Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

const FormControl = ({
  className,
  label,
  tooltip,
  mandatory,
  labelTag,
  htmlFor,
  children,
  error,
  ...props
}) => {
  const addAllClasses = ["form-control"];
  if (error) {
    addAllClasses.push("has-error");
  }
  if (className) {
    addAllClasses.push(className);
  }

  return (
    <Wrapper className={addAllClasses.join(" ")} {...props}>
      <div className="field-label-wrapper">
        {label && (
          <HtmlLabel
            className="field-label"
            as={labelTag}
            htmlFor={htmlFor}
            content={label}
            mandatory={mandatory}
          />
        )}
        {tooltip && (
          <Tooltip
            className="field-tooltip"
            title={tooltip}
            placement="right"
            getPopupContainer={(triggerNode) => triggerNode.parentElement}
          >
            <InfoCircleOutlined />
          </Tooltip>
        )}
      </div>
      {children}
      {error && <div className="feedback">{error}</div>}
    </Wrapper>
  );
};

FormControl.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  labelTag: PropTypes.string,
  htmlFor: PropTypes.string,
  children: PropTypes.node,
  mandatory: PropTypes.bool,
  error: PropTypes.any,
};

export default FormControl;
