export const HOME_PAGE = '/';
export const COMMON_PAGE = '/p/:code'
export const ACCOUNT_SETTINGS_PAGE = '/account-settings';

export const MY_ORDERS_PAGE = '/my-orders';
export const MY_LOCAL_ORDER_DETAILS_PAGE = '/my-orders/nearby/:orderId';
export const MY_STORE_ORDER_DETAILS_PAGE = '/my-orders/store/:orderId/:subOrderId?';

// NEARBY
export const SEARCH_BUSINESS_PAGE = '/search/business';
export const BUSINESS_DETAILS_PAGE = '/business-details';
export const BUSINESS_CATEGORIES_PAGE = '/business-categories';
export const BUSINESS_PRODUCTS_PAGE = '/business-products';
export const BUSINESS_ORDER_CHECKOUT_PAGE = '/business-order-checkout';

// STORE
export const STORE_PRODUCT_SEARCH_PAGE = '/store/search';
export const STORE_PRODUCT_PAGE = '/store/product/:productId/:variantId?';
export const STORE_CHECKOUT_PAGE = '/store/checkout';

// AUTH
export const INVITE_PAGE = '/invite/:inviteCode';
export const LOGIN_PAGE = '/sign-in';
export const SIGN_UP_PAGE = '/sign-up';
export const SIGN_UP_CONFIRM_PAGE = '/sign-up-confirm';
export const FORGET_PASSWORD_PAGE = '/forgot-password';
export const FORGET_PASSWORD_CONFIRM_PAGE = '/forgot-password-confirm';

// PAYMENT
export const TECHNICAL_ERROR_PAGE = '/technical-error';
export const SUCCESS_PAGE = '/success';
export const PAYMENT_PROCESSING_PAGE = '/payment-processing';
export const PAYMENT_CALLBACK_PAGE = '/payment-callback';

// FOOTER
export const JOIN_AS_MERCHANT = '/join-as-merchant'
export const PRIVACY_POLICY_PAGE = '/privacy-policy';
export const REFUND_POLICY_PAGE = '/refund-policy';
export const TERMS_OF_USE_PAGE = '/terms';
export const ABOUT_US_PAGE = '/about-us';

// SELER STORE
export const MANAGE_MY_SELLER_STORE_PAGE = `/my-store/:sellerId`;

// BUSINESS
export const MY_BUSINESS_PAGE = '/my-business';
export const ADD_MY_BUSINESS_PAGE = '/add-business';
export const MANAGE_MY_BUSINESS_PATH = '/manage-business';
export const MANAGE_MY_BUSINESS_PAGE = `${MANAGE_MY_BUSINESS_PATH}/:slug/:businessId`;
export const BUSINESS_POS_PATH = '/pos';
export const BUSINESS_POS_PAGE = `${BUSINESS_POS_PATH}/:slug/:businessId`;

// ADMIN
export const ADMIN_PANEL_PAGE = '/admin';

