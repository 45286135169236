import styled from "styled-components";

const ProceedToPayWrapper = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 10000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 22px;

  @media only screen and (max-width: 992px) {
    background: #ffffff;
    border-top: 1px solid #f0f0f0;
    box-shadow: 0 -2px 10px 0 rgba(0, 0, 0, 0.1);
  }

  button {
    height: auto;
    padding: 11px 22px;
    border-radius: 3px;
    font-size: 15px;
    min-width: 200px;
    @media only screen and (max-width: 767px) {
      min-width: 100px;
    }
  }

  .proceed-to-pay-container {
    .button-wrapper {
      width: 100%;
      button {
        width: 100%;
      }
    }
    @media only screen and (max-width: 767px) {
      width: 100%;
      padding: 0;
    }
  }

  &.full-size-btn {
    @media only screen and (max-width: 767px) {
      .button-wrapper {
        width: 100%;
      }

      border-top: none;
      background: transparent;
      box-shadow: none;
    }
  }
`;

export default ProceedToPayWrapper;
