import React, { useContext, useState } from 'react';
import useSWR from 'swr';
import { Alert, Card, Col, Divider, Drawer, List, Row } from 'antd';
import { EnvironmentOutlined } from '@ant-design/icons';
import { useTheme } from 'styled-components';
import { get } from 'lodash';

import useWindowSize from 'library/hooks/useWindowSize';
import { fetcher } from 'library/hooks/useDataApi';
import { AuthContext } from 'context/AuthProvider';
import { useBusinessOrderCart } from 'context/BusinessOrderCartProvider';
import Heading from 'components/UI/Heading/Heading';
import Text from 'components/UI/Text/Text';
import Box from 'components/UI/Box/Box';
import Button from 'components/UI/Button/Button';
// import Map from 'components/Map/Map';
import UpsertAddressForm from 'components/AddressForm/UpsertAddressForm';
import { AddressCardWrapper } from './DeliveryDetails.style';

const AddressList = () => {
  const { width } = useWindowSize();
  const { user } = useContext(AuthContext);
  const { state, setDeliveryAddress } = useBusinessOrderCart();
  const { data: cartDetails, isValidating } = useSWR(`${process.env.REACT_APP_API_URL}/cart-details?resetKey=${state.resetKey}`, url => fetcher(url, "POST", {}, JSON.stringify(state)))

  const [showAddressSelection, setshowAddressSelection] = useState(!state.address);
  const [currentAddress, setCurrentAddress] = useState({});
  const [showDrawer, setShowDrawer] = useState(false);

  const onClose = () => {
    setCurrentAddress({});
    setShowDrawer(false);
  };

  const onSubmitSuccess = () => {
    setCurrentAddress({});
    setShowDrawer(false);
  };


  const onAddNewAddress = () => {
    setDeliveryAddress(null)
    setCurrentAddress({});
    setShowDrawer(true);
  }

  const selectedAddress = get(state, "address")
  if (showAddressSelection === true || !selectedAddress) {
    return (
      <>
        <List
          bordered={false}
          locale={{ emptyText: "You have not added any address" }}
          header={
            <AddressHeading headingText="Select Delivery Address" btnText="Add New" onBtnClick={() => onAddNewAddress()} />
          }
          grid={{ gutter: 16, xs: 1, sm: 1, md: 1, lg: 2, xl: 2, xxl: 2 }}
          dataSource={get(user, 'address', [])}
          renderItem={item => (
            <List.Item>
              <AddressCardWrapper>
                <Card
                  className={get(state, "address.id", "") === item.id ? "selected" : ""}
                  actions={[
                    <Button type="primary" size="large" fullWidth onClick={() => {
                      setDeliveryAddress(item)
                      setshowAddressSelection(false)
                    }}>
                      <Text fontSize={[10, 12]} fontWeight={600} content="DELIVER HERE" />
                    </Button>
                  ]}
                >
                  <Heading as={"h4"} content={item.addressType} />
                  <Text content={item.name} />
                  <Text content={item.address} />
                  <Text content={item.locality} />
                  <Text content={`${item.city}, ${item.state}, ${item.pin}`} />
                </Card>
              </AddressCardWrapper>
            </List.Item >
          )}
        />
        <Drawer
          title={currentAddress.id ? "Update Address" : "Add Address"}
          width={width > 767 ? "50vw" : "100vw"}
          destroyOnClose
          onClose={onClose}
          open={showDrawer}
          bodyStyle={{ paddingBottom: 80 }}
        >
          <UpsertAddressForm
            mode={currentAddress.id ? "UPDATE" : "CREATE"}
            onSubmitSuccess={onSubmitSuccess}
            currentAddress={currentAddress}
          />
        </Drawer>
      </>
    )
  }

  return (
    <>
      <AddressHeading headingText="Delivery Address" btnText="Change" onBtnClick={() => {
        setDeliveryAddress(null)
        setshowAddressSelection(true)
      }} />
      <Divider type="horizontal" />
      {!cartDetails?.isDeliverable && !isValidating && <Alert showIcon type="error" message="This address is not serviceable. Please select a different address." />}

      <Row gutter={8}>
        <Col xs={24} sm={12}>
          <Heading as={"h4"} content={selectedAddress.addressType} />
          <Text content={`${selectedAddress.name}`} />
          <Text content={`${selectedAddress.address} ${selectedAddress.locality}`} />
          <Text content={`${selectedAddress.city} ${selectedAddress.state} ${selectedAddress.pin}`} />
          <Text content={`Landmark: ${selectedAddress.landmark}`} mb={3} />
          <Text content={`Mobile: ${selectedAddress.mobileNo}`} mb={3} />
        </Col>
        <Col xs={24} sm={12}>
          {/* {selectedAddress?.location?.coordinates ?
            <Map
              data={[selectedAddress]}
              multiple={false}
              height="150px"
              defaultZoom={14}
              defaultCenter={{ lat: selectedAddress?.location.coordinates[0], lng: selectedAddress?.location.coordinates[1] }}
            />
            : null
          } */}
        </Col>
      </Row>
    </>
  )
}

const AddressHeading = ({ headingText = "", btnText = "", onBtnClick = () => { } }) => {
  const theme = useTheme()
  return (
    <Box flexBox flexDirection="row" justifyContent="space-between" alignItems="center">
      <Heading
        as="h3"
        content={<Box><EnvironmentOutlined style={{ color: get(theme, "primary.0", '#ff6105'), marginRight: 5 }} />{headingText}</Box>}
      />
      <Button onClick={onBtnClick}>{btnText}</Button>
    </Box>
  )
}

export default AddressList
