import React from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import { PuffLoader } from 'react-spinners';
import LoaderWrapper from './Loader.style';

const Loader = ({ fill, className }) => {
  const theme = useTheme()
  
  // default className
  const addAllClasses = ['loader'];
  const fillColor = fill || get(theme, "primary.0", '#ff6105') 
  // add new class using className prop
  if (className) {
    addAllClasses.push(className);
  }

  return (
    <LoaderWrapper className={addAllClasses.join(' ')}>
      <svg enableBackground="new 0 0 0 0">
        <circle fill={fillColor} stroke="none" cx="6" cy="50" r="6">
          <animate
            attributeName="opacity"
            dur="1s"
            values="0;1;0"
            repeatCount="indefinite"
            begin="0.1"
          />
        </circle>
        <circle fill={fillColor} stroke="none" cx="26" cy="50" r="6">
          <animate
            attributeName="opacity"
            dur="1s"
            values="0;1;0"
            repeatCount="indefinite"
            begin="0.2"
          />
        </circle>
        <circle fill={fillColor} stroke="none" cx="46" cy="50" r="6">
          <animate
            attributeName="opacity"
            dur="1s"
            values="0;1;0"
            repeatCount="indefinite"
            begin="0.3"
          />
        </circle>
      </svg>
    </LoaderWrapper>
  );
};

Loader.propTypes = {
  /** ClassName of the Loader component. */
  className: PropTypes.string,

  /** Add Loader color using fill prop. */
  fill: PropTypes.string,
};

/**
 *
 * Public Routes
 *
 */
 export const FullPageLoader = () => {
  const theme = useTheme()
  return (
    <div style={{ height: "100vh" }}>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}>
        <PuffLoader size={50} color={theme.primary[0]} />
      </div>
    </div>
  )
}

export default Loader;
