import React from "react";
import { Link } from "react-router-dom";

import { LOGIN_PAGE } from "settings/constant";
import { useBaseData } from "context/BaseDataProvider";
import Logo from "components/UI/Logo/Logo";
import authPageImage from "assets/images/sign-in.svg";
import SignUpForm from "./SignUpForm";
import Wrapper, {
  Text,
  FormWrapper,
  BannerWrapper,
  BannerImageWrapper,
  ImgQuote,
} from "../Auth.style";

const SignUp = () => {
  const {
    preferences: { fullLogoUrl },
  } = useBaseData();
  return (
    <Wrapper>
      <FormWrapper>
        <Logo withLink linkTo="/" src={fullLogoUrl} />
        <SignUpForm />
        <Text>
          Already Have an Account! &nbsp;
          <Link to={LOGIN_PAGE}>Login</Link>
        </Text>
      </FormWrapper>
      <BannerWrapper>
        <BannerImageWrapper>
          <img src={authPageImage} alt="" />
          <ImgQuote>
            "Success isn't about how much money you make; it's about the
            difference you make in people's lives."
          </ImgQuote>
        </BannerImageWrapper>
      </BannerWrapper>
    </Wrapper>
  );
};

export default SignUp;
