import React from "react";
import { get, minBy } from "lodash";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { generatePath } from "react-router-dom";
import { Col, Row } from "antd";

import { useBaseData } from "context/BaseDataProvider";
import { useAuth } from "context/AuthProvider";
import { STORE_PRODUCT_PAGE } from "settings/constant";
import Box from "components/UI/Box/Box";
import Image from "components/UI/Image/Image";
import LinkTo from "components/UI/LinkTo/LinkTo";
import Text from "components/UI/Text/Text";
import useDataApi from "library/hooks/useDataApi";
import { getImageDimensions, responsive } from "../utils";
import { formatCurrency } from "library/helpers/utils";

const FiveProductGallery = ({ section }) => {
  const configuration = get(section, "configuration", {});
  const components = get(section, "components", []);

  return (
    <Row>
      <Col xs={0} md={24}>
        <Box flexBox flexDirection="row" height="50vw">
          <Box flexBox flexDirection="column" height="100%">
            <ImageLink
              height="65%"
              aspectRatio="1"
              component={components[0]}
              configuration={configuration}
            />
            <ImageLink
              height="35%"
              component={components[1]}
              configuration={configuration}
            />
          </Box>
          <Box flexBox flex={1} flexDirection="column" height="100%">
            <Box
              width="100%"
              height="50%"
              flexBox
              flexDirection="row"
              p={responsive(get(configuration, "cardPadding"), 0)}
            >
              <Product configuration={configuration} />
            </Box>
            <Box width="100%" height="50%" flexBox flexDirection="row">
              <ImageLink
                width="50%"
                component={components[2]}
                configuration={configuration}
              />
              <ImageLink
                width="50%"
                component={components[3]}
                configuration={configuration}
              />
            </Box>
          </Box>
        </Box>
      </Col>

      <Col xs={24} md={0}>
        <Box flexBox flexDirection="row" style={{ aspectRatio: "1"}}>
          <Box flexBox flexDirection="column" width="50%" height="100%">
            <ImageLink
              height="65%"
              aspectRatio="1"
              component={components[0]}
              configuration={configuration}
            />
            <ImageLink
              height="35%"
              component={components[1]}
              configuration={configuration}
            />
          </Box>
          <Box flexBox flexDirection="column" width="50%" height="100%">
            <ImageLink
              height="50%"
              component={components[2]}
              configuration={configuration}
            />
            <ImageLink
              height="50%"
              component={components[3]}
              configuration={configuration}
            />
          </Box>
        </Box>
        <Box
          width="100%"
          height="50vw"
          flexBox
          flexDirection="row"
          p={responsive(get(configuration, "cardPadding"), 0)}
        >
          <Product configuration={configuration} />
        </Box>
      </Col>
    </Row>
  );
};

export const ImageLink = ({
  configuration,
  component,
  width = "auto",
  height = "auto",
  aspectRatio,
  ...props
}) => {
  const { getAssetUrl } = useBaseData();

  if (!component) {
    return null;
  }

  return (
    <LinkTo to={component.linkTo} style={{ width, height }}>
      <Box
        p={responsive(get(configuration, "cardPadding", 0))}
        width="100%"
        height="100%"
      >
        <Image
          as={LazyLoadImage}
          width="100%"
          height="100%"
          alt=""
          style={{ objectFit: "cover", aspectRatio }}
          src={getAssetUrl(component.photo[0]?.path)}
          borderRadius={responsive(get(configuration, "cardRadius", 0))}
          {...props}
        />
      </Box>
    </LinkTo>
  );
};

export const Product = ({ configuration }) => {
  const {
    preferences: { primaryColor },
  } = useBaseData();
  const { getAssetUrl } = useBaseData();
  const { loggedIn } = useAuth();

  const url = `${process.env.REACT_APP_API_URL}/search/store-product`;
  const { data: products = [] } = useDataApi(
    `${url}/${configuration.productId}`
  );

  if (!products.length) {
    return null;
  }

  const product = products[0];
  const variant = minBy(product.variants, "sellingPrice");
  const linkTo = generatePath(STORE_PRODUCT_PAGE, {
    productId: configuration.productId,
  });
  const productPhoto = product.photos[0] || configuration.productPhoto;

  return (
    <LinkTo to={linkTo} style={{ width: "100%", height: "100%" }}>
      <Box
        flexBox
        flexDirection="row"
        width="100%"
        height="100%"
        bg="#e6e6e6"
        p={responsive(get(configuration, "cardPadding"), 0)}
        borderRadius={responsive(get(configuration, "cardRadius", 0))}
      >
        <Image
          as={LazyLoadImage}
          alt=""
          height="100%"
          style={{
            objectFit: "cover",
            aspectRatio: getImageDimensions(productPhoto?.meta).aspectRatio,
          }}
          src={getAssetUrl(productPhoto?.path)}
          borderRadius={responsive(get(configuration, "cardRadius"), 0)}
        />
        <Box
          flexBox
          flexDirection="column"
          flex={1}
          justifyContent="center"
          p={[12, 16, 20]}
        >
          <Text
            fontSize={[16, 16, 20, 24]}
            fontWeight={700}
            color={primaryColor}
            content={formatCurrency(
              loggedIn ? variant.sellingPrice : variant.guestPrice,
              0,
              true
            )}
          />
          <Text
            mt={[1, 2, 1, 2]}
            fontSize={[16, 16, 20, 24]}
            fontWeight={700}
            color="#000"
            content={product.productName}
          />
          <Text
            mt={[1, 2, 1, 2]}
            fontSize={[12, 12, 15, 18]}
            fontWeight={300}
            color="#000"
            content={get(configuration, "productDescription", "")}
          />
        </Box>
      </Box>
    </LinkTo>
  );
};
export default FiveProductGallery;
