import React from "react";
import { Col, Row } from "antd";

import { useBaseData } from "context/BaseDataProvider";
import {
  HOME_PAGE,
  ABOUT_US_PAGE,
  PRIVACY_POLICY_PAGE,
  REFUND_POLICY_PAGE,
  TERMS_OF_USE_PAGE,
} from "settings/constant";
import Heading from "components/UI/Heading/Heading";
import Box from "components/UI/Box/Box";
import Text from "components/UI/Text/Text";
import LinkTo from "components/UI/LinkTo/LinkTo";

const FooterMenu = () => {
  const {
    preferences: {
      shiprocketTrackingUrl,
      facebookUrl,
      instagramUrl,
      youtubeUrl,
      twitterUrl,
    },
  } = useBaseData();

  const widgetMenus = [
    {
      id: "1",
      title: "Quick Links",
      menus: [
        { href: HOME_PAGE, label: "Home" },
        { href: ABOUT_US_PAGE, label: "About us" },
        { href: shiprocketTrackingUrl, label: "Track your order" },
      ],
    },
    {
      id: "2",
      title: "Legal",
      menus: [
        { href: PRIVACY_POLICY_PAGE, label: "Privacy Policy" },
        { href: REFUND_POLICY_PAGE, label: "Refund Policy" },
        { href: TERMS_OF_USE_PAGE, label: "Terms of Use" },
      ],
    },
    {
      id: "3",
      title: "Social",
      menus: [
        ...(instagramUrl ? [{ href: instagramUrl, label: "Instagram" }] : []),
        ...(facebookUrl ? [{ href: facebookUrl, label: "Facebook" }] : []),
        ...(youtubeUrl ? [{ href: youtubeUrl, label: "Youtube" }] : []),
        ...(twitterUrl ? [{ href: twitterUrl, label: "X" }] : []),
      ],
    },
  ];

  return (
    <Row>
      {widgetMenus.map((menu) => (
        <Col xs={24} lg={24 / widgetMenus.length} key={menu.id}>
          <Menu menu={menu} orientation />
        </Col>
      ))}
    </Row>
  );
};

export const Menu = ({ menu }) => {
  return (
    <Box>
      <Heading color="white" content={menu.title} mt={[3, 3, 3, 0]} mb={[1, 1, 1, 0]} />
      <Box as="ul" flexBox flexDirection={["row", "row", "row", "column"]}>
        {menu.menus.map((item, index) => (
          <Box as="li" key={index}>
            <LinkTo key={index} to={item.href}>
              <Box as="span" flexBox flexDirection="row">
                <Text as="span" color="white" content={item.label} />
                {index !== menu.menus.length - 1 && (
                  <Text
                    as="span"
                    color="white"
                    content="•"
                    ml={2}
                    mr={2}
                    display={["block", "block", "block", "none"]}
                  />
                )}
              </Box>
            </LinkTo>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default FooterMenu;
