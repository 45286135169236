import React, { useState, useContext } from "react";
import Sticky from "react-stickynode";
import { withRouter } from "react-router-dom";
import { IoIosClose } from "react-icons/io";
import { Button, Drawer } from "antd";
import Logo from "components/UI/Logo/Logo";
import Text from "components/UI/Text/Text";
import Box from "components/UI/Box/Box";
import Navbar from "components/Navbar/Navbar";
import { useBaseData } from "context/BaseDataProvider";
import { AuthContext } from "context/AuthProvider";
import { LayoutContext } from "context/LayoutProvider";
import useWindowSize from "library/hooks/useWindowSize";
import AuthMenu from "./AuthMenu";
import MainMenu from "./MainMenu";
import MobileMenu from "./MobileMenu";
import ProfileMenu from "./ProfileMenu";
import ProfileDetails from "./ProfileDetails";
import NavbarSearch from "./NavbarSearch";
import HeaderWrapper, {
  MobileNavbar,
  CloseDrawer,
  AvatarWrapper,
  AvatarImage,
  AvatarInfo,
  LogoArea,
} from "./Header.style";

import blankLogo from "assets/images/blank-logo.svg";
import ProfilePicStub from "assets/images/profile-pic-stub.jpg";
import StoreCart from "components/StoreCart/StoreCart";

const WhiteLogo = ({ ...props }) => {
  const {
    preferences: { orgName, fullWhiteLogoUrl },
  } = useBaseData();

  return <img src={fullWhiteLogoUrl || blankLogo} alt={orgName} {...props} />;
};

export default withRouter(function Header({ location }) {
  const {
    preferences: { fullLogoUrl },
    getAssetUrl,
  } = useBaseData();
  const [{ searchVisibility }] = useContext(LayoutContext);
  const { loggedIn, user } = useContext(AuthContext);
  const { width } = useWindowSize();
  const [state, setState] = useState(false);

  const sidebarHandler = () => {
    setState(!state);
  };

  const headerType = location.pathname === "/" ? "default" : "default";
  const avatar = (
    <Logo src={getAssetUrl(user?.profilePic?.path) || ProfilePicStub} />
  );
  return (
    <HeaderWrapper>
      <Sticky
        top={headerType === "transparent" ? -1 : 0}
        innerZ={10001}
        activeClass="isHeaderSticky"
      >
        {width > 991 ? (
          <Navbar
            logo={
              <>
                {headerType === "transparent" && <WhiteLogo width="160px" />}
                <Logo
                  withLink
                  linkTo="/"
                  src={fullLogoUrl || blankLogo}
                  width="160px"
                />
              </>
            }
            navMenu={<MainMenu />}
            authMenu={<AuthMenu />}
            isLogin={loggedIn}
            avatar={avatar}
            profileMenu={<ProfileMenu user={user} />}
            headerType={headerType}
            searchComponent={<NavbarSearch />}
            location={location}
            searchVisibility={searchVisibility}
          />
        ) : (
          <MobileNavbar className={headerType}>
            <Box flexBox flexDirection="row" alignItems="center">
              <Box mr={3}>
                <Button
                  className={`hamburg-btn ${state ? "active" : ""}`}
                  onClick={sidebarHandler}
                >
                  <span />
                  <span />
                  <span />
                </Button>
              </Box>
              <LogoArea>
                <>
                  {headerType === "transparent" && <WhiteLogo width="130px" />}
                  <Logo
                    withLink
                    linkTo="/"
                    src={fullLogoUrl || blankLogo}
                    width="130px"
                  />
                </>
                {/* <NavbarSearch /> */}
              </LogoArea>
            </Box>
            <Box>
              <StoreCart />
            </Box>
            <Drawer
              placement="left"
              closable={false}
              onClose={sidebarHandler}
              width="300px"
              className="mobile-header"
              open={state}
            >
              <CloseDrawer>
                <button onClick={sidebarHandler}>
                  <IoIosClose />
                </button>
              </CloseDrawer>
              {loggedIn ? (
                <>
                  <AvatarWrapper>
                    <AvatarImage>{avatar}</AvatarImage>
                    <AvatarInfo>
                      <Text
                        as="h3"
                        content={`${user?.firstName || ""} ${
                          user?.lastName || ""
                        }`}
                      />
                    </AvatarInfo>
                  </AvatarWrapper>
                  <ProfileDetails user={user} />
                </>
              ) : (
                <AuthMenu className="auth-menu" />
              )}
              <MobileMenu
                className="main-menu"
                onClick={() => {
                  if (state) {
                    setState(false);
                  }
                }}
              />
            </Drawer>
          </MobileNavbar>
        )}
      </Sticky>
    </HeaderWrapper>
  );
});
