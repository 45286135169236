import React from "react";

import Box from "components/UI/Box/Box";
import Text from "components/UI/Text/Text";
import Heading from "components/UI/Heading/Heading";

const SectionHeading = ({ section }) => {
  return (
    <Box px={[4, 5]} pt={4} pb={2}>
      <Heading
        fontSize={[20, 24, 28]}
        content={section.configuration.heading}
        textAlign={section.configuration.align || "left"}
      />
      <Text
        content={section.configuration.subHeading}
        textAlign={section.configuration.align || "left"}
      />
    </Box>
  );
};

export default SectionHeading;
