import { get } from "lodash";

export const getImageDimensions = (meta = {}) => {
  const width = get(meta, "width");
  const height = get(meta, "height");
  const wUnits = get(meta, "wUnits");
  const hUnits = get(meta, "hUnits");

  const result = {};
  if (width && wUnits) {
    result.width = width;
    result.widthInUnit = [width].join("");
  }
  if (height && hUnits) {
    result.height = height;
    result.heightInUnit = [height].join("");
  }

  if (width && height) {
    result.aspectRatio = `${width}/${height}`;
  }

  return result;
};

export const responsive = (array = [], defaultWhenEmpty = []) => {
  let previous;
  const values =
    array?.map((item) => {
      const isNullEmptyOrUndefined =
        typeof item === "undefined" || item === null || item === "";
      previous = isNullEmptyOrUndefined ? previous : item;
      return previous;
    }) || [];
  if (!values.filter(Boolean).length) {
    return defaultWhenEmpty;
  }
  return values;
};
