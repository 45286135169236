import React, { useEffect } from 'react';
import SuccessPageWrapper, { ContentWrapper } from './Success.style';

import { Result } from 'antd';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { HOME_PAGE } from 'settings/constant';

export default () => {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if(!location.state?.description){
      history.push(HOME_PAGE);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SuccessPageWrapper>
      <ContentWrapper>
        <Result
          status="success"
          title={location.state?.title}
          subTitle={location.state?.description}
          extra={[<NavLink key={location.state?.buttonLink || "/"} to={location.state?.buttonLink || "/"}>{location.state?.buttonText || "Go To Home"}</NavLink>]}
        />
      </ContentWrapper>
    </SuccessPageWrapper>
  );
};
