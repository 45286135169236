import React from 'react';
import { get } from 'lodash';
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

import Box from 'components/UI/Box/Box';
import Text from 'components/UI/Text/Text';
import { formatCurrency } from 'library/helpers/utils';

const Subtotal = ({ items = [], deliveryCharge }) => {
  const calculated = items.reduce((existing, current) => {
    const currentTotalPrice = current.pricing.totalSellingPrice || 0
    const currentTaxesAndFees = current.pricing.transactionCharge.amount + current.pricing.transactionCharge.gst || 0
    return (
      {
        totalSellingPrice: existing.totalSellingPrice + currentTotalPrice,
        taxesAndFees: existing.taxesAndFees + currentTaxesAndFees,
        amountToPay: existing.amountToPay + currentTotalPrice + currentTaxesAndFees
      }
    )
  }, { totalSellingPrice: 0, taxesAndFees: 0, amountToPay: 0 })

  const totalDeliveryCharge = get(deliveryCharge, "amount", 0) + get(deliveryCharge, "gst", 0)
  return (
    <Box>
      <Box flexBox flexDirection="row" justifyContent="space-between">
        <Text fontSize={[12, 14, 14]} content="Total Price" />
        <Text fontSize={[12, 14, 14]} content={formatCurrency(calculated.totalSellingPrice, 2)} />
      </Box>
      <Box flexBox flexDirection="row" justifyContent="space-between">
        <Text fontSize={[12, 14, 14]} content="Service Charge" />
        <Text fontSize={[12, 14, 14]} content={formatCurrency(calculated.taxesAndFees, 2)} />
      </Box>
      {deliveryCharge ?
        <Box flexBox flexDirection="row" justifyContent="space-between">
          <Box flexBox flexDirection="row" alignItems="center">
            <Text fontSize={[12, 14, 14]} content="Delivery Charge" mr={2} />
            <Tooltip title={`Applicable below ${formatCurrency(get(deliveryCharge, "minAmountForFreeDelivery", 0), 2)}`}> <InfoCircleOutlined /></Tooltip>
          </Box>
          <Text fontSize={[12, 14, 14]} color={totalDeliveryCharge ? null : "green"} content={totalDeliveryCharge ? formatCurrency(totalDeliveryCharge, 2) : "FREE"} />
        </Box>
        : null
      }
      <Box flexBox flexDirection="row" justifyContent="space-between">
        <Text fontWeight={600} fontSize={[16, 18, 18]} content="Amount to Pay" />
        <Text fontWeight={600} fontSize={[16, 18, 18]} content={formatCurrency((calculated.amountToPay + totalDeliveryCharge), 2)} />
      </Box>
    </Box>
  )
}

export default Subtotal
