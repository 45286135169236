import React from "react";
import { Button, Checkbox } from "antd";
import useSWR from 'swr';

import ViewWithPopup from "components/UI/ViewWithPopup/ViewWithPopup";
import { setStateToUrl, getStateFromUrl } from "library/helpers/url_handler";
import { SEARCH_BUSINESS_PAGE } from "settings/constant";
import SearchFilterWrapper from "./SearchFilter.style";

const SearchFilter = ({ history, location }) => {
  const { data: categoryData = [] } = useSWR(`${process.env.REACT_APP_API_URL}/category`)

  const searchParams = getStateFromUrl(location);
  const state = {
    category: searchParams.category || [],
  };

  const onChange = (value, type) => {
    const query = {
      ...state,
      [type]: value,
    };
    const search = setStateToUrl(query);
    history.push({
      pathname: SEARCH_BUSINESS_PAGE,
      search: search,
    });
  };

  const onSearchReset = () => {
    history.push({
      pathname: SEARCH_BUSINESS_PAGE,
      search: "",
    });
  };

  return (
    <SearchFilterWrapper>
      <ViewWithPopup
        className={state.category.length ? "activated" : ""}
        key="category"
        noView={true}
        view={
          <Button type="default">
            Category
            {state.category.length > 0 && `: ${state.category.length}`}
          </Button>
        }
        popup={
          <Checkbox.Group
            options={categoryData.map((item) => ({
              label: item.name,
              value: item.value,
            }))}
            defaultValue={state.category}
            onChange={(value) => onChange(value, "category")}
          />
        }
      />
      <div className="view_with__popup">
        <div className="popup_handler">
          <Button type="default" onClick={onSearchReset}>
            Reset
          </Button>
        </div>
      </div>
    </SearchFilterWrapper>
  );
};

export default SearchFilter;
