import React, { useEffect, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Alert } from "antd";

import { getSearchObjectFromLocation } from "library/helpers/url_handler";
import { SUCCESS_PAGE, TECHNICAL_ERROR_PAGE, MY_LOCAL_ORDER_DETAILS_PAGE, MY_STORE_ORDER_DETAILS_PAGE } from "settings/constant";
import Loader from "components/Loader/Loader";
import { AuthContext } from "context/AuthProvider";
import { useBusinessOrderCart } from "context/BusinessOrderCartProvider";
import { getSuccessPaymentProps } from './SuccessPaymentProps';
import PageWrapper, { ContentWrapper } from "./PaymentProcessing.style";
import { useStoreCart } from "context/StoreCartProvider";
import { generatePath } from "react-router-dom/cjs/react-router-dom.min";

const verifyPaymentForGateway = (gateway = "", searchObject = {}) => {
  switch (gateway) {
    case 'RAZORPAY': {
      return searchObject.paymentStatus === "Captured"
    }
    case 'INSTAMOJO': {
      return searchObject.payment_status === "Credit"
    }
    default: {
      return false
    }
  }
}

export default () => {
  const { reAuthenticate } = useContext(AuthContext);
  const { resetCart } = useBusinessOrderCart();
  const { refreshCart: refreshStoreCart } = useStoreCart();

  const history = useHistory();
  const location = useLocation();

  const verifyPaymentAndRedirect = async () => {
    const searchObject = getSearchObjectFromLocation(location)
    const isPaymentValid = verifyPaymentForGateway(searchObject.gateway, searchObject)

    if (isPaymentValid) {
      const { successActions, successProps, successActionsDelay } = getSuccessPaymentProps(searchObject.paymentFor);
      setTimeout(() => {
        if (successActions.includes("REAUTHENTICATE")) {
          reAuthenticate();
        }
        if (successActions.includes("CLEAR_BUSINESS_CART")) {
          resetCart();
        }
        if (successActions.includes("REFRESH_STORE_CART")) {
          refreshStoreCart();
        }
        if (successActions.includes("SHOW_SUCCESS_LOCAL_ORDER_DETAIL_PAGE")) {
          history.push(`${generatePath(MY_LOCAL_ORDER_DETAILS_PAGE, { orderId: searchObject.orderId  })}?orderSuccess=true`);
        }
        if (successActions.includes("SHOW_SUCCESS_STORE_ORDER_DETAIL_PAGE")) {
          history.push(`${generatePath(MY_STORE_ORDER_DETAILS_PAGE, { orderId: searchObject.orderId  })}?orderSuccess=true`);
        }
        else {
          history.push(SUCCESS_PAGE, { ...successProps });
        }
      }, successActionsDelay)
    }
    else {
      history.push(TECHNICAL_ERROR_PAGE, { title: "Payment Failed", description: "We could not process your payment as you closed or cancelled the payment" });
    }
  }

  useEffect(() => {
    verifyPaymentAndRedirect()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <PageWrapper>
      <ContentWrapper>
        <Loader />
        <Alert message="Please wait we verify your payment. Please do not click back, close or refresh button." />
      </ContentWrapper>
    </PageWrapper>
  );
};
