import { useLocation } from 'react-router-dom';
import { loadState } from 'library/helpers/localStorage';
import { getSearchObjectFromLocation } from 'library/helpers/url_handler';

// const patna =  {"city":"Patna","state":"BR","formattedAddress":"Patna, Bihar, India","lat":"25.59409470","lng":"85.13756450"}

const useSearchLocation = () => {
    const location = useLocation();

    const locationFromLocalStorage = loadState("_ul");
    const locationFromSearch = getSearchObjectFromLocation(location);

    const currentLocation = {
        lat: parseFloat(locationFromSearch?.lat || locationFromLocalStorage?.lat || 25.59409470),
        lng: parseFloat(locationFromSearch?.lng || locationFromLocalStorage?.lng || 85.13756450)
    }

    let formattedAddress = locationFromLocalStorage?.formattedAddress || ""
    let city = locationFromLocalStorage?.city || ""
    let state = locationFromLocalStorage?.state || ""
    
    // If location is Patna
    if(currentLocation.lat === 25.59409470 && currentLocation.lng === 85.13756450) {
        formattedAddress = "Patna, Bihar, India"
        city = "Patna"
        state = "BR"
    }
    
    return { currentLocation, formattedAddress, city, state }
};


export default useSearchLocation;
