import React, { useContext } from "react";
import { Menu } from "antd";

import { AuthContext } from "context/AuthProvider";
import StoreCart from "components/StoreCart/StoreCart";

const MainMenu = ({ className }) => {
  const { loggedIn } = useContext(AuthContext);
  return (
    <Menu className={className}>
      <Menu.Item key="1">
        <a
          className="menu-item"
          target="_blank"
          rel="noopener noreferrer"
          href={`https://holidays.diskounto.com`}
        >
          Holidays
        </a>
      </Menu.Item>
      {loggedIn && (
        <Menu.Item key="2">
          <StoreCart className="menu-item" />
        </Menu.Item>
      )}
    </Menu>
  );
};

export default MainMenu;
