import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";

export const NavigationArea = styled.div`
  background: #fafafa;

  @media only screen and (max-width: 575px) {
    .navigation-container {
      padding: 0;

    }
    .links-container {
      overflow-x: scroll;
      white-space: nowrap;
      background-color: ${themeGet("primary.0", "#008489")};

      /* Hide scrollbar for Chrome, Safari and Opera */
      &::-webkit-scrollbar {
        display: none;
      }

      /* Hide scrollbar for IE, Edge and Firefox */
      & {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
      }

      a {
        border: 1px solid #fff;
        border-right-width: 0px;
        &:last-child {
          border-right-width: 1px;
        }
        color: #fff;
      }
    }
  }

  a {
    &:hover {
      opacity: 0.8;
    }

    &::after {
      content: "";
      display: block;
      width: 0%;
      height: 2px;
      border-radius: 4px;
      position: absolute;
      left: 0;
      bottom: 0;
      opacity: 0;
      visibility: hidden;
      background-color: ${themeGet("primary.0", "#008489")};
      transition: all 0.3s ease;
    }
  }
`;

export default NavigationArea;
