import React from "react";
import PropTypes from "prop-types";
import { Empty } from "antd";
import useInfiniteScroll from "react-infinite-scroll-hook";

import Box from "components/UI/Box/Box";
import Text from "components/UI/Text/Text";

export default function SectionGrid({
  data = [],
  limit,
  loading,
  rowStyle,
  columnStyle,
  columnWidth,
  placeholder,
  renderComponent,
  showLoadMoreButton,
  handleLoadMore,
  paginationComponent,
  showEmptyIcon = false,
  emptyProps,
}) {
  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage: showLoadMoreButton,
    onLoadMore: handleLoadMore,
    disabled: false,
    rootMargin: "0px 0px 1px 0px",
  });

  // Fill array with the limit so that it will show skeleton on load
  const n = limit ? Number(limit) : 1;
  const limits = Array(n).fill(0);

  return (
    <>
      <Box className="grid_wrapper" {...rowStyle}>
        {data && data.length
          ? data.map((item) => {
              return (
                <Box
                  className="grid_column"
                  width={columnWidth}
                  key={item.id}
                  {...columnStyle}
                >
                  {typeof renderComponent === "function"
                    ? renderComponent(item)
                    : null}
                </Box>
              );
            })
          : null}
      </Box>

      {(loading || showLoadMoreButton) && (
        <div ref={sentryRef}>
        <Box
          className="grid_wrapper"
          {...rowStyle}
        >
          {limits.map((_, index) => (
            <Box
              className="grid_column"
              width={columnWidth}
              key={index}
              {...columnStyle}
            >
              {placeholder ? placeholder : <Text content="Loading ..." />}
            </Box>
          ))}
        </Box>
        </div>
      )}
      {paginationComponent && (
        <Box className="pagination_wrapper">{paginationComponent}</Box>
      )}
      {showEmptyIcon && !loading && data.length === 0 && (
        <Empty {...emptyProps} />
      )}
    </>
  );
}

SectionGrid.propTypes = {
  data: PropTypes.array.isRequired,
  limit: PropTypes.number,
  loading: PropTypes.bool,
  rowStyle: PropTypes.object,
  columnStyle: PropTypes.object,
  columnWidth: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  placeholder: PropTypes.element,
  renderComponent: PropTypes.func,
  showLoadMoreButton: PropTypes.bool,
  handleLoadMore: PropTypes.func,
  paginationComponent: PropTypes.element,
  showEmptyIcon: PropTypes.bool,
  emptyProps: PropTypes.object,
};

SectionGrid.defaultProps = {
  rowStyle: {
    flexBox: true,
    flexWrap: "wrap",
    // mr: ["-10px", "-10px", "-10px", "-10px", "-10px", "-15px"],
    // ml: ["-10px", "-10px", "-10px", "-10px", "-10px", "-15px"],
  },
  columnStyle: {
    // pr: ["10px", "10px", "10px", "10px", "10px", "15px"],
    // pl: ["10px", "10px", "10px", "10px", "10px", "15px"],
  },
  emptyProps: {},
};
