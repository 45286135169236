import styled, { css } from 'styled-components';

const ContainerWrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  ${(props) =>
    props.fullWidth &&
    css`
      width: 100%;
      max-width: none !important;
    `};
  ${(props) =>
    (props.noGutter &&
      css`
        padding-left: 0;
        padding-right: 0;
      `) ||
    css`
      padding-left: 15px;
      padding-right: 15px;

      @media only screen and (max-width: 575px) {
        padding-left: 10px;
        padding-right: 10px;
      }
    `};

  ${(props) =>
    props.fluid &&
    css`
      width: 100% !important;
      max-width: 1920px !important;
    `}

  @media (min-width: 576px) {
    max-width: 560px;
    width: 100%;
  }

  @media (min-width: 768px) {
    max-width: 750px;
    width: 100%;
  }

  @media (min-width: 992px) {
    max-width: 970px;
    width: 100%;
  }

  @media (min-width: 1200px) {
    max-width: 1170px;
    width: 100%;
  }
`;

export default ContainerWrapper;
