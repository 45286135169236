import React, { useState, useContext, useEffect } from "react";
import { Alert  } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import OtpInput from "react-otp-input";

import { SIGN_UP_PAGE } from "settings/constant";
import { fetcher } from "library/hooks/useDataApi";
import { useBaseData } from "context/BaseDataProvider";
import { AuthContext } from "context/AuthProvider";
import Button from "components/UI/Button/Button";
import Text from "components/UI/Text/Text";
import { SignUpConfirmWrapper, VerificationBox, OtpWrapper, FormWrapper } from "./SignUpConfirm.style";
import Heading from "components/UI/Heading/Heading";
import FormControl from "components/UI/FormControl/FormControl";
import { Controller, useForm } from "react-hook-form";
import { getStateFromUrl } from "library/helpers/url_handler";
import Box from "components/UI/Box/Box";

const SignUpConfirm = ({ history, location }) => {
  const searchParams = getStateFromUrl(location);
  const { preferences: { fullLogoUrl, orgName } } = useBaseData()
  const { loggedIn, onSignupConfirmed } = useContext(AuthContext);
  const [alert, setAlert] = useState({ type: "", message: "" })
  const { control, handleSubmit } = useForm();

  useEffect(() => {
    if (loggedIn || !searchParams.mobile || !searchParams.firstName) {
      history.push("/")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSubmit = async (data) => {
    try {
      const result = await fetcher(
        `${process.env.REACT_APP_API_URL}/sign-up/confirm`, "POST", {},
        JSON.stringify({ otpId: searchParams.otpId, ...data })
      );
      if (!result.user) {
        setAlert({ type: "error", message: result.errors[0].userMessage });
      } else {
        onSignupConfirmed(result.auth_token, result.user)
        history.push("/");
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onResendOtp = async () => {
    try {
      const result = await fetcher(
        `${process.env.REACT_APP_API_URL}/sign-up/resend-otp`, "POST", {},
        JSON.stringify({ otpId: searchParams.otpId })
      );
      if (!result.success) {
        setAlert({ type: "error", message: result.errors[0].userMessage });
      } else {
        setAlert({ type: "success", message: "OTP is successfully sent to your mobile" });
        setTimeout(() => {
          setAlert({ type: "", message: "" });
        }, 5000)
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <SignUpConfirmWrapper>
      <VerificationBox>
      <img src={fullLogoUrl} alt={orgName}></img>
        <OtpWrapper>
          <FormWrapper>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Heading content={`Hi! ${searchParams.firstName}`} />
              <Text mb={2} content="You need to verify your mobile to use our services. We have sent OTP to your below mobile number." />
              <Text mb={3} fontWeight="600" content={`Mobile : ${searchParams.mobile}`} />
              {alert.type && <Alert {...alert} />}
              <FormControl label={`Enter OTP`} htmlFor="otp">
                <Controller
                  as={
                    <OtpInput
                      numInputs={6}
                      separator={<span>-</span>}
                      inputStyle={"otp-input"}
                      isInputNum={true}
                    />
                  }
                  id="otp"
                  name="otp"
                  defaultValue=""
                  control={control}
                />
              </FormControl>

              <Button
                className="action_btn"
                type="primary"
                htmlType="submit"
                size="large"
                style={{ width: "100%" }}
              >
                Submit
              </Button>
              <Box flexBox flexDirection="row" justifyContent="space-between">
              <Button
                type="link"
                icon={<LeftOutlined/>}
                wrapperStyles={{ marginTop: "10px", display: "flex", justifyContent: "center" }}
                onClick={() => history.replace(SIGN_UP_PAGE)}
                >
                Back to sign up
              </Button>
              <Button
                type="link"
                wrapperStyles={{ marginTop: "10px", display: "flex", justifyContent: "center" }}
                onClick={onResendOtp}
                >
                Resend OTP
              </Button>
                </Box>
            </form>
          </FormWrapper>
        </OtpWrapper>
      </VerificationBox>
    </SignUpConfirmWrapper >
  );
};

export default SignUpConfirm;
