import React, { useContext, useEffect, useState } from "react";
import { Row, Col, Steps, Modal, Alert } from "antd";
import {
  CreditCardOutlined,
  HomeOutlined,
  ShoppingCartOutlined,
} from "@ant-design/icons";
import Sticky from "react-stickynode";
import { uniq } from "lodash";

import {
  PAYMENT_PROCESSING_PAGE,
  STORE_CHECKOUT_PAGE,
  } from "settings/constant";
import { fetcher } from "library/hooks/useDataApi";
import { useStoreCart } from "context/StoreCartProvider";
import { AuthContext } from "context/AuthProvider";
import { useBaseData } from "context/BaseDataProvider";
import Container from "components/UI/Container/Container";
import Box from "components/UI/Box/Box";
import Text from "components/UI/Text/Text";
import Loader from "components/Loader/Loader";
import PriceSummary from "./PriceSummary";
import SelectedAddress from "./SelectedAddress";
import SelectAddress from "./SelectAddress";
import ConfirmedCart from "./ConfirmedCart";
import StoreCheckoutWrapper from "./StoreCheckout.style";

const StoreCheckout = ({ history, location }) => {
  const { user } = useContext(AuthContext);
  const {
    preferences: { orgName },
  } = useBaseData();
  const { loading, cart = [] } = useStoreCart();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [step, setStep] = useState(1);
  const [addressId, setAddressId] = useState();
  const [delivery, setDelivery] = useState({
    loading: false,
    canProceedToPay: false,
    items: [],
  });

  useEffect(() => {
    checkAdressForDelivery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressId]);

  const handlePlaceOrder = async () => {
    if (!addressId) {
      Modal.error({
        title: "Please select an address",
        content:
          "It seems like you have not selected an address. Please select to continue.",
      });
      return;
    }
    setIsSubmitting(true);
    try {
      history.push(PAYMENT_PROCESSING_PAGE, {
        gateway: "RAZORPAY",
        paymentProps: {
          orderAPI: `${process.env.REACT_APP_API_URL}/store/order`,
          paymentDescription: `Payment to ${orgName}`,
          orderBody: { cart, addressId },
          errorPage: STORE_CHECKOUT_PAGE,
        },
        paymentFor: "STORE_ORDER",
      });
    } catch (e) {
      // Handle errors here ex: Timeout or server
    } finally {
      setIsSubmitting(false);
    }
  };

  const checkAdressForDelivery = async () => {
    try {
      if (!addressId) {
        setDelivery((delivery) => ({
          ...delivery,
          loading: false,
          canProceedToPay: false,
          items: [],
        }));
        return;
      }
      setDelivery((delivery) => ({
        ...delivery,
        loading: true,
        canProceedToPay: false,
        items: [],
      }));
      const { pin } = user.address.find((address) => address.id === addressId);
      const productIds = uniq(cart.map((item) => item.productId));

      const url = `${process.env.REACT_APP_API_URL}/servicability/store`;
      const result = await fetcher(
        `${url}?destinationPin=${pin}&productId=${productIds.join(",")}`,
        "GET"
      );
      if (result.errors) {
        setDelivery((delivery) => ({
          ...delivery,
          loading: false,
          canProceedToPay: false,
          items: cart.map((item) => ({
            productId: item.productId,
            servicable: false,
          })),
        }));
      } else {
        setDelivery((delivery) => ({
          ...delivery,
          loading: false,
          canProceedToPay: result.find((item) => !item.servicable)
            ? false
            : true,
          items: result,
        }));
      }
    } catch (e) {
      // Handle errors here ex: Timeout or server
    }
  };

  if (loading) {
    return <Loader />;
  }

  if (!cart.length) {
    return (
      <Row gutter={30}>
        <Col xs={16} push={4}>
          <Box
            flexBox
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            mt="20vh"
          >
            <ShoppingCartOutlined style={{ fontSize: 40 }} />
            <Text
              fontWeight="600"
              fontSize="18"
              content="Your cart is empty!"
            />
            <Text
              fontWeight="400"
              fontSize="16"
              content="Explore our wide selection and find something you like"
            />
          </Box>
        </Col>
      </Row>
    );
  }

  return (
    <StoreCheckoutWrapper>
      <Container fluid={false}>
        <Box my={[3, 3, 3, 4]}>
          <Steps
            responsive={false}
            direction="horizontal"
            current={step}
            items={[
              {
                title: (
                  <Text
                    fontSize={["12px", "12px", "14px", "16px"]}
                    content="Cart"
                  />
                ),
                icon: <ShoppingCartOutlined />,
              },
              {
                title: (
                  <Text
                    fontSize={["12px", "12px", "14px", "16px"]}
                    content="Address"
                  />
                ),
                icon: <HomeOutlined />,
              },
              {
                title: (
                  <Text
                    fontSize={["12px", "12px", "14px", "16px"]}
                    content="Payment"
                  />
                ),
                icon: <CreditCardOutlined />,
              },
            ]}
          />
        </Box>

        <Row gutter={30}>
          <Col xs={24} lg={16}>
            {location.state?.errorMessage && (
              <Alert
                message={location.state?.errorMessage}
                type="error"
                showIcon
                style={{ marginBottom: 10 }}
              />
            )}

            <Box>
              {step === 1 && (
                <SelectAddress
                  address={user?.address || []}
                  addressId={addressId}
                  onChangeAddressId={(value) => {
                    setAddressId(value);
                    setStep(2);
                  }}
                />
              )}
              {step === 2 && (
                <SelectedAddress
                  address={
                    user?.address.find((item) => item.id === addressId) || []
                  }
                  onChangeAddress={() => {
                    setAddressId(undefined);
                    setStep(1);
                  }}
                />
              )}
            </Box>

            <Box mt={3}>
              <ConfirmedCart isActive={step === 2} delivery={delivery} />
            </Box>
          </Col>
          <Col xs={24} lg={8}>
            <Sticky innerZ={9999} top={100}>
              <PriceSummary
                canProceedToPay={delivery.canProceedToPay}
                isPaymentInProgress={isSubmitting}
                onMakePayment={handlePlaceOrder}
              />
            </Sticky>
          </Col>
        </Row>
      </Container>
    </StoreCheckoutWrapper>
  );
};

export default StoreCheckout;
