import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { useTheme } from "styled-components";
import { get } from "lodash";

import { useBaseData } from "context/BaseDataProvider";
import { loadScript } from "library/helpers/utils";
import { fetcher } from "library/hooks/useDataApi";
import { TECHNICAL_ERROR_PAGE } from "settings/constant";
import { AuthContext } from "context/AuthProvider";
import { Button } from "./PayWithRazorpay.style";
import { getSearchStringFromObject } from "library/helpers/url_handler";

const RAZORPAY_CHECKOUT_URL = "https://checkout.razorpay.com/v1/checkout.js";

const PayWithRazorpay = ({
  btnStyle = {},
  btnRef,
  buttonText = "",
  orderAPI,
  orderBody = {},
  errorPage,
  paymentDescription = "",
  callbackQueryParams,
  onScriptLoaded = () => { },
  onOrderCreated = () => { },
  onModalDismissed = () => { },
}) => {
  const { preferences: { orgName, fullLogoUrl } } = useBaseData()
  const theme = useTheme();
  const history = useHistory();
  const { user } = useContext(AuthContext);

  const displayRazorpay = async () => {
    const res = await loadScript(RAZORPAY_CHECKOUT_URL);
    if (!res) {
      if (errorPage) {
        return history.replace(errorPage, { errorMessage: "Your payment could not be processed. Please try after sometime." });
      }
      return history.replace(TECHNICAL_ERROR_PAGE);
    }
    onScriptLoaded();

    const data = await fetcher(`${orderAPI}`, "POST", {}, JSON.stringify({ ...orderBody, gateway: "RAZORPAY" }));
    if (data.errors) {
      if (errorPage) {
        return history.replace(errorPage, { errorMessage: data.errors[0].userMessage });
      }
      return history.replace(TECHNICAL_ERROR_PAGE);
    } else {
      onOrderCreated(data);
      const options = {
        key: data.apiKey,
        currency: data.result.currency,
        amount: data.result.amount.toString(),
        order_id: data.result.id,
        name: `${orgName}`,
        image: fullLogoUrl,
        description: paymentDescription,
        modal: {
          ondismiss: function () {
            onModalDismissed();
          },
        },
        prefill: {
          name: `${user?.firstName || ""} ${user.lastName || ""}`,
          email: "",
          contact: user?.mobile || "",
        },
        theme: {
          color: get(theme, "primary.0", "#ff6105"),
        },
        callback_url: `${process.env.REACT_APP_API_URL}/razorpay-callback${getSearchStringFromObject(
          {
            ...callbackQueryParams,
            orderId: data?.data?.orderId || "",
          }
        )}`,
        redirect: true
      };
      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    }
  };
  return (
    <Button ref={btnRef} onClick={displayRazorpay} style={btnStyle}>
      {buttonText}
    </Button>
  );
};

export default PayWithRazorpay;
