import React, { useMemo } from "react";
import { Row, Col, Typography } from "antd";
import { DateTime } from "luxon";
import { FaRupeeSign } from "react-icons/fa";
import { RWebShare } from "react-web-share";
import { generatePath } from "react-router-dom";
import { ShareAltOutlined } from "@ant-design/icons";

import { useBaseData } from "context/BaseDataProvider";
import Text from "components/UI/Text/Text";
import { DiskountoCoinIcon } from "components/Icon/Icon";
import Box from "components/UI/Box/Box";
import { INVITE_PAGE } from "settings/constant";
import { isB2B } from "library/helpers/utils";
import ProfileDetailsWrapper, {
  NameWrapper,
  DetailsWrapper,
} from "./ProfileDetails.style";

const ProfileDetails = ({ user, closeDropdown }) => {
  const {
    preferences: {
      orgDomain,
      orgName,
      loyaltyCoinText,
      loyaltyCashText,
      primaryColor,
    },
  } = useBaseData();
  const {
    firstName = "",
    lastName = "",
    referCode,
    cashBalance,
    promoCoins,
    hasActiveMembership,
    membership,
  } = user || {};
  const name = `${firstName} ${lastName}`;

  const membershipDetails = useMemo(() => {
    if (!membership?.joinedOn) {
      return { status: "New" };
    }
    if (hasActiveMembership) {
      return {
        status: "Active",
        expiryHeading: "Expiry",
        expiry: membership.endDate,
      };
    }
    return {
      status: "Expired",
      expiryHeading: "Expired On",
      expiry: membership.endDate,
    };
  }, [hasActiveMembership, membership]);

  if (isB2B) {
    return (
      <ProfileDetailsWrapper>
        {name && <NameWrapper>{name}</NameWrapper>}
        <div style={{ background: primaryColor, height: 5 }} />
      </ProfileDetailsWrapper>
    );
  }
  return (
    <ProfileDetailsWrapper>
      {name && <NameWrapper>{name}</NameWrapper>}
      <DetailsWrapper>
        <Row>
          <Col>
            <Text as="div" content={`${loyaltyCoinText}`} />
            <Typography.Text>
              <DiskountoCoinIcon style={{ marginRight: 4 }} />
              {promoCoins}
            </Typography.Text>
          </Col>
          <Col>
            <Text as="div" content={`${loyaltyCashText}`} />
            <Typography.Text>
              <FaRupeeSign />
              {parseFloat(cashBalance).toFixed(2)}
            </Typography.Text>
          </Col>
        </Row>
        <Row>
          <Col>
            <Text as="div" content="Invite Code" />
            <Box flexBox flexDirection="row" alignItems="center">
              <Typography.Text>{referCode}</Typography.Text>
              <div onClick={closeDropdown}>
                <RWebShare
                  data={{
                    text: `${orgName} brings you a great platform to enhance the shopping experience and save your pocket. Here is my invite link`,
                    url: `${orgDomain}${generatePath(INVITE_PAGE, {
                      inviteCode: referCode,
                    })}`,
                    title: "Refer & Earn",
                  }}
                  onClick={() => console.log("shared successfully!")}
                >
                  <ShareAltOutlined style={{ fontSize: 20, marginLeft: 10 }} />
                </RWebShare>
              </div>
            </Box>
          </Col>
        </Row>
        <Row>
          <Col>
            <Text as="div" content="Membership" />
            <Typography.Text>{membershipDetails.status}</Typography.Text>
          </Col>
          {membershipDetails.expiry && (
            <Col>
              <Text as="div" content={membershipDetails.expiryHeading} />
              <Typography.Text>
                {DateTime.fromISO(membershipDetails.expiry).toLocaleString(
                  DateTime.DATE_SHORT
                )}
              </Typography.Text>
            </Col>
          )}
        </Row>
      </DetailsWrapper>
    </ProfileDetailsWrapper>
  );
};

export default ProfileDetails;
