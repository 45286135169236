import styled from "styled-components";

export const FormContent = styled.div`
  flex: 1;
  width: 100%;
  @media only screen and (max-width: 480px) {
    margin-top: 50px;
  }
  .ant-alert {
    margin-bottom: 20px;
  }
  .form-control {
    margin-bottom: 30px;
  }
  .
`;

export const AffixBottom = styled.div`
  background-color: white;
  box-shadow: 0 -2px 10px 0 rgba(0,0,0,.1);
  position: absolute;
  bottom: 0;
  margin-left: -24px;
  padding: 15px 24px;
  width: 100%;
`;
export const FormAction = styled.div`
  button[type="submit"] {
    align-items: center;
    padding: 11px 40px;
    font-size: 15px;
    height: 47px;
  }
`;
