import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { DateTime } from "luxon";
import { get } from "lodash";
import { List, Col, Row, Tag, Empty, Alert, Result } from "antd";

import { getStateFromUrl } from "library/helpers/url_handler";
import useDataApi from "library/hooks/useDataApi";
import {
  formatCurrency,
  getOrderPaymentDetails,
  getOrderStateColor,
  getOrderStateTextForCustomer,
} from "library/helpers/utils";
import { HOME_PAGE, MY_ORDERS_PAGE } from "settings/constant";
import { AuthContext } from "context/AuthProvider";
import Image from "components/UI/Image/Image";
import Text from "components/UI/Text/Text";
import Box from "components/UI/Box/Box";
import Button from "components/UI/Button/Button";
import { useBaseData } from "context/BaseDataProvider";
import MyOrderActions from "./OrderActions";
import orderFailedImage from "assets/images/order-failed.jpg";
import Container from "components/UI/Container/Container";
import Loader from "components/Loader/Loader";
import { OrderDetailsWrapper } from "./Order.style";
import TextLink from "components/UI/TextLink/TextLink";

const MyOrderDetails = ({ history, location }) => {
  const { getAssetUrl } = useBaseData();
  const { reAuthenticate } = useContext(AuthContext);
  const { orderId } = useParams();
  const { orderSuccess } = getStateFromUrl(location);
  const {
    data = [],
    updateData,
    loading,
  } = useDataApi(
    `${process.env.REACT_APP_API_URL}/nearby/my-orders/${orderId}`,
    20
  );

  const handleOrderChange = (newData) => {
    updateData(
      data.map((item) =>
        item.orderId === newData.orderId ? { ...item, ...newData } : item
      )
    );
    reAuthenticate();
  };

  if (loading) {
    return <Loader />;
  }

  if (!loading && data.length === 0) {
    if (String(orderSuccess) === "true") {
      return (
        <>
          <Box flexBox flexDirection="column" flex={1} mb={2} bg="#f2f2f2">
            <Result
              //  style={{ height: "calc(100vh - 90px)"}}
              status={"error"}
              title="Your payment could not be verified"
              subTitle={
                <>
                  <div>
                    If your payment was deducted then if will be automatically
                    refunded to the source within 3-5 working days.
                  </div>
                  <div>
                    Please contact support for more information or try again
                    later.
                  </div>
                  <div>Reference ID : {orderId}</div>
                </>
              }
              extra={
                <Button
                  type="primary"
                  onClick={() => window.location.replace("/")}
                >
                  Back to Home
                </Button>
              }
            />
          </Box>
          <Box flexBox justifyContent="center">
            <Image src={orderFailedImage} width="500px" alt="Order Failed" />
          </Box>
        </>
      );
    }
    return (
      <Row gutter={30}>
        <Col xs={24} lg={16} push={4}>
          <Box height="50vh" marginTop="20vh">
            <Empty description="You have not placed any order !">
              <Button onClick={() => history.push(HOME_PAGE)}>Shop Now</Button>
            </Empty>
          </Box>
        </Col>
      </Row>
    );
  }

  const order = data[0];
  const cancelledItems = get(order, "initialOrder.cancelledItems", []).map(
    (i) => ({ ...i, isCancelled: true })
  );

  return (
    <OrderDetailsWrapper>
      <Container fluid={false}>
        {String(orderSuccess) === "true" && (
          <Box my={2} bg="#f2f2f2">
            <Result
              status={order ? "success" : "info"}
              title="Congratulations! Your order is successful"
              subTitle={`Order ID: ${order.orderId}`}
              extra={
                <Button
                  onClick={() =>
                    history.push(`${MY_ORDERS_PAGE}?orderType=nearby`)
                  }
                >
                  View all orders
                </Button>
              }
            />
          </Box>
        )}
        {String(orderSuccess) !== "true" && (
          <Box my={3}>
            <TextLink
              to={`${MY_ORDERS_PAGE}?orderType=nearby`}
              fontWeight={300}
              fontSize={16}
              content="< Back to orders"
            />
          </Box>
        )}
        <List
          bordered
          dataSource={[...order.items, ...cancelledItems] || []}
          header={
            <Row gutter={30}>
              <Col xs={12}>
                <Box>
                  <Text fontWeight="600" content="Order ID" />
                  <Text content={order.orderId} />
                </Box>
              </Col>
              <Col xs={12} className="order-status">
                <Box
                  flexBox
                  flex={1}
                  flexDirection="column"
                  alignItems="flex-end"
                >
                  <Text fontWeight="600" content="Order Status" />
                  <Tag color={getOrderStateColor(order.orderState)}>
                    {getOrderStateTextForCustomer(
                      order.orderState,
                      order.orderType === "BOOM_DELIVERY"
                    )}{" "}
                  </Tag>
                </Box>
              </Col>
              <Col xs={12}>
                <Box>
                  <Text fontWeight="600" content="Ordered At" />
                  <Text
                    content={DateTime.fromISO(order.createdAt).toFormat(
                      "dd/MM/yyyy hh:mm a"
                    )}
                  />
                </Box>
              </Col>
              <Col xs={12}>
                <Box
                  flexBox
                  flex={1}
                  flexDirection="column"
                  alignItems="flex-end"
                >
                  <Text fontWeight="600" content={"Order Amount"} />
                  <Text
                    ml={1}
                    content={formatCurrency(order.pricing?.customerToPay, 2)}
                  />
                  <Text
                    textAlign="right"
                    content={getOrderPaymentDetails(order.transaction)}
                  />
                </Box>
              </Col>
              <Col xs={24} sm={12}>
                {["BOOM_PICKUP", "BOOM_OFFLINE"].includes(order.orderType) && (
                  <Box>
                    <Text fontWeight="600" content="Pickup By" />
                    <Text
                      content={`${order.user.firstName} ${order.user.lastName}`}
                    />
                    <Text content={`${order.user.mobile}`} />
                  </Box>
                )}
                {["BOOM_PICKUP", "BOOM_OFFLINE"].includes(order.orderType) && (
                  <Box mt={2} flexBox flexDirection="column">
                    <Text fontWeight="600" content="Pickup Address" />
                    <Text content={`${order.pickupDetails.name}`} />
                    <Text
                      as="address"
                      content={`${order.pickupDetails.address}`}
                    />
                    <Text content={`${order.pickupDetails.phoneNumber}`} />
                  </Box>
                )}

                {order.orderType === "BOOM_DELIVERY" && (
                  <Box mt={2} flexBox flexDirection="column">
                    <Text fontWeight="600" content="Store name" />
                    <Text content={`${order.pickupDetails.name}`} />
                  </Box>
                )}
                {order.orderType === "BOOM_DELIVERY" && (
                  <Box mt={2} flexBox flexDirection="column">
                    <Text fontWeight="600" content="Delivery Address" />
                    <Text content={`${order.deliveryDetails.name}`} />
                    <Text
                      as="address"
                      content={`${order.deliveryDetails.address}`}
                    />
                    <Text
                      as="address"
                      content={`${order.deliveryDetails.city} ${order.deliveryDetails.state} ${order.deliveryDetails.pin}`}
                    />
                    <Text content={`${order.deliveryDetails.mobileNo}`} />
                  </Box>
                )}
              </Col>
              <Col xs={24} sm={12}>
                <MyOrderActions
                  order={order}
                  onOrderChange={handleOrderChange}
                />
              </Col>
              <Col xs={24}>
                {order.isItemsModified && (
                  <Alert
                    showIcon
                    type="warning"
                    message={`The order has been modified. Few items in the order has been cancelled or updated due to operational reasons.`}
                  />
                )}
              </Col>
            </Row>
          }
          footer={
            <Row>
              <Col xs={0} sm={6} md={12} />
              <Col xs={24} sm={18} md={12}>
                <Box>
                  <Box
                    flexBox
                    flexDirection="row"
                    justifyContent="space-between"
                  >
                    <Text content={`Total`} />
                    <Box flexBox flexDirection="row">
                      {order.isItemsModified && (
                        <Text
                          ml={1}
                          color="gray"
                          content={formatCurrency(
                            order.initialOrder?.pricing?.totalPrice,
                            2
                          )}
                          className="line-through"
                        />
                      )}
                      <Text
                        ml={1}
                        content={formatCurrency(order.pricing?.totalSellingPrice, 2)}
                      />
                    </Box>
                  </Box>

                  <Box
                    flexBox
                    flexDirection="row"
                    justifyContent="space-between"
                  >
                    <Text content={`Service Charge`} />
                    <Box flexBox flexDirection="row">
                      {order.isItemsModified &&
                        get(
                          order,
                          "initialOrder.pricing.transactionCharge.amount",
                          0
                        ) +
                          get(
                            order,
                            "initialOrder.pricing.transactionCharge.gst",
                            0
                          ) !==
                          get(order, "pricing.transactionCharge.amount", 0) +
                            get(order, "pricing.transactionCharge.gst", 0) && (
                          <Text
                            ml={1}
                            color="gray"
                            content={formatCurrency(
                              order.initialOrder?.pricing?.transactionCharge
                                .amount +
                                order.initialOrder?.pricing?.transactionCharge
                                  .gst,
                              2
                            )}
                            className="line-through"
                          />
                        )}
                      <Text
                        ml={1}
                        content={formatCurrency(
                          order.pricing?.transactionCharge.amount +
                            order.pricing?.transactionCharge.gst,
                          2
                        )}
                      />
                    </Box>
                  </Box>

                  {order.orderType === "BOOM_DELIVERY" && (
                    <Box
                      flexBox
                      flexDirection="row"
                      justifyContent="space-between"
                    >
                      <Text content={`Delivery Charge`} />
                      <Text
                        ml={1}
                        content={formatCurrency(
                          order.pricing?.deliveryCharge.amount +
                            order.pricing?.deliveryCharge.gst,
                          2
                        )}
                      />
                    </Box>
                  )}

                  <Box
                    flexBox
                    flexDirection="row"
                    justifyContent="space-between"
                  >
                    <Text
                      fontWeight="600"
                      fontSize={18}
                      content={`Order Amount`}
                    />
                    <Box flexBox flexDirection="row">
                      {order.isItemsModified &&
                        get(order, "initialOrder.pricing.customerToPay", 0) !==
                          get(order, "pricing.customerToPay", 0) && (
                          <Text
                            ml={1}
                            color="gray"
                            fontWeight="600"
                            fontSize={18}
                            content={formatCurrency(
                              order.initialOrder?.pricing?.customerToPay,
                              2
                            )}
                            className="line-through"
                          />
                        )}
                      <Text
                        ml={1}
                        fontWeight="600"
                        fontSize={18}
                        content={formatCurrency(
                          order.pricing?.customerToPay,
                          2
                        )}
                      />
                    </Box>
                  </Box>
                </Box>
              </Col>
            </Row>
          }
          renderItem={(item) => {
            return (
              <List.Item>
                <Row>
                  <Col xs={24} sm={16}>
                    <Box flexBox flex={1}>
                      <Image
                        src={getAssetUrl(item?.meta?.photos[0]?.path)}
                        alt={item.meta.productName}
                        width="100px"
                      />
                      <Box
                        flexBox
                        flex={1}
                        flexDirection="column"
                        justifyContent="center"
                        ml={3}
                        mr={3}
                      >
                        {item.isCancelled && (
                          <Box>
                            <Tag color="red" style={{ height: "22px" }}>
                              Cancelled
                            </Tag>
                          </Box>
                        )}
                        <Text
                          mr={2}
                          fontWeight="600"
                          content={item.meta.productName}
                        />
                        <Text content={get(item, "meta.variant.name", "")} />
                        {/* <Text content={`Quantity : ${item.quantity}`} /> */}
                        <Row>
                          <Col xs={24} sm={24}>
                            <Box
                              flexBox
                              flexDirection="row"
                              alignItems="center"
                            >
                              <Text content={`${item.quantity}`} />
                              <Text
                                ml={1}
                                mr={1}
                                color="gray"
                                fontSize={10}
                                content={`X`}
                              />
                              <Text
                                content={formatCurrency(
                                  item.meta.variant.sellingPrice
                                )}
                              />
                            </Box>
                          </Col>
                        </Row>
                      </Box>
                    </Box>
                  </Col>
                  <Col xs={0} sm={8}>
                    <Box
                      flexBox
                      height="100%"
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="flex-end"
                    >
                      <Text
                        ml={1}
                        color={item.isCancelled ? "gray" : "black"}
                        fontSize={item.isCancelled ? 12 : 16}
                        content={formatCurrency(item.pricing.totalSellingPrice)}
                        className={item.isCancelled ? "line-through" : ""}
                      />
                      {item.isCancelled && (
                        <Text
                          ml={1}
                          fontSize={16}
                          content={formatCurrency(0)}
                        />
                      )}
                    </Box>
                  </Col>
                </Row>
              </List.Item>
            );
          }}
        />
      </Container>
    </OrderDetailsWrapper>
  );
};

export default MyOrderDetails;
