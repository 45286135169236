import { get } from "lodash";
import { MY_ORDERS_PAGE } from "settings/constant";

export const SuccessPaymentProps = {
    BUSINESS_ORDER: {
        successProps: {
            title: "Congratulations!",
            description: "Your order has been created. You will receive the notification soon on mobile",
            buttonText: "View Orders",
            buttonLink: `${MY_ORDERS_PAGE}?orderType=nearby`,
        },
        successActions: ["CLEAR_BUSINESS_CART", "SHOW_SUCCESS_ORDER_DETAIL_PAGE"],
        successActionsDelay: 5000
    },
    STORE_ORDER: {
        successProps: {
            title: "Congratulations!",
            description: "Your order has been created. You will receive the notification soon on mobile",
            buttonText: "View Orders",
            buttonLink: `${MY_ORDERS_PAGE}?orderType=store`,
        },
        successActions: ["REFRESH_STORE_CART", "SHOW_SUCCESS_STORE_ORDER_DETAIL_PAGE"],
        successActionsDelay: 5000
    },
}

export const getSuccessPaymentProps = (paymentFor) => {
    return {
      successProps: {},
      successActions: [],
      successActionsDelay: 10000,
      ...get(SuccessPaymentProps, paymentFor, {})
    }
  }