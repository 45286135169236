import React, { useContext } from 'react';
import { Card } from 'antd';

import Heading from 'components/UI/Heading/Heading';
import Text from 'components/UI/Text/Text';
import Map from 'components/Map/Map';
import PickupDetailsWrapper from './PickupDetails.style';
import { AuthContext } from 'context/AuthProvider';
import { EnvironmentOutlined, UserOutlined } from '@ant-design/icons';
import Box from 'components/UI/Box/Box';
import { useBaseData } from 'context/BaseDataProvider';

const PickupDetails = ({ business }) => {
  const { preferences: { primaryColor } } = useBaseData()
  const { user } = useContext(AuthContext)
  return (
    <PickupDetailsWrapper>
      <Card className="outer">
        <Heading
          as="h3"
          content={<Box><UserOutlined style={{ color: primaryColor, marginRight: 5 }} />Customer Details</Box>}
        />
        <Text fontSize={[12, 14, 14]} content={`${user.firstName} ${user.lastName}`} />
        <Text fontSize={[12, 14, 14]} content={`${user.mobile}/${user.email}`} />
      </Card>
      <Card className="outer">
        <Heading
          as="h3"
          content={<Box><EnvironmentOutlined style={{ color: primaryColor, marginRight: 5 }} />Pickup Address</Box>}
        />
        <Text fontSize={[12, 14, 14]} content={`${business.name}`} />
        <Text fontSize={[12, 14, 14]} content={`${business.address}`} mb={3} />
        <Map
          data={[business]}
          multiple={false}
          height="250px"
          defaultZoom={14}
          defaultCenter={
            business
              ? {
                lat: business.location.coordinates[0],
                lng: business.location.coordinates[1],
              }
              : null
          }
        />
      </Card>

    </PickupDetailsWrapper>
  )
}

export default PickupDetails
