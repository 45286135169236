import React, { useEffect } from "react";
import { Row, Col, List, Tag } from "antd";
import { generatePath } from 'react-router-dom';
import { RightOutlined } from "@ant-design/icons";
import { DateTime } from "luxon";

import useDataApi from "library/hooks/useDataApi";
import {
  formatCurrency,
  getOrderStateColor,
  getOrderStateTextForCustomer,
} from "library/helpers/utils";
import { MY_LOCAL_ORDER_DETAILS_PAGE } from "settings/constant";
import Box from "components/UI/Box/Box";
import Text from "components/UI/Text/Text";
import Button from "components/UI/Button/Button";
import Loader from "components/Loader/Loader";
import { OrderListWrapper } from "./MyOrders.style";

const NearbyOrders = ({ history, location }) => {
  const url = `${process.env.REACT_APP_API_URL}/nearby/my-orders`
  const {
    data: orders = [],
    loading,
    showLoadMore,
    loadMoreData,
    doFetch,
  } = useDataApi(url + location.search, 20);

  useEffect(() => {
    doFetch(url + location.search || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  if (loading && (orders.length === 0)) {
    return <Loader />;
  }

  return (
    <>
      <OrderListWrapper>
        <List
          rowKey={(item) => item.orderId}
          bordered
          dataSource={orders}
          locale={{ emptyText: "No Orders" }}
          itemLayout="horizontal"
          renderItem={(order) => {
            return (
              <List.Item
                extra={<RightOutlined />}
                onClick={() =>
                  history.push(generatePath(MY_LOCAL_ORDER_DETAILS_PAGE, { orderId: order.orderId}))
                }
              >
                <Row gutter={30} style={{ width: "100%" }}>
                  <Col xs={24} sm={12}>
                    <Box>
                      <Text
                        fontSize={[10, 12]}
                        content={DateTime.fromISO(order.createdAt).toFormat(
                          "dd MMM yyyy, hh:mm a"
                        )}
                      />
                      <Text fontWeight="600" content={order.orderId} />
                      <Text
                        content={formatCurrency(order.pricing.customerToPay, 2)}
                      />
                    </Box>
                  </Col>

                  <Col xs={24} sm={12}>
                    <Box flexBox flex={1} alignItems="center">
                      <Tag color={getOrderStateColor(order.orderState)}>
                        {getOrderStateTextForCustomer(
                          order.orderState,
                          order.orderType === "BOOM_DELIVERY"
                        )}
                      </Tag>
                    </Box>
                  </Col>
                </Row>
              </List.Item>
            );
          }}
        />
      </OrderListWrapper>
      {showLoadMore && (
        <Box mt={4} flexBox justifyContent="center">
          <Button onClick={loadMoreData}>Load more orders</Button>
        </Box>
      )}
    </>
  );
};

export default NearbyOrders;
