import React from "react";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import Text from "components/UI/Text/Text";
import { handlePlural } from "library/helpers/utils";

const ReturnExchange = ({ returnDays, exchangeDays }) => {
  const isReturnable = returnDays > 0;
  const isExchangable = exchangeDays > 0;
  if (!isReturnable && !isExchangable) {
    return (
      <>
        <CloseCircleOutlined style={{ color: "indianred" }} />
        <Text
          ml={1}
          as="span"
          fontWeight="300"
          fontSize="12"
          content={<>No return or exchange available</>}
        />
      </>
    );
  }
  return (
    <>
      <CheckCircleOutlined style={{ color: "green" }} />
      {isReturnable && (
        <Text
          ml={1}
          as="span"
          fontWeight="300"
          fontSize="12"
          content={<>{handlePlural(returnDays, "day", "s")} return available</>}
        />
      )}
      {!isReturnable && isExchangable && (
        <Text
          ml={1}
          as="span"
          fontWeight="300"
          fontSize="12"
          content={
            <>{handlePlural(exchangeDays, "day", "s")} exchange available</>
          }
        />
      )}
    </>
  );
};

export default ReturnExchange;
