import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";

const BusinessCartWrapper = styled.div`
  .ant-list {
    border-color: #F0F0F0;
    .ant-list-header {
      background-color: ${themeGet("color.2", "#F7F7F7")};
    }
    .ant-spin-container {
      // NavBar 80px
      // Cover Photo 180px
      // Header 50px
      // Subtotal 96px
      // Checkout Button 78px
      // Padding Between Navbar & cart 20px & cart & footer 20px
      max-height: ${({ coverHeight = 0 }) =>
        `calc(100vh - 80px - ${coverHeight}px - 50px - 96px - 78px - 40px)`};
      overflow-y: scroll;
    }
  }
`;

export const CartListDrawerWrapper = styled.div`
  .ant-list {
    .ant-list-header {
      background-color: ${themeGet("color.2", "#F7F7F7")};
    }
  }
  .view-cart-btn {
    > span {
      text-decoration: 1px dotted underline;
    }
  }
`;

export default BusinessCartWrapper;
