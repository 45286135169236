import React from "react";
import { useParams } from "react-router-dom";
import CommonPage from "components/CommonPage/CommonPage";

const Page = () => {
  const { code } = useParams()
  return <CommonPage pageCode={code} />;
};

export default Page;
