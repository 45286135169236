import React from 'react';
import { Card } from 'antd';

import DeliveryDetailsWrapper from './DeliveryDetails.style';
import AddressList from './AddressList';
import SlotSelector from './SlotSelector';
import { useBusinessOrderCart } from 'context/BusinessOrderCartProvider';

const DeliveryDetails = ({ business }) => {
  const { state } = useBusinessOrderCart();
  return (
    <DeliveryDetailsWrapper>
      <Card mt={4} mb={4} className={`outer ${!state.address ? "bordered" : ""}`}>
        <AddressList />
      </Card>

      {
        business?.requireSlot === "YES" && state.address ?
          <Card mt={4} mb={4} className={`outer ${state.address && !state.slot ? "bordered" : ""}`}>
            <SlotSelector />
          </Card> : null
      }

    </DeliveryDetailsWrapper >
  )
}

export default DeliveryDetails
