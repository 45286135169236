import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

export const FormWrapper = styled.div`
  width: 50%;
  background-color: #F1F1F1;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 130px;

  @media only screen and (max-width: 991px) {
    padding: 0 15%;
    width: 100%;
    margin: 0 auto;
  }
  @media only screen and (max-width: 767px) {
    padding: 0 15%;
    width: 100%;
  }
  @media only screen and (max-width: 480px) {
    padding: 0 30px;
  }

  .signin_form,
  .signup_form,
  .reset_password_form,
  .forgot_password_form {
    margin-top: 40px;
    margin-bottom: 30px;
  }

  .otp-input {
    width: 2.5rem !important;
    height: 2.5rem;
    &:focus-visible {
      outline-color: black;
    }
  }

  .action_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    height: 57px;
    font-size: 17px;
    font-weight: 700;
    margin-bottom: 10px;
    border-color: ${themeGet('primary.0', '#008489')};
    background-color: ${themeGet('primary.0', '#008489')};
    svg {
      width: 18px;
      height: 18px;
      margin-right: 2px;
    }
    &::after {
      content: none;
    }
    &:hover,
    &:focus {
      border-color: ${themeGet('secondary.0', '#6EC5B8')};
      background-color: ${themeGet('secondary.0', '#6EC5B8')};
    }
  }

  .form-error {
    margin-top : 20px;
  }

  .ant-divider {
    margin: 41px 0 !important;
    .ant-divider-inner-text {
      font-size: 15px;
      font-weight: 700;
      color: ${themeGet('text.2', '#777777')};
    }
  }

  .ant-alert{
    margin-bottom: 20px;
  }
`;

export const FieldWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  > a {
    color: ${themeGet('primary.0', '#008489')};
    font-size: 15px;
    font-weight: 700;
    line-height: 1;
    &:hover,
    &:focus {
      outline: none;
      color: ${themeGet('secondary.0', '#6EC5B8')};
      text-decoration: none;
    }
  }
`;

export const SwitchWrapper = styled.div`
`;

export const Label = styled.span`
  font-size: 15px;
  line-height: 1;
  font-weight: 700;
  color: ${themeGet('text.0', '#2C2C2C')};
  padding: 0 8px;
`;

export const Title = styled.h2`
  color: ${themeGet('text.0', '#2C2C2C')};
  font-size: 45px;
  line-height: 54px;
  font-weight: 700;
  margin-bottom: 0;
  margin-top: 58px;
  @media only screen and (max-width: 1600px) {
    font-size: 38px;
    line-height: 48px;
  }
  @media only screen and (max-width: 1440px) {
    font-size: 32px;
    line-height: 42px;
  }
  @media only screen and (max-width: 1200px) {
    font-size: 28px;
    line-height: 32px;
  }
`;

export const Text = styled.p`
  text-align: center;
  font-size: 15px;
  font-weight: 700;
  color: ${themeGet('text.2', '#777777')};
  line-height: 1;
  a {
    color: ${themeGet('primary.0', '#008489')};
    &:hover,
    &:focus {
      outline: none;
      color: ${themeGet('secondary.0', '#6EC5B8')};
      text-decoration: none;
    }
  }
`;

export const ImgQuote = styled.blockquote`
  width: 50%;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  font-style: italic;
  color: ${themeGet('text.2', '#777777')};
  line-height: 1.2;
  margin-top: 12px;
`;

export const BannerWrapper = styled.div`
  width: 50%;

  @media only screen and (max-width: 991px) {
    display: none;
  }
  > div {
    position: fixed;
    width: 50%;
    top: 0;
    right: 0;
  }
`;

export const BannerImageWrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    width: 50%;
  }
`;

export default Wrapper;
