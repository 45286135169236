import React from "react";
import PropTypes from "prop-types";
import { IoIosStar, IoIosStarOutline } from "react-icons/io";

const Rating = (props) => {
  const { rating, ratingCount, type, ratingFieldName, hideRatingText } = props;
  let i, floorValue;
  let ratingView = [];
  if (rating && rating !== 0) {
    floorValue = Math.floor(rating);
    for (i = 0; i < 5; i++) {
      if (i < floorValue) {
        ratingView.push(<IoIosStar key={i} />);
      } else {
        ratingView.push(<IoIosStarOutline key={i} />);
      }
    }
  }
  let listingCondition;
  if (rating && rating === 5) {
    listingCondition = "Awesome";
  } else if (4 <= rating && rating < 5) {
    listingCondition = "Good";
  } else if (3 <= rating && rating < 4) {
    listingCondition = "Average";
  } else if (2 <= rating && rating < 3) {
    listingCondition = "Bad";
  } else if (rating >= 1) {
    listingCondition = "Terrible";
  } else {
    listingCondition = "";
  }

  if(hideRatingText){
    listingCondition = ""
  }
  
  let showRatingCount;
  if (ratingCount) {
    showRatingCount = `(${ratingCount} ${ratingCount > 1 ? "reviews" : "review"})`;
  } else {
    showRatingCount = "";
  }

  if (type === "bulk") {
    return (
      <>
        <span>{ratingView}</span>
        <strong>
          {` ${listingCondition}`} {`${showRatingCount}`}
        </strong>
      </>
    );
  }

  if(type === "stars-only") {
    return (
      <>
        {ratingView}
      </>
    );
  }

  return (
    <>
      <span>{ratingFieldName}</span> {ratingView}
    </>
  );
};

Rating.propTypes = {
  type: PropTypes.string.isRequired,
  ratingCount: PropTypes.number,
  rating: PropTypes.number,
  ratingFieldName: PropTypes.string,
  hideRatingText: PropTypes.bool
};

export default Rating;
