import React, { useState, useEffect, Fragment } from 'react';
import Sticky from 'react-stickynode';
import { Checkbox, Alert } from 'antd';

import { addQueryToUrl, getStateFromUrl } from "library/helpers/url_handler";
import useWindowSize from 'library/hooks/useWindowSize';
import useDataApi from 'library/hooks/useDataApi';
import useSearchLocation from 'library/hooks/useSearchLocation';
import { PostPlaceholder } from 'components/UI/ContentLoader/ContentLoader';
import Toolbar from 'components/UI/Toolbar/Toolbar';
import Text from 'components/UI/Text/Text';
import SectionGrid from 'components/SectionGrid/SectionGrid';
import BusinessCard from 'components/BusinessCard/BusinessCard';
import SearchFilter from 'components/Search/SearchFilter';
import SearchFilterDrawer from 'components/Search/SearchFilterDrawer';
import { BUSINESS_DETAILS_PAGE } from 'settings/constant';
import ListingMap from './ListingMap';
import SearchPageBodyWrapper, { ErrorWrapper, BusinessWrapper, ShowMapCheckbox } from './NearbyBusiness.style';

export default function NearbyBusiness({ location, history }) {
  const searchParams = getStateFromUrl(location);
  const url = `${process.env.REACT_APP_API_URL}/search/business`;

  const { width } = useWindowSize();
  const { currentLocation, formattedAddress, city, state } = useSearchLocation();
  const [showMap, setShowMap] = useState(false);

  const initialUrl = addQueryToUrl(url + location.search, { lat: currentLocation.lat, lng: currentLocation.lng, formattedAddress, city, state, increaseDistance: true });
  const { data, loading, error, showLoadMore, loadMoreData, limit, doFetch } = useDataApi(initialUrl, 60);

  useEffect(() => {
    const newUrl = addQueryToUrl(url + location.search, { lat: currentLocation.lat, lng: currentLocation.lng, formattedAddress, city, state, increaseDistance: true });
    doFetch(newUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const handleMapToggle = () => {
    setShowMap((showMap) => !showMap);
  };

  return (
    <SearchPageBodyWrapper>
      <Sticky top={82} innerZ={999} activeClass="isHeaderSticky">
        <Toolbar
          left={
            width > 991 ? (
              <SearchFilter history={history} location={location} />
            ) : (
              <SearchFilterDrawer history={history} location={location} />
            )
          }
          right={
            <ShowMapCheckbox>
              <Checkbox defaultChecked={false} onChange={handleMapToggle}>
                Show map
              </Checkbox>
            </ShowMapCheckbox>
          }
        />
        {searchParams.searchText && <Toolbar
          left={<Text mt={2} mb={2} content={`Showing results for ${searchParams.searchText} in ${city}`} />}
        />}
      </Sticky>

      <Fragment>
        {error &&
          <ErrorWrapper>
            <Alert showIcon type="error" message="Technical error Occurred" />
          </ErrorWrapper>
        }
        <BusinessWrapper className={width > 767 && showMap ? 'col-12' : 'col-24'}>
          <SectionGrid
            columnWidth={showMap ? [1 / 1, 1 / 2, 1 / 2, 1 / 2, 1 / 3] : [1 / 1, 1 / 2, 1 / 3, 1 / 4, 1 / 5]}
            data={data}
            showLoadMoreButton={showLoadMore}
            loading={loading}
            limit={limit}
            handleLoadMore={loadMoreData}
            placeholder={<PostPlaceholder />}
            showEmptyIcon={true}
            emptyProps={{ description: "Oh No! We could not find any business nearby. Please try reseting filters." }}
            renderComponent={(item) => <BusinessCard link={BUSINESS_DETAILS_PAGE} hideRatingText {...item} />}
          />
        </BusinessWrapper>

        {showMap && <ListingMap data={data} loading={loading} defaultCenter={currentLocation} />}
      </Fragment>
    </SearchPageBodyWrapper>
  );
}
