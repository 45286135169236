import React from "react";
import { Collapse, Radio, Space } from "antd";
import { DownOutlined } from "@ant-design/icons";

import Box from "components/UI/Box/Box";
import Text from "components/UI/Text/Text";

const { Panel } = Collapse;

const FilterOrderType = ({ label, value, onChange }) => {
  return (
    <Box bg="#f6f6f6" width="100%" mb={2}>
      <Collapse
        defaultActiveKey="1"
        accordion={true}
        expandIconPosition="end"
        expandIcon={() => <DownOutlined />}
      >
        <Panel header={label} key="1">
          <Radio.Group
            defaultValue={value}
            onChange={(e) => {
              onChange(e.target.value);
            }}
          >
            <Space direction="vertical">
              <Radio value={"store"}>Store</Radio>
              <Radio value={"nearby"}>Nearby</Radio>
            </Space>
          </Radio.Group>
        </Panel>
      </Collapse>
    </Box>
  );
};

export const FilterOrderTypePanel = ({ value, onChange }) => {
  return (
    <Radio.Group
      defaultValue={value}
      onChange={(e) => {
        onChange(e.target.value);
      }}
    >
      <Space direction="vertical">
        <Radio value={"store"}>
          {" "}
          <Text
            fontSize={["10px", "10px", "12px", "12px", "14px", "14px"]}
            content={"Online"}
          />
        </Radio>
        <Radio value={"nearby"}>
          {" "}
          <Text
            fontSize={["10px", "10px", "12px", "12px", "14px", "14px"]}
            content={"Nearby"}
          />
        </Radio>
      </Space>
    </Radio.Group>
  );
};

export default FilterOrderType;
