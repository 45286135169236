import React, { useState, useEffect } from 'react';
import { List, Modal } from 'antd';
import useSWR from 'swr';
import { CloseOutlined } from '@ant-design/icons';
import { get } from 'lodash';

import { fetcher } from 'library/hooks/useDataApi';
import { useBusinessOrderCart } from 'context/BusinessOrderCartProvider';
import Box from 'components/UI/Box/Box';
import Button from 'components/UI/Button/Button';
import InputIncDec from 'components/UI/InputIncDec/InputIncDec';
import Text from 'components/UI/Text/Text';
import Heading from 'components/UI/Heading/Heading';
import Subtotal from './Subtotal';
import { formatCurrency } from 'library/helpers/utils';

const CartList = ({ showHeader = true, showDeliveryCharge = false }) => {
  const { state, incrementItem, decrementItem, removeItem, syncItems } = useBusinessOrderCart();
  const [cartDetails, setCartDetails] = useState({ businessId: null, businessName: "", items: [] });
  const { data: cart, isValidating } = useSWR(`${process.env.REACT_APP_API_URL}/cart-details?resetKey=${state.resetKey}`, url => fetcher(url, "POST", {}, JSON.stringify(state)))

  useEffect(() => {
    if (cart && cart.items) {
      if (cart.items.length !== state.items.length) {
        syncItems(cart.businessId, cart.items.map(item => ({ productId: item.productId, variantId: item.variant.id })))
      }
      setCartDetails(cart)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart])

  return (
    <List
      loading={isValidating}
      bordered={true}
      locale={{ emptyText: "Cart Empty" }}
      rowKey={(item) => item.id}
      dataSource={state?.businessId ? get(cartDetails, "items", []) : []}
      header={showHeader ?
        <>
          <Heading as="h3" content={`CART`} />
          <Text color="gray" content={`${get(cartDetails, "businessName", "")}`} />
        </>
        : null
      }
      footer={get(cartDetails, "items", []).length
        ? (
          <Box flexBox flexDirection="column">
            <Subtotal
              items={get(cartDetails, "items", [])}
              deliveryCharge={showDeliveryCharge ? get(cartDetails, "pricing.deliveryCharge") : null}
            />
          </Box>
        ) : null
      }
      renderItem={(item) => {
        const isLowInStock = item.variant.availableQuantity > 0 && item.variant.availableQuantity < 5
        return (
          <List.Item>
            <Box key={`${item.productId}${item.variantId}`} flexBox flex={1} flexDirection="row" alignItems="center" justifyContent="space-between">
              <Box flexBox flex={1} flexDirection="row" alignItems="center" justifyContent="space-between">
                <Box>
                  <Text fontWeight="600" content={item.productName} />
                  <Text fontSize={[10, 12, 12]} color="gray" content={item.variant.name} />
                  <Box flexBox flexDirection="row" alignItems="flex-end" mt={1} mb={1}>
                    <Text fontSize={[14, 16, 16]} lineHeight={1} fontWeight="600" content={formatCurrency(item.pricing?.totalSellingPrice, 2, true)} mr={2} />
                    {item.pricing?.totalMaximumPrice !== item.pricing?.totalSellingPrice && <Text fontSize={[10, 12, 12]} lineHeight={1} content={formatCurrency(item.pricing?.totalMaximumPrice, 2, true)} mr={2} style={{ textDecoration: "line-through" }} />}
                  </Box>
                </Box>
                <Box flexBox flexDirection="column" alignItems="center">
                  <InputIncDec
                    value={item.quantity}
                    disablePlus={!item.inStock || item.quantity >= Math.min(item.variant.maxOrderQuantity, item.variant.availableQuantity)}
                    disableMinus={!item.inStock || item.quantity <= 0}
                    decrement={() => decrementItem(cartDetails.businessId, item.productId, item.variant.id)}
                    increment={() => incrementItem(cartDetails.businessId, item.productId, item.variant.id)}
                  />
                  {isLowInStock && <Text mt={1} fontSize={[10, 12, 12]} color="green" content={`Only ${item.variant.availableQuantity} in stock`} />}
                  {!item.inStock && <Text mt={1} fontWeight="600" fontSize={[10, 12, 12]} color="indianred" content="Out of Stock" />}
                </Box>
              </Box>
              <Box ml={2}>
                <Button
                  type="link"
                  size="small"
                  icon={<CloseOutlined />}
                  onClick={() => {
                    Modal.confirm({
                      title: `Remove ${item.productName} ${item.variant?.name ? `(${item.variant.name})` : ""}`,
                      content: <div>Are you sure! Do you want to remove the item from cart ?</div>,
                      onOk: () => { removeItem(cartDetails.businessId, item.productId, item.variant.id); }
                    });
                  }}
                />
              </Box>
            </Box>
          </List.Item>
        );
      }}
    />
  )
}

export default CartList;
