import React from 'react';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import InputIncDecWrapper from './InputIncDec.style';

const InputIncDec = ({ className, value, increment, decrement, disableMinus = false, disablePlus = false, ...restProps }) => {
  const addAllClasses = ['quantity'];
  if (className) {
    addAllClasses.push(className);
  }

  return (
    <InputIncDecWrapper className={addAllClasses.join(' ')}>
      <button className="btn decBtn" type="button" onClick={decrement} disabled={disableMinus}>
        <MinusOutlined />
      </button>
      <label className="qnt-input">{value}</label>
      {/* <Input className="qnt-input" type="number" {...restProps} disabled={disableMinus && disablePlus} /> */}
      <button className="btn incBtn" type="button" onClick={increment} disabled={disablePlus}>
        <PlusOutlined />
      </button>
    </InputIncDecWrapper>
  );
};

export default InputIncDec;
