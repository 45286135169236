import React, { useState } from "react";
import { generatePath } from "react-router-dom";
import { List, Modal } from "antd";
import styled from "styled-components";
import themeGet from "@styled-system/theme-get";

import { MANAGE_MY_SELLER_STORE_PAGE } from "settings/constant";
import useDataApi from "library/hooks/useDataApi";
import Box from "components/UI/Box/Box";
import TextLink from "components/UI/TextLink/TextLink";
import Text from "components/UI/Text/Text";

export const TextLinkWrapper = styled.div`
  a {
    color: black;
    &:hover {
      color: ${themeGet("text.2", "#777777")};
    }
  }
`;

const MyStoreSellerNavLink = ({
  text = "Seller Panel",
  hideIfOnlyOne = false,
  className = "",
}) => {
  const { data: sellers = [] } = useDataApi(
    `${process.env.REACT_APP_API_URL}/my-store/seller`,
    1000
  );
  const [showModal, setShowModal] = useState();

  const handleClick = () => {
    setShowModal(true);
  };

  if (hideIfOnlyOne && (!sellers || sellers.length === 0)) {
    return <></>;
  }

  return (
    <>
      {/* eslint-disable jsx-a11y/anchor-is-valid */}
      <a onClick={handleClick} className={className}>
        {text}
      </a>
      <Modal
        title={
          <Box>
            <Box
              flexBox
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              pr={4}
            >
              <Text content="Select A Store" />
            </Box>
          </Box>
        }
        destroyOnClose
        onCancel={() => {
          setShowModal(false);
        }}
        open={showModal}
        footer={null}
      >
        <List
          bordered={false}
          locale={{ emptyText: "It seems you have not listed any business" }}
          rowKey={(item) => item.id}
          dataSource={sellers || []}
          renderItem={(item) => {
            return (
              <List.Item>
                <Box
                  key={item.id}
                  flexBox
                  flex={1}
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <TextLinkWrapper>
                    <TextLink
                      to={`${generatePath(MANAGE_MY_SELLER_STORE_PAGE, {
                        sellerId: item.id,
                      })}`}
                      onClick={() => setShowModal(false)}
                      fontWeight="600"
                      style={{ textTransform: "uppercase" }}
                      content={item.name}
                    />
                  </TextLinkWrapper>
                </Box>
              </List.Item>
            );
          }}
        />
      </Modal>
    </>
  );
};

export default MyStoreSellerNavLink;
