import React from "react";
import Sticky from "react-stickynode";

import StoreNavMenu from "./StoreNavMenu";

const StoreNavMenuBar = () => {
  return (
    <Sticky top={82} innerZ={999} activeClass="isHeaderSticky" bottomBoundary=".ant-layout-content">
      <StoreNavMenu />
    </Sticky>
  );
};

export default StoreNavMenuBar;
