import React from "react";
import { Collapse, Radio, Space } from "antd";
import { DownOutlined } from "@ant-design/icons";

import Box from "components/UI/Box/Box";
import Text from "components/UI/Text/Text";

const { Panel } = Collapse;

const SortOptions = ({ label, value, onChange }) => {
  return (
    <Box bg="#f6f6f6" width="100%" mb={2}>
      <Collapse
        defaultActiveKey="1"
        accordion={true}
        expandIconPosition="end"
        expandIcon={() => <DownOutlined />}
      >
        <Panel
          header={label}
          key="1"
        >
          <SortOptionsPanel value={value} onChange={onChange} />
        </Panel>
      </Collapse>
    </Box>
  );
};

export const SortOptionsPanel = ({ value, onChange }) => {
  return (
    <Radio.Group
      defaultValue={value}
      onChange={(e) => {
        onChange(e.target.value);
      }}
    >
      <Space direction="vertical">
        {[
          { key: "popularity", value: "Popularity" },
          { key: "discount", value: "Discount %" },
          { key: "new-arrival", value: "New Arrival" },
          { key: "price-high-to-low", value: "Price: High To Low" },
          { key: "price-low-to-high", value: "Price: Low To High" },
        ].map((item) => (
          <Radio key={item.key} value={item.key}>
            <Text
              fontSize={["10px", "10px", "12px", "12px", "14px", "14px"]}
              content={item.value}
            />
          </Radio>
        ))}
      </Space>
    </Radio.Group>
  );
};

export default SortOptions;
