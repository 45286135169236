import React from "react";
import { Row, Col } from "antd";
import { get } from "lodash";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { useBaseData } from "context/BaseDataProvider";
import Image from "components/UI/Image/Image";
import LinkTo from "components/UI/LinkTo/LinkTo";
import { getImageDimensions, responsive } from "../utils";

const Banner = ({ section }) => {
    const { getAssetUrl } = useBaseData();

      const gutter = responsive(get(section, "configuration.gutter"));
      const column = responsive(get(section, "configuration.column"));
      return (
        <Row
          gutter={{
            xs: gutter[0],
            sm: gutter[1],
            md: gutter[2],
            lg: gutter[3],
            xl: gutter[4],
            xxl: gutter[5],
          }}
        >
          {section.components.map((component, index) => (
            <Col
              key={index}
              xs={column[0]}
              sm={column[1]}
              md={column[2]}
              lg={column[3]}
              xl={column[4]}
              xxl={column[5]}
            >
              <LinkTo to={component.linkTo}>
                <Image
                  as={LazyLoadImage}
                  alt=""
                  width="100%"
                  style={{
                    aspectRatio: getImageDimensions(component.photo[0]?.meta)
                      .aspectRatio,
                  }}
                  src={getAssetUrl(component.photo[0]?.path)}
                  p={responsive(get(section, "configuration.imagePadding"), 0)}
                  borderRadius={responsive(
                    get(section, "configuration.imageRadius"),
                    0
                  )}
                />
              </LinkTo>
            </Col>
          ))}
        </Row>
      );
  };

  export default Banner