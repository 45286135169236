import React, { useMemo, useContext, useEffect, useState } from "react";
import shortid from "shortid";
import { message } from "antd";
import useDataApi, { fetcher } from "library/hooks/useDataApi";
import { useAuth } from "./AuthProvider";

export const StoreCartContext = React.createContext();

const StoreCartProvider = (props) => {
  const url = `${process.env.REACT_APP_API_URL}/store/cart`;
  const {
    loading,
    data,
    updateData: updateCart,
    doFetch,
  } = useDataApi(url, 1000, true);

  const cart = useMemo(
    () =>
      data.filter((item) => {
        return !!item.meta?.variants?.find(
          (variant) => variant.id === item.variantId
        );
      }),
    [data]
  );

  const { loggedIn } = useAuth();
  const [showCart, setShowCart] = useState(false);

  useEffect(() => {
    if (loggedIn) {
      doFetch(url, shortid.generate());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedIn]);

  const refreshCart = () => {
    doFetch(url, shortid.generate());
  };

  const findProductInCart = (productId, variantId) => {
    return cart.find(
      (product) =>
        product.productId === productId && product.variantId === variantId
    );
  };

  const isProductInCart = (productId, variantId) => {
    const isAlreadyInCart = !!cart.find(
      (product) =>
        product.productId === productId && product.variantId === variantId
    );
    return isAlreadyInCart;
  };

  const addOrRemoveInCart = async (
    productId,
    variantId,
    quantity,
    meta = {}
  ) => {
    try {
      const payload = {
        productId,
        variantId,
        quantity,
      };
      const result = await fetcher(url, "POST", {}, JSON.stringify(payload));
      if (!result.errors) {
        const newCart = result.cart.products.map((item) => {
          const productInCart = findProductInCart(
            item.productId,
            item.variantId
          );
          if (productInCart) {
            return { ...productInCart, ...item };
          }
          return { ...item, meta };
        });
        updateCart(newCart);
      } else {
        refreshCart();
        message.error({
          content: result.errors[0].userMessage,
        });
      }
    } catch (e) {
      // Handle errors here ex: Timeout or server
    }
  };

  const getTotalPrice = (priceType = "sellingPrice") => {
    return cart.reduce((sum, current) => {
      const variant = current.meta.variants.find(
        (item) => item.id === current.variantId
      );
      const variantPrice = variant ? variant[priceType] : 0;
      return sum + current.quantity * variantPrice;
    }, 0);
  };

  const isAnyProductOutOfStock = () => {
    return !!cart.find((current) => {
      const variant = current.meta.variants.find(
        (item) => item.id === current.variantId
      );
      const variantStockQuantity = variant ? variant.stockQuantity : 0;
      return current.quantity > variantStockQuantity;
    });
  };

  return (
    <StoreCartContext.Provider
      value={{
        loading,
        cart,
        updateCart,
        refreshCart,
        showCart,
        setShowCart,
        addOrRemoveInCart,
        isProductInCart,
        isAnyProductOutOfStock,
        getTotalPrice,
      }}
    >
      <>{props.children}</>
    </StoreCartContext.Provider>
  );
};

export const useStoreCart = () => useContext(StoreCartContext);

export default StoreCartProvider;
