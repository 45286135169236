import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Alert, Checkbox, Button, message } from "antd";
import { useForm, Controller, get } from "react-hook-form";
import { Helmet } from "react-helmet";
import useSWR from "swr";
import Loader from "react-spinners/PuffLoader";

import { useBaseData } from "context/BaseDataProvider";
import { AuthContext } from "context/AuthProvider";
import { HOME_PAGE } from "settings/constant";
import { fetcher } from 'library/hooks/useDataApi';

import Container from "components/UI/Container/Container";
import FormControl from "components/UI/FormControl/FormControl";
import FormError from 'components/UI/FormError/FormError';
import Box from "components/UI/Box/Box";
import SiteDataRichText from "components/SiteDataRichText/SiteDataRichText";
import { Form } from "./JoinAsMerchant.style";

export default function JoinAsMerchant() {
  const history = useHistory();
  const { preferences: { orgName } } = useBaseData() 
  const { data: siteData, isValidating } = useSWR(`${process.env.REACT_APP_API_URL}/site-data/merchant-terms`)
  const { setUser, hasAnyRole } = useContext(AuthContext);
  const [serverErrors, setServerErrors] = useState([]);
  const { control, register, errors, handleSubmit } = useForm({
    mode: "onSubmit",
  });

  const onSubmit = async (data) => {
    const payload = {}
    try {
      const result = await fetcher(`${process.env.REACT_APP_API_URL}/merchant`, "POST", {}, JSON.stringify(payload))
      if (result.errors) {
        setServerErrors(result.errors);
      }
      else {
        setUser(result.user);
        history.push(HOME_PAGE)
      }
    }
    catch (e) {
      message.error(`Technical error occured.`, 3);
      // Handle errors here ex: Timeout or server
    }
  }

  useEffect(() => {
    register(
      { name: "termsAndConditions" },
      {
        validate: (termsAndConditions) => {
          return !!termsAndConditions;
        },
      }
    );
  }, [register]);

  if (!siteData && isValidating) {
    return <Loader />
  }
  return (
    <>
      <Helmet>
        <title>{orgName} | Join As Merchant</title>
      </Helmet>
      {hasAnyRole(['MERCHANT']) ? <Container>
        <Alert
          showIcon
          type="error"
          message="You are already a merchant!"
          style={{ marginTop: 20 }}
        />
      </Container> :
        <Container>
          <Box mt={3}>
            <SiteDataRichText html={get(siteData, 'data', "")} />
          </Box>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <FormControl
              error={
                errors.termsAndConditions && (
                  <span>Please accept the terms & conditions!</span>
                )
              }
            >
              <Controller
                name="termsAndConditions"
                onChange={([e]) => {
                  return e.target.checked;
                }}
                as={
                  <Checkbox>
                    I certify that I have read all the terms mentioned above. I
                    also understand that {orgName} has a zero-tolerance policy on
                    fake merchants. If any information is found to be incorrect
                    then {orgName} may deactivate my account at any moment.
                </Checkbox>
                }
                control={control}
              />
            </FormControl>
            <FormError errors={serverErrors} className="inner-error-wrapper" />
            <FormControl className="submit-container">
              <Button htmlType="submit" type="primary" size="large">
                Join as Merchant
            </Button>
            </FormControl>
          </Form>
        </Container>}
    </>
  );
}
