import React, { useState } from "react";
import { Row, Col, Tabs, Drawer, Badge, Divider } from "antd";

import { setStateToUrl, getStateFromUrl } from "library/helpers/url_handler";
import Text from "components/UI/Text/Text";
import Button from "components/UI/Button/Button";
import Box from "components/UI/Box/Box";
import SortOptions, { SortOptionsPanel } from "./SortOptions";
import FilterSubCategory, { FilterSubCategoryPanel } from "./FilterSubCategory";
import FilterProductType, { FilterProductTypePanel } from "./FilterProductType";
import FilterPrice, { FilterPricePanel } from "./FilterPrice";
import FilterBrand, { FilterBrandPanel } from "./FilterBrand";
import FilterDiscount, { FilterDiscountPanel } from "./FilterDiscount";
import { FaFilter, FaSort } from "react-icons/fa";

const FilterLabel = ({ text = "", count = 0 }) => {
  return (
    <Box
      flexBox
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Text
        fontSize={["10px", "10px", "12px", "12px", "14px", "14px"]}
        content={text}
      />
      <Badge size="small" count={count} />
    </Box>
  );
};

const SortAndFilter = ({ history, location }) => {
  const searchParams = getStateFromUrl(location);

  const onChange = (value, type) => {
    const query = {
      ...searchParams,
      [type]: value,
    };
    const search = setStateToUrl(query);
    history.push({
      pathname: location.pathname,
      search: search,
    });
  };

  return (
    <Row>
      <Col xs={24}>
        <SortOptions
          label={<FilterLabel text="Sort By" />}
          value={searchParams.sortBy}
          onChange={(value) => onChange(value, "sortBy")}
        />
        <FilterSubCategory
          label={
            <FilterLabel
              text="Category"
              count={searchParams.subCategory?.split(",").length}
            />
          }
          value={searchParams.subCategory?.split(",")}
          onChange={(value) => onChange(value?.join(","), "subCategory")}
        />
        <FilterProductType
          label={<FilterLabel text="Product" />}
          value={searchParams.productType?.split(",")}
          onChange={(value) => onChange(value?.join(","), "productType")}
        />
        <FilterBrand
          label={<FilterLabel text="Brand" />}
          value={searchParams.brand?.split(",")}
          onChange={(value) => onChange(value?.join(","), "brand")}
        />
        <FilterPrice
          label={<FilterLabel text="Price Range" />}
          value={searchParams.price?.split(",")}
          onChange={(value) => onChange(value?.join(","), "price")}
        />
        <FilterDiscount
          label={<FilterLabel text="Discount" />}
          value={searchParams.discount?.split(",")}
          onChange={(value) => onChange(value?.join(","), "discount")}
        />
      </Col>
    </Row>
  );
};

export const MobileSortAndFilter = ({ history, location }) => {
  const [showFilter, setShowFilter] = useState(false);
  const [showSort, setShowSort] = useState(false);
  const [query, setQuery] = useState(getStateFromUrl(location));

  const onChange = (value, type) => {
    const newQuery = {
      [type]: value,
    };
    setQuery(newQuery);
  };

  const onCancel = () => {
    setQuery(getStateFromUrl(location));
    setShowFilter(false);
    setShowSort(false);
  };

  const onApply = () => {
    const search = setStateToUrl({ ...getStateFromUrl(location), ...query });
    setShowFilter(false);
    setShowSort(false);
    history.push({
      pathname: location.pathname,
      search: search,
    });
  };

  return (
    <>
      <Box
        flexBox
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
       
      >
        <Button fullWidth type="link" onClick={() => setShowSort(true)}>
          <Box
            flexBox
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
          >
            <FaSort style={{ height: 18 }} />
            <Text  fontSize={[14]} ml={2} content="Sort" />
          </Box>
        </Button>
        <Divider type="vertical" style={{ color: "gray" }} />
        <Button fullWidth type="link" onClick={() => setShowFilter(true)}>
          <Box
            flexBox
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
          >
            <FaFilter style={{ height: 18 }} />
            <Text  fontSize={[14]} ml={2} content="Filter" />
          </Box>
        </Button>
      </Box>
      <Drawer
        title={<Text fontSize={[14]} content="Sort by" />}
        placement="bottom"
        height={265}
        headerStyle={{ height: 65 }}
        contentWrapperStyle={{ borderTopLeftRadius: 16 , borderTopRightRadius: 16, overflow: 'hidden' }}
        onClose={onCancel}
        open={showSort}
        closable={false}
        extra={
          <Button type="dashed" onClick={onApply}>
            Apply
          </Button>
        }
        bodyStyle={{ padding: 16 }}
      >
        <Box>
          <SortOptionsPanel
            label={<FilterLabel text="Sort By" />}
            value={query.sortBy}
            onChange={(value) => onChange(value, "sortBy")}
          />
        </Box>
      </Drawer>
      <Drawer
        title={<Text fontSize={[14]} content="Select Filters" />}
        placement="bottom"
        height={365}
        headerStyle={{ height: 65 }}
        closable={false}
        onClose={onCancel}
        open={showFilter}
        contentWrapperStyle={{ borderTopLeftRadius: 16 , borderTopRightRadius: 16, overflow: 'hidden' }}
        extra={
          <Button type="dashed" onClick={onApply}>
            Apply
          </Button>
        }
        bodyStyle={{ padding: 0 }}
      >
        <Tabs
          defaultActiveKey="1"
          tabPosition="left"
          type="card"
          style={{ height: 300 }}
          items={[
            {
              label: <FilterLabel text="Category" />,
              key: "subCategory",
              style: { padding: 12, height: "100%", overflowY: "scroll" },
              children: (
                <Box>
                  <FilterSubCategoryPanel
                    value={query.subCategory?.split(",")}
                    onChange={(value) =>
                      onChange(value?.join(","), "subCategory")
                    }
                  />
                </Box>
              ),
            },
            {
              label: <FilterLabel text="Product" />,
              key: "productType",
              style: { padding: 12, height: "100%", overflowY: "scroll" },
              children: (
                <Box>
                  <FilterProductTypePanel
                    value={query.productType?.split(",")}
                    onChange={(value) =>
                      onChange(value?.join(","), "productType")
                    }
                  />
                </Box>
              ),
            },
            {
              label: <FilterLabel text="Brand" />,
              key: "brand",
              style: { padding: 12, height: "100%", overflowY: "scroll" },
              children: (
                <Box>
                  <FilterBrandPanel
                    value={query.brand?.split(",")}
                    onChange={(value) => onChange(value?.join(","), "brand")}
                  />
                </Box>
              ),
            },
            {
              label: <FilterLabel text="Price Range" />,
              key: "price",
              style: { padding: 12, height: "100%", overflowY: "scroll" },
              children: (
                <Box>
                  <FilterPricePanel
                    value={query.price?.split(",")}
                    onChange={(value) => onChange(value?.join(","), "price")}
                  />
                </Box>
              ),
            },
            {
              label: <FilterLabel text="Discount Range" />,
              key: "discount",
              style: { padding: 12, height: "100%", overflowY: "scroll" },
              children: (
                <Box>
                  <FilterDiscountPanel
                    value={query.discount?.split(",")}
                    onChange={(value) => onChange(value?.join(","), "discount")}
                  />
                </Box>
              ),
            },
          ]}
        />
      </Drawer>
    </>
  );
};

export default SortAndFilter;
