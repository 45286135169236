const defaultTheme = {
  breakpoints: ['576px', '768px', '992px', '1200px', '1600px'],
  primary: [
    '#ff6105',
  ],
  secondary : [
    '#f7350d'
  ],
  selection : [
    '#7b7b7b'
  ],
  color: [
    '#000000', // 0: Black
    '#ffffff', // 1: White
    '#F7F7F7', // 2: Light Grey
  ],
  layout: [
    '#001529', // 0: SideBar 
    '#282c3f', // 1: SideBar Header 
  ],
  warning: [
    '#F29100', // 0: Warning
  ],
  success: [
    '#00BB5D', // 0: Success
  ],
  preview: [
    '#228B22' // 0: Header green
  ],
  error: [
    '#F9503D', // 0: Error
    'rgba(249, 80, 61, 0.08)', // 0: Error Light Bg
  ],
  text: [
    '#2C2C2C', // 0: Heading
    '#909090', // 1: Meta Text
    '#777777', // 2: Text Info
  ],
  border: [
    '#EBEBEB', // 0: Light border
    '#E8E8E8', // 1: Default Border
    'rgba(57, 151, 247, 0.35)', // 2: Active Light Border
    '#E6E6E6', // 3: Pricing Border
  ],
  boxShadow: [
    'rgba(26, 68, 116, 0.16)', // 0: Card hover
    'rgba(0, 0, 0, 0.16)', // 1: Carousel Button shadow
  ],
  fonts: {
    primary: 'Lato, sans-serif',
  },
};

export default defaultTheme;
