import React from "react";
import PropTypes from "prop-types";
import Wrapper from "./FormError.style";
import Heading from "../Heading/Heading";

const FormError = ({ className, errors = [] }) => {
  const addAllClasses = ["form-error"];
  if (className) {
    addAllClasses.push(className);
  }

  if (!errors.length) {
    return null;
  }

  return (
    <Wrapper className={addAllClasses.join(" ")}>
      <Heading as="h3" className="heading" content="Errors" />
      <ul className="feedback-container">
        {errors.map((error) => (
          <li key={error.code} className="feedback">
            {error.userMessage}
          </li>
        ))}
      </ul>
    </Wrapper>
  );
};

FormError.propTypes = {
  error: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      type: PropTypes.string,
      userMessage: PropTypes.string,
    })
  ),
  allowMinimize: PropTypes.bool,
};

export default FormError;
