// localStorage.js
export const loadState = key => {
  try {
    const serializedValue = localStorage.getItem(key);
    if (serializedValue === null) {
      return undefined;
    }
    return JSON.parse(serializedValue);
  } catch (err) {
    return undefined;
  }
};

const getCurrentStorageState = () => {
  let state = {}
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    const serializedValue = localStorage.getItem(key);

    if (serializedValue !== null) {
      state = { ...state, [key]: JSON.parse(serializedValue) }
    }
  }
  return state
}

export const saveState = (key, value) => {
  try {
    const serializedValue = JSON.stringify(value);
    localStorage.setItem(key, serializedValue);
    typeof window !== "undefined" && window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify({ type: "setStorage", value: getCurrentStorageState() }))
  } catch {
    // ignore write errors
  }
};

export const removeState = (key) => {
  try {
    localStorage.removeItem(key);
    typeof window !== "undefined" && window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify({ type: "setStorage", value: getCurrentStorageState() }))
  } catch {
    // ignore delete errors
  }
};
