import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { Button, Result, notification } from "antd";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import theme from "themes/default.theme";
import GlobalStyles from "assets/style/Global.style";
import AuthProvider from "context/AuthProvider";
import StoreCartProvider from "context/StoreCartProvider";
import BusinessOrderCartProvider from "context/BusinessOrderCartProvider";
import BaseDataProvider, { useBaseData } from "context/BaseDataProvider";
import ScrollToTop from "components/ScrollToTop/ScrollToTop";
import Routes from "./router";

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    debug: true,
    tracesSampleRate: 0.1,
  });
}

const ThemedApp = () => {
  const {
    preferences: { primaryColor, secondaryColor },
  } = useBaseData();

  useEffect(() => {
    notification.config({
      placement: "topRight",
      top: 90,
      duration: 3,
    });
  });

  return (
    <Sentry.ErrorBoundary
      fallback={
        <Result
          status="500"
          title="Oh!"
          subTitle="Sorry, something went wrong."
          extra={
            <Button
              type="primary"
              style={{ background: "grey" }}
              onClick={() => window.location.replace("/")}
            >
              Home
            </Button>
          }
        />
      }
    >
      <ThemeProvider
        theme={{
          ...theme,
          primary: [primaryColor || "#9e9e9e"],
          secondary: [secondaryColor || "#777777"],
        }}
      >
        <>
          <GlobalStyles />
          <BrowserRouter>
            <ScrollToTop />
            <AuthProvider>
              <StoreCartProvider>
                <BusinessOrderCartProvider>
                  <Routes />
                </BusinessOrderCartProvider>
              </StoreCartProvider>
            </AuthProvider>
          </BrowserRouter>
        </>
      </ThemeProvider>
    </Sentry.ErrorBoundary>
  );
};

const App = () => {
  return (
    <BaseDataProvider>
      <ThemedApp />
    </BaseDataProvider>
  );
};

ReactDOM.render(<App />, document.getElementById("root"));
