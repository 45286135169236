import React, { useState, useRef, useEffect } from "react";
import { get } from "lodash";
import { Marker } from "react-google-maps";
import { Input, Button } from "antd";
import MapWrapper from "./MapWrapper";
import { AimOutlined } from "@ant-design/icons";
const { SearchBox } = require("react-google-maps/lib/components/places/SearchBox");

// Default Coordinates are of New Delhi
const MapWithSearchBox = ({ defaultValue = [28.6139391, 77.2090212], updateValue, name, defaultZoom = 13, markerProps = {}, containerStyles = {} }) => {
  const [locationInput, setLocationInput] = useState({ searchedLocation: "" });
  const [locationDetails, setLocationDetails] = useState({
    center: { lat: defaultValue[0], lng: defaultValue[1] },
    markers: [{ position: { lat: defaultValue[0], lng: defaultValue[1] } }],
    places: [],
  });
  const SearchBoxRef = useRef(null);

  // Commenting as there is no need of it
  useEffect(() => {
    geocode({
      lat: defaultValue[0],
      lng: defaultValue[1]
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onPlacesChanged = () => {
    if (!window.google) {
      return
    }
    const places = SearchBoxRef.current.getPlaces();
    const bounds = new window.google.maps.LatLngBounds();
    places.forEach((place) => {
      if (place.geometry.viewport) {
        bounds.union(place.geometry.viewport);
      } else {
        bounds.extend(place.geometry.location);
      }
    });
    const nextMarkers = places.map((place) => ({
      position: place.geometry.location,
    }));
    const nextCenter = get(nextMarkers, "0.position", locationDetails.center);

    setLocationDetails({
      ...locationDetails,
      places: [places[0]],  // Setting the 1st place in case of search
      center: nextCenter,
      markers: [nextMarkers[0]],
    });
    setLocationInput({ searchedLocation: get(places, "0.formatted_address", "") });
    updateValue(places);
  };

  const handleOnChange = (event) => {
    event.stopPropagation();
    if (event.which === "13") {
      event.preventDefault();
    }
    setLocationInput({ searchedLocation: event.target.value });
  };

  const handleOnPressEnter = (event) => {
    event.stopPropagation();
    if (event.which === "13") {
      event.preventDefault();
    }
    setLocationInput({ searchedLocation: event.target.value });
  };

  const geocode = (latlng) => {
    if (!window.google) {
      return
    }
    let tempLocArray = [];
    var geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ latLng: latlng }, function (results, status) {
      if (results && results[0] && results[0].formatted_address) {
        setLocationInput({
          searchedLocation: results[0] && results[0].formatted_address,
        });
        const location = {
          place_id: results[0].place_id,
          formatted_address: results[0].formatted_address,
          address_components: results[0].address_components,
          geometry: results[0].geometry,
        };
        tempLocArray.push(location);
      }
      updateValue(tempLocArray);
    });
  };

  const onDragEndFunc = (marker) => {
    const latlng = {
      lat: Number(marker.latLng.lat().toFixed(8)),
      lng: Number(marker.latLng.lng().toFixed(8)),
    };
    setLocationDetails({
      ...locationDetails,
      center: latlng,
    });
    geocode(latlng);
  };

  const onDetectCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const latlng = {
            lat: Number(latitude.toFixed(8)),
            lng: Number(longitude.toFixed(8)),
          };

          setLocationDetails({
            ...locationDetails,
            center: latlng,
            markers: [{ position: latlng }],
          });

          geocode(latlng);
        },
        () => {
          // Show error message
        },
        { enableHighAccuracy: true }
      );
    }
  };


  const onBoundsChanged = () => {
    setLocationDetails({ ...locationDetails })
  }

  return (
    <MapWrapper
      containerElement={<div style={{ height: "400px", ...containerStyles }} />}
      mapElement={<div style={{ height: "100%" }} />}
      defaultZoom={defaultZoom}
      center={locationDetails.center}
      onBoundsChanged={onBoundsChanged}
    >
      <SearchBox
        ref={SearchBoxRef}
        controlPosition={window.google?.maps.ControlPosition.TOP_LEFT}
        onPlacesChanged={onPlacesChanged}
      >
        <Input
          type="text"
          name={name}
          placeholder="Enter a location to search or click to locate"
          style={{
            marginLeft: '10px',
            marginTop: '10px',
            width: '40%',
            minWidth: '340px',
            boxSizing: 'border-box',
            border: '1px solid transparent',
            borderRadius: '2px',
            boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16)',
            fontSize: '15px',
            outline: 'none',
            textOverflow: 'ellipsis',
            overflow: "hidden",
            whiteSpace: "nowrap"

          }}
          defaultValue=""
          value={locationInput ? locationInput.searchedLocation : ""}
          onChange={handleOnChange}
          onPressEnter={handleOnPressEnter}
          suffix={
            <Button
              type="link"
              onClick={onDetectCurrentLocation}
              icon={
                <AimOutlined style={{ color: "grey" }} />
              }
            />
          }
        />
      </SearchBox>
      {locationDetails.markers.map((marker, index) => {
        return (
          <Marker
            // icon={markerImage}
            key={index}
            position={marker.position}
            onDragEnd={(marker) => onDragEndFunc(marker)}
            {...markerProps}
          />
        );
      })}
    </MapWrapper>
  );
};

export default MapWithSearchBox;
