import React, { useEffect } from "react";
import { generatePath } from "react-router-dom";
import { Row, Col, List, Empty } from "antd";

import useDataApi from "library/hooks/useDataApi";
import { useBaseData } from "context/BaseDataProvider";
import Box from "components/UI/Box/Box";
import Text from "components/UI/Text/Text";
import Image from "components/UI/Image/Image";
import Button from "components/UI/Button/Button";
import Loader from "components/Loader/Loader";
import { HOME_PAGE, MY_STORE_ORDER_DETAILS_PAGE } from "settings/constant";
import { OrderListWrapper } from "./MyOrders.style";

const StoreOrders = ({ history, location }) => {
  const url = `${process.env.REACT_APP_API_URL}/store/my-orders`;
  const { getAssetUrl } = useBaseData();
  const {
    data: orders = [],
    loading,
    showLoadMore,
    loadMoreData,
    doFetch,
  } = useDataApi(url + location.search, 20);

  useEffect(() => {
    doFetch(url + location.search || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  if (loading && orders.length === 0) {
    return <Loader />;
  }

  if (orders.length === 0) {
    return (
      <>
        <Row gutter={30}>
          <Col xs={24} lg={16} push={4}>
            <Box height="50vh" marginTop="10vh">
              <Empty description="You have not placed any order !">
                <Button onClick={() => history.push(HOME_PAGE)}>
                  Shop Now
                </Button>
              </Empty>
            </Box>
          </Col>
        </Row>
      </>
    );
  }

  return (
    <>
      <OrderListWrapper>
        <List
          bordered
          dataSource={orders}
          locale={{ emptyText: "No Orders yet" }}
          renderItem={(item) => {
            return (
              <List.Item>
                <Row>
                  <Col xs={24} sm={24}>
                    <Box flexBox flex={1} alignItems="center">
                      <Image
                        src={getAssetUrl(item.meta.photo.path)}
                        alt={item.productName}
                        width="65px"
                      />
                      <Box
                        flexBox
                        flex={1}
                        flexDirection="column"
                        justifyContent="center"
                        mx={3}
                      >
                        <Text
                          fontWeight="600"
                          content={`${item.meta.brand.name} ${item.meta.productName}`}
                        />
                        {item.meta.variant.size !== "Default" && (
                          <Text
                            fontSize={12}
                            color="gray"
                            content={`Size : ${item.meta.variant.size}`}
                          />
                        )}
                        <Text
                          fontSize={12}
                          color="gray"
                          content={`Quantity : ${item.quantity}`}
                        />
                      </Box>
                      <Button
                        type="dashed"
                        onClick={() =>
                          history.push(
                            `${generatePath(MY_STORE_ORDER_DETAILS_PAGE, {
                              orderId: item.orderId,
                              subOrderId: item.subOrderId,
                            })}`
                          )
                        }
                      >
                        View
                      </Button>
                    </Box>
                  </Col>
                </Row>
              </List.Item>
            );
          }}
        />
      </OrderListWrapper>
      {showLoadMore && (
        <Box mt={4} flexBox justifyContent="center">
          <Button onClick={loadMoreData}>Load more orders</Button>
        </Box>
      )}
    </>
  );
};

export default StoreOrders;
