import React, { useState, useEffect, useContext } from "react";
import { Redirect, useParams, useHistory } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { MdLockOpen } from "react-icons/md";
import { FcApproval } from "react-icons/fc";
import { Row, Col, Alert, Input, Switch, message, InputNumber } from "antd";

import FormControl from "components/UI/FormControl/FormControl";
import FormError from "components/UI/FormError/FormError";
import Button from "components/UI/Button/Button";
import Text from "components/UI/Text/Text";
import { SIGN_UP_CONFIRM_PAGE } from "settings/constant";
import { AuthContext } from "context/AuthProvider";
import { fetcher } from "library/hooks/useDataApi";
import { addQueryToUrl } from "library/helpers/url_handler";
import { FieldWrapper, SwitchWrapper, Label } from "../Auth.style";

export default () => {
  const history = useHistory();
  const { inviteCode } = useParams();
  const [verifiedCode, setVerifiedCode] = useState("");
  const [verifiedCodePerson, setVerifiedCodePerson] = useState("");
  const [referCodeError, setReferCodeError] = useState("");
  const [formErrors, setFormErrors] = useState([]);
  const { loggedIn } = useContext(AuthContext);
  const { control, formState, watch, register, errors, handleSubmit } =
    useForm();

  const referralCode = watch("referralCode", inviteCode);

  const onVerifyReferCode = async () => {
    setReferCodeError([]);
    try {
      const result = await fetcher(
        `${process.env.REACT_APP_API_URL}/verify/refer-code-user`,
        "POST",
        {},
        JSON.stringify({ referCode: referralCode })
      );
      if (result.errors) {
        setVerifiedCodePerson("");
        setReferCodeError(result.errors[0].userMessage);
      } else {
        setVerifiedCode(referralCode);
        setVerifiedCodePerson(result.referrer?.name || "");
      }
    } catch (e) {
      message.error(`Technical error occured.`, 3);
      // Handle errors here ex: Timeout or server
    }
  };

  const initiateSignUp = async (data) => {
    setFormErrors([]);
    try {
      const result = await fetcher(
        `${process.env.REACT_APP_API_URL}/sign-up`,
        "POST",
        {},
        JSON.stringify(data)
      );
      if (result.errors) {
        setFormErrors(result.errors);
      } else {
        history.push(
          addQueryToUrl(SIGN_UP_CONFIRM_PAGE, {
            otpId: result.otpId,
            firstName: data.firstName,
            mobile: data.mobile,
          })
        );
      }
    } catch (e) {
      // Handle errors here ex: Timeout or server
    }
  };

  const onSubmit = async (data) => {
    const payload = {
      ...data,
      referralCode: verifiedCode ? data.referralCode : "",
    };
    initiateSignUp(payload);
  };

  useEffect(() => {
    register(
      { name: "termsAndConditions" },
      {
        required: true,
        validate: (termsAndConditions) => {
          return !!termsAndConditions;
        },
      }
    );
    if (inviteCode) {
      onVerifyReferCode();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loggedIn) {
    return <Redirect to={{ pathname: "/" }} />;
  }

  return (
    <form className="signup_form" onSubmit={handleSubmit(onSubmit)}>
      <Row gutter={16}>
        <Col xs={24} sm={12}>
          <FormControl
            mandatory
            label="First Name"
            htmlFor="firstName"
            error={
              errors.firstName && (
                <>
                  {errors.firstName?.type === "required" && (
                    <span>This field is required!</span>
                  )}
                </>
              )
            }
          >
            <Controller
              as={<Input />}
              id="firstName"
              name="firstName"
              defaultValue=""
              control={control}
              placeholder="Your first name"
              rules={{
                required: true,
              }}
            />
          </FormControl>
        </Col>

        <Col xs={24} sm={12}>
          <FormControl
            label="Last Name"
            htmlFor="lastName"
            error={
              errors.lastName && (
                <>
                  {errors.lastName?.type === "required" && (
                    <span>This field is required!</span>
                  )}
                </>
              )
            }
          >
            <Controller
              as={<Input />}
              id="lastName"
              name="lastName"
              defaultValue=""
              control={control}
              placeholder="Your last name"
              rules={{
                required: false,
              }}
            />
          </FormControl>
        </Col>

        <Col xs={24} sm={12}>
          <FormControl
            mandatory
            label="Mobile"
            htmlFor="mobile"
            error={
              errors.mobile && (
                <>
                  {errors.mobile?.type === "required" && (
                    <span>This field is required!</span>
                  )}
                  {errors.mobile?.type === "pattern" && (
                    <span>Please enter your valid 10 digit mobile number!</span>
                  )}
                </>
              )
            }
          >
            <Controller
              as={
                <InputNumber
                  addonBefore="+91"
                  stringMode={true}
                  controls={false}
                  precision={0}
                />
              }
              id="mobile"
              name="mobile"
              defaultValue=""
              control={control}
              placeholder="Your mobile number"
              rules={{
                required: true,
                pattern: /^[0-9]{10}$/,
              }}
            />
          </FormControl>
        </Col>

        <Col xs={24} sm={12}>
          <FormControl
            mandatory
            label="Password"
            htmlFor="password"
            error={
              errors.password && (
                <>
                  {errors.password?.type === "required" && (
                    <span>This field is required!</span>
                  )}
                  {errors.password?.type === "minLength" && (
                    <span>Password must be atleast 6 characters!</span>
                  )}
                  {errors.password?.type === "maxLength" && (
                    <span>Password must not be longer than 20 characters!</span>
                  )}
                </>
              )
            }
          >
            <Controller
              as={<Input.Password autocomplete="new-password" />}
              defaultValue=""
              control={control}
              id="password"
              name="password"
              placeholder="Strong password of minimum 6 letters"
              rules={{ required: true, minLength: 6, maxLength: 20 }}
            />
          </FormControl>
        </Col>
        <Col xs={24}>
          <FormControl
            mandatory
            label="Invite Code"
            htmlFor="referralCode"
            error={
              errors.referralCode ? (
                <span>Please enter the invite code</span>
              ) : referCodeError ? (
                <span>{referCodeError}</span>
              ) : null
            }
          >
            <Controller
              as={
                <Input
                  suffix={
                    verifiedCode && referralCode === verifiedCode ? (
                      <FcApproval size={24} />
                    ) : (
                      ""
                    )
                  }
                  addonAfter={
                    <Button type="link" onClick={onVerifyReferCode}>
                      Apply
                    </Button>
                  }
                />
              }
              id="referralCode"
              name="referralCode"
              defaultValue={inviteCode || ""}
              control={control}
              placeholder="Enter the invite code you have received"
              rules={{
                required: true,
              }}
            />
            {referralCode === verifiedCode && verifiedCodePerson && (
              <Text
                color="green"
                content={`You have been invited by ${verifiedCodePerson}`}
              />
            )}
          </FormControl>
        </Col>
        <Col xs={24}>
          <FieldWrapper>
            <SwitchWrapper>
              <Controller
                render={({ value, onChange }) => (
                  <Switch checked={value} onChange={onChange} />
                )}
                name="termsAndConditions"
                defaultValue={false}
                control={control}
              />
              <Label>I agree with terms and conditions</Label>
            </SwitchWrapper>
          </FieldWrapper>
          {errors.termsAndConditions && (
            <Alert
              showIcon
              type="error"
              message="Please accept the terms & conditions!"
            />
          )}
        </Col>
        <Col xs={24}>
          <Button
            disabled={formState.isSubmitting}
            className="action_btn"
            type="primary"
            htmlType="submit"
            size="large"
            style={{ width: "100%" }}
          >
            <MdLockOpen />
            Register
          </Button>
          <FormError errors={formErrors} />
        </Col>
      </Row>
    </form>
  );
};
