import React from 'react';
import { Col, Modal, Row } from 'antd';
import { useHistory, generatePath } from 'react-router-dom';
import { get } from 'lodash';
import useSWR from 'swr';

import { BUSINESS_ORDER_CHECKOUT_PAGE, MY_LOCAL_ORDER_DETAILS_PAGE, PAYMENT_PROCESSING_PAGE } from 'settings/constant';
import { fetcher } from 'library/hooks/useDataApi';
import { useBaseData } from 'context/BaseDataProvider';
import { useBusinessOrderCart } from 'context/BusinessOrderCartProvider';
import Container from 'components/UI/Container/Container';
import Box from 'components/UI/Box/Box';
import ActionButton from './ActionButton';
import ProceedToPayWrapper from './ProceedToPay.style';

const ProceedToPay = ({ className = "", nextPage = "CHECKOUT", showFullButton, childrenOnLeft, btnText, btnTextCOD, ...props }) => {
  const history = useHistory();
  const { preferences: { orgName } } = useBaseData()
  const { state, resetCart } = useBusinessOrderCart();
  const { data: cartDetails, isValidating } = useSWR(`${process.env.REACT_APP_API_URL}/cart-details?resetKey=${state.resetKey}`, url => fetcher(url, "POST", {}, JSON.stringify(state)))
  const orderAPI = `${process.env.REACT_APP_API_URL}/business/${state.businessId}/order`

  const handleCODOrder = async () => {
    const result = await fetcher(`${orderAPI}`, "POST", {}, JSON.stringify({ ...state, gateway: "NA" }));
    if (result.errors) {
      window.scrollTo(0,0);
      history.replace(`${BUSINESS_ORDER_CHECKOUT_PAGE}/${state.businessId}`, { errorMessage: result.errors[0].userMessage });
    }
    else {
      resetCart();
      history.push(`${generatePath(MY_LOCAL_ORDER_DETAILS_PAGE, { orderId: result.orderId})}?orderSuccess=true`);
    }
  }

  const handleSuccess = () => {
    switch (nextPage) {
      case 'CHECKOUT': {
        history.push(`${BUSINESS_ORDER_CHECKOUT_PAGE}/${state.businessId}`);
        break;
      }
      case 'PAYMENT': {
        if (cartDetails?.paymentOption === "COD") {
          Modal.confirm({
            title: <b>Please Confirm</b>,
            content: `You need to pay the amount in cash at the time of delivery. Please make sure to keep the cash ready.`,
            onOk: () => handleCODOrder(),
            okText: "Place order"
          })

        }
        else {
          history.push(PAYMENT_PROCESSING_PAGE, {
            gateway: "RAZORPAY",
            paymentProps: {
              orderAPI: `${process.env.REACT_APP_API_URL}/business/${state.businessId}/order`,
              paymentDescription: `Payment to ${orgName}`,
              orderBody: state,
              errorPage: `${BUSINESS_ORDER_CHECKOUT_PAGE}/${state.businessId}`,
            },
            paymentFor: "BUSINESS_ORDER"
          });
        }
        break;
      }
      default: { }
    }
  }

  if (!cartDetails && isValidating) {
    return null
  }

  if (!state.businessId || get(state, "items", []).length < 1) {
    return null
  }

  if (nextPage === "PAYMENT" && cartDetails?.canProceedToPay === false) {
    return null
  }

  return (
    <ProceedToPayWrapper className={showFullButton ? [className, 'full-size-btn'].join(" ") : className}>
      <Container className="proceed-to-pay-container">
        <Row>
          <Col xs={showFullButton ? 0 : 12} lg={16}>
            <Box flexBox flex={1} height="100%" alignItems="center">{childrenOnLeft}</Box>
          </Col>
          <Col align="right" xs={showFullButton ? 24 : 12} lg={8}>
            <ActionButton
              isValidating={isValidating}
              onSuccess={handleSuccess}
              cartDetails={cartDetails}
              btnText={cartDetails?.paymentOption === "COD" ? btnTextCOD : btnText}
              {...props}
            />
          </Col>
        </Row>
      </Container>
    </ProceedToPayWrapper>
  )
}

export default ProceedToPay
