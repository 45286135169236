export function getSearchStringFromObject(urlData) {
  const keys = Object.keys(urlData);
  let search = '?';
  keys.forEach(key => {
    if (urlData[key] !== null && urlData[key] !== undefined && urlData[key] !== '') {
      search += `${key}=${encodeURIComponent(urlData[key])}&`;
    }
  });
  return search.substring(0, search.length - 1);
}

export function getSearchObjectFromLocation(location) {
  const data =
    process.browser && location.search
      ? location.search.slice(location.search.indexOf('?') + 1).split('&')
      : [];
  const urlData = {};
  data.forEach(data => {
    try {
      data = data.split('=');
      const dataVal = decodeURIComponent(data[1]);
      urlData[data[0]] = dataVal;
    } catch (e) { }
  });
  return urlData;
}

export function setStateToUrl(state) {
  let urlData = {};
  for (const key in state) {
    if (state.hasOwnProperty(key)) {
      switch (key) {
        case 'location':
          if (state[key] && state[key].lat) {
            urlData.lat = state[key].lat;
          }
          if (state[key] && state[key].lng) {
            urlData.lng = state[key].lng;
          }
          break;

        case 'category':
          urlData[key] = state[key] && state[key].length ? state[key].join() : null;
          break;

        case 'reset':
          urlData = state[key];
          break;

        default:
          urlData[key] = state[key];
          break;
      }
    }
  }
  return getSearchStringFromObject(urlData);
}

export function getStateFromUrl(location) {
  const urlData = getSearchObjectFromLocation(location);
  const state = {};
  for (const key in urlData) {
    if (urlData.hasOwnProperty(key)) {
      switch (key) {
        case 'lat':
          if (urlData['lat']) {
            state['location'] = {};
            state['location']['lat'] = Number(urlData[key]);
          } else {
            state['location'] = null;
          }
          break;

        case 'lng':
          if (urlData[key]) {
            state['location']['lng'] = Number(urlData[key]);
          }
          break;

        case 'category':
          state[key] =
            urlData[key] && urlData[key] !== 'null'
              ? urlData[key].split(',')
              : [];
          break;

        case 'skip':
          if (urlData[key]) {
            state['skip'] = Number(urlData[key]);
          }
          break;

        case 'rt':  // reset token
          if (urlData[key]) {
            state['rt'] = urlData[key];
          }
          break;

        default:
          state[key] = urlData[key];
          break;
      }
    }
  }
  return state;
}

export function addLatLngToSearch(location, currentLocation) {
  const urlData = getStateFromUrl(location);
  if (!urlData.location) {
    urlData.location = currentLocation;
  }
  return setStateToUrl(urlData);
}

export function addQueryToUrl(url, query = {}) {
  const index = url.indexOf('?')
  const domain = index > 0 ? url.substring(0,index) : url;
  const search = index > 0 ? url.slice(index + 1).split('&') : []

  const urlData = {};
  search.forEach(attribute => {
    try {
      const parts = attribute.split('=');
      urlData[parts[0]] = decodeURIComponent(parts[1]);
    } catch (e) { }
  });
  return `${domain}${getSearchStringFromObject({ ...urlData, ...query })}`;
}
