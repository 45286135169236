import React from 'react';
import { Link } from "react-router-dom";

const LinkTo = ({ to = "", children, ...props }) => {
  const defaultLinkTo = `${window.location.pathname}${window.location.search}`;
  if (to.indexOf("http", 0) !== -1) {
    return (
      <a href={to || defaultLinkTo} target="_blank" rel="noopener noreferrer" {...props}>
        {children}
      </a>
    );
  }
  return <Link to={to || defaultLinkTo} {...props}>{children}</Link>;
};

export default LinkTo;
