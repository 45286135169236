import React, { useContext } from "react";

import { Route, Redirect, Switch, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import loadable from "@loadable/component";
import { useTheme } from "styled-components";
import PuffLoader from "react-spinners/PuffLoader";

import { useBaseData } from "context/BaseDataProvider";
import { AuthContext } from "./context/AuthProvider";
import Layout from "./container/Layout/Layout";
import Maintenance from "./container/500/500";

import {
  LOGIN_PAGE,
  SIGN_UP_PAGE,
  SIGN_UP_CONFIRM_PAGE,
  FORGET_PASSWORD_PAGE,
  HOME_PAGE,
  SEARCH_BUSINESS_PAGE,
  BUSINESS_DETAILS_PAGE,
  BUSINESS_ORDER_CHECKOUT_PAGE,
  MANAGE_MY_BUSINESS_PAGE,
  MANAGE_MY_SELLER_STORE_PAGE,
  ADD_MY_BUSINESS_PAGE,
  ACCOUNT_SETTINGS_PAGE,
  PRIVACY_POLICY_PAGE,
  REFUND_POLICY_PAGE,
  JOIN_AS_MERCHANT,
  ABOUT_US_PAGE,
  TERMS_OF_USE_PAGE,
  TECHNICAL_ERROR_PAGE,
  SUCCESS_PAGE,
  PAYMENT_PROCESSING_PAGE,
  PAYMENT_CALLBACK_PAGE,
  ADMIN_PANEL_PAGE,
  STORE_PRODUCT_SEARCH_PAGE,
  STORE_PRODUCT_PAGE,
  STORE_CHECKOUT_PAGE,
  MY_ORDERS_PAGE,
  MY_LOCAL_ORDER_DETAILS_PAGE,
  MY_STORE_ORDER_DETAILS_PAGE,
  BUSINESS_CATEGORIES_PAGE,
  BUSINESS_PRODUCTS_PAGE,
  INVITE_PAGE,
  BUSINESS_POS_PAGE,
  FORGET_PASSWORD_CONFIRM_PAGE,
  COMMON_PAGE
} from "./settings/constant";

// Public Routes
import SignIn from "container/Auth/SignIn/SignIn";
import SignUp from "container/Auth/SignUp/SignUp";
import SignUpConfirm from "container/Auth/SignUpConfirm/SignUpConfirm";
import ForgetPassword from "container/Auth/ForgotPassword/ForgotPassword";
import ForgotPasswordConfirm from "container/Auth/ForgotPasswordConfirm/ForgotPasswordConfirm";
import AboutUs from "container/AboutUs/AboutUs";
import PrivacyPolicy from "container/Privacy/Privacy";
import RefundPolicy from "container/Refund/Refund";
import TermsOfUse from "container/TermsOfUse/TermsOfUse";
import PaymentProcessing from "container/PaymentProcessing/PaymentProcessing";
import PaymentCallback from "container/PaymentProcessing/PaymentCallback";
import TechnicalError from "container/TechnicalError/TechnicalError";
import Success from "container/Success/Success";
import NotFound from "container/404/404";

// Protected Routes
import JoinAsMerchant from "container/JoinAsMerchant/JoinAsMerchant";
import NearbyBusiness from "container/NearbySearch/NearbyBusiness";
import BusinessOrderCheckout from "container/Business/BusinessOrderCheckout";
import StoreSearch from "container/StoreSearch/StoreSearch";
import StoreCheckout from "container/StoreCheckout/StoreCheckout";
import MyOrders from "container/MyOrders/MyOrders";
import MyNearByOrderDetails from "container/MyOrders/Nearby/OrderDetails";
import MyStoreByOrderDetails from "container/MyOrders/Store/OrderDetails";
import Page from "container/Page/Page";

/**
 *
 * Public Routes
 *
 */
const Loading = () => {
  const theme = useTheme();
  return (
    <div style={{ height: "100vh" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <PuffLoader size={50} color={theme.primary[0]} />
      </div>
    </div>
  );
};

const routes = [
  {
    path: HOME_PAGE,
    component: loadable(
      () => import(/* webpackChunkName: "Home" */ "./container/Home/Home"),
      {
        fallback: <Loading />,
      }
    ),
    exact: true,
  },
  {
    path: COMMON_PAGE,
    component: Page,
  },
  {
    path: LOGIN_PAGE,
    component: SignIn,
  },
  {
    path: SIGN_UP_PAGE,
    component: SignUp,
  },
  {
    path: INVITE_PAGE,
    component: SignUp,
  },
  {
    path: SIGN_UP_CONFIRM_PAGE,
    component: SignUpConfirm,
  },
  {
    path: FORGET_PASSWORD_PAGE,
    component: ForgetPassword,
  },
  {
    path: FORGET_PASSWORD_CONFIRM_PAGE,
    component: ForgotPasswordConfirm,
  },
  {
    path: `${BUSINESS_DETAILS_PAGE}/:slug/:businessId`,
    component: loadable(
      () =>
        import(
          /* webpackChunkName: "BusinessDetails" */ "./container/Business/BusinessDetails"
        ),
      {
        fallback: <Loading />,
      }
    ),
  },
  {
    path: `${BUSINESS_CATEGORIES_PAGE}/:slug/:businessId`,
    component: loadable(
      () =>
        import(
          /* webpackChunkName: "BusinessCategoryMenu" */ "./container/Business/BusinessCategoryMenu"
        ),
      {
        fallback: <Loading />,
      }
    ),
  },
  {
    path: `${BUSINESS_PRODUCTS_PAGE}/:slug/:businessId`,
    component: loadable(
      () =>
        import(
          /* webpackChunkName: "BusinessProductsMenu" */ "./container/Business/BusinessProductsMenu"
        ),
      {
        fallback: <Loading />,
      }
    ),
  },
  {
    path: SEARCH_BUSINESS_PAGE,
    component: NearbyBusiness,
  },
  {
    path: STORE_PRODUCT_SEARCH_PAGE,
    component: StoreSearch,
  },
  {
    path: STORE_PRODUCT_PAGE,
    component: loadable(
      () =>
        import(
          /* webpackChunkName: "StoreProduct" */ "./container/StoreProduct/StoreProduct"
        ),
      {
        fallback: <Loading />,
      }
    ),
  },
  {
    path: ABOUT_US_PAGE,
    component: AboutUs,
  },
  {
    path: PRIVACY_POLICY_PAGE,
    component: PrivacyPolicy,
  },
  {
    path: REFUND_POLICY_PAGE,
    component: RefundPolicy,
  },
  {
    path: TERMS_OF_USE_PAGE,
    component: TermsOfUse,
  },
  {
    path: TECHNICAL_ERROR_PAGE,
    component: TechnicalError,
  },
  {
    path: SUCCESS_PAGE,
    component: Success,
  },
  {
    path: PAYMENT_PROCESSING_PAGE,
    component: PaymentProcessing,
  },
  {
    path: PAYMENT_CALLBACK_PAGE,
    component: PaymentCallback,
  },
];

/**
 *
 * Protected Route Component
 *
 */

const AddBusiness = loadable(
  () =>
    import(
      /* webpackChunkName: "AddBusiness" */ "./container/AddBusiness/AddBusiness"
    ),
  {
    fallback: <Loading />,
  }
);

const ManageBusiness = loadable(
  () =>
    import(
      /* webpackChunkName: "ManageBusiness" */ "./container/ManageBusiness/ManageBusiness"
    ),
  {
    fallback: <Loading />,
  }
);

const ManageSellerStore = loadable(
  () =>
    import(
      /* webpackChunkName: "ManageSellerStore" */ "./container/ManageSellerStore/ManageSellerStore"
    ),
  {
    fallback: <Loading />,
  }
);

const BusinessPos = loadable(
  () =>
    import(/* webpackChunkName: "BusinessPos" */ "./container/Pos/BusinessPos"),
  {
    fallback: <Loading />,
  }
);

const AccountSettingsPage = loadable(
  () =>
    import(
      /* webpackChunkName: "AccountSettingsPage" */ "./container/AccountSettings/AccountSettingsPage"
    ),
  {
    fallback: <Loading />,
  }
);

const AdminPanel = loadable(
  () =>
    import(
      /* webpackChunkName: "AdminPanel" */ "./container/AdminPanel/AdminPanel"
    ),
  {
    fallback: <Loading />,
  }
);

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { pathname, search } = useLocation();
  const { loggedIn } = useContext(AuthContext);
  return (
    <Route
      render={(props) =>
        loggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: LOGIN_PAGE,
              state: {
                loginRequiredMessage: true,
                previousPath: `${pathname}`,
                previousSearch: `${search}`,
              },
            }}
          />
        )
      }
      {...rest}
    />
  );
};

/**
 *
 * Overall Router Component
 *
 */

const Routes = () => {
  const {
    preferences: {
      orgName,
      metaTitle,
      metaDescription,
      metaKeywords = []
    },
  } = useBaseData();
  const { loading, maintenance } = useContext(AuthContext);
  if (loading) {
    return null;
  }
  if (maintenance) {
    return <Maintenance />;
  }
  return (
    <Layout>
      <Helmet>
        <title>{orgName}</title>
        <meta charSet="utf-8" />
        <meta name="keywords" content={metaKeywords.join(",")} />
        <meta name="description" content={metaDescription} />
        <meta
          name="og_title"
          property="og:title"
          content={`${orgName} | ${metaTitle}`}
        />
        <meta
          name="og_description"
          property="og:description"
          content={metaDescription}
        />
      </Helmet>
      <Switch>
        {routes.map(({ path, component, exact = true }) => (
          <Route key={path} path={path} exact={exact} component={component} />
        ))}
        <ProtectedRoute path={JOIN_AS_MERCHANT} component={JoinAsMerchant} />
        <ProtectedRoute path={ADD_MY_BUSINESS_PAGE} component={AddBusiness} />
        <ProtectedRoute
          path={MANAGE_MY_BUSINESS_PAGE}
          component={ManageBusiness}
        />
        <ProtectedRoute
          path={MANAGE_MY_SELLER_STORE_PAGE}
          component={ManageSellerStore}
        />
        <ProtectedRoute path={BUSINESS_POS_PAGE} component={BusinessPos} />
        <ProtectedRoute exact path={MY_ORDERS_PAGE} component={MyOrders} />
        <ProtectedRoute
          path={MY_LOCAL_ORDER_DETAILS_PAGE}
          component={MyNearByOrderDetails}
        />
        <ProtectedRoute
          path={MY_STORE_ORDER_DETAILS_PAGE}
          component={MyStoreByOrderDetails}
        />
        <ProtectedRoute
          path={ACCOUNT_SETTINGS_PAGE}
          component={AccountSettingsPage}
        />
        <ProtectedRoute path={ADMIN_PANEL_PAGE} component={AdminPanel} />
        <ProtectedRoute path={STORE_CHECKOUT_PAGE} component={StoreCheckout} />
        <ProtectedRoute
          path={`${BUSINESS_ORDER_CHECKOUT_PAGE}/:businessId`}
          component={BusinessOrderCheckout}
        />
        <Route component={NotFound} />
      </Switch>
    </Layout>
  );
};

export default Routes;
