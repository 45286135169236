import styled, { css } from "styled-components";
import { themeGet } from "@styled-system/theme-get";

const Wrapper = styled.span`
  &:focus {
    outline: none;
  }
  ${(props) => {
    return (
      props.fullWidth &&
      css`
        width: 100%;
        max-width: none !important;
        button {
          width: 100%;
        }
      `
    );
  }};
  ${(props) => {
    return (
      props.disabled &&
      css`
        * {
          color: grey;
        }
      `
    );
  }};
  button[type="button"] {
    &.ant-btn-sm {
      font-size: 12px;
    }
    &:hover,
    &:focus {
      color: ${themeGet("primary.0", "#008489")};
      border-color: ${themeGet("primary.0", "#008489")};
    }
    &[disabled],
    &[disabled]:hover,
    &[disabled]:focus,
    &[disabled]:active {
      color: rgba(0, 0, 0, 0.5);
      background: #f5f5f5;
      border-color: #d9d9d9;
    }
    &.ant-btn-link {
      color: ${themeGet("text.0", "#2C2C2C")};
      border: none;
      padding: 0 10px;
      height: auto;
      &:hover,
      &:focus {
        color: ${themeGet("primary.0", "#008489")};
      }
      &[disabled],
      &[disabled]:hover,
      &[disabled]:focus,
      &[disabled]:active {
        color: rgba(0, 0, 0, 0.5);
        background: transparent;
        border: none;
      }
    }
    &.ant-btn-primary {
      border-color: ${themeGet("primary.0", "#008489")};
      background-color: ${themeGet("primary.0", "#008489")};
      color: white;
      border: none;
      &:hover,
      &:focus {
        background-color: ${themeGet("secondary.0", "#F7350D")};
      }
      &[disabled],
      &[disabled]:hover,
      &[disabled]:focus,
      &[disabled]:active {
        color: rgba(0, 0, 0, 0.5);
        background: #f5f5f5;
        border-color: #d9d9d9;
      }
    }
  }
  button[type="submit"] {
    cursor: pointer;
    transition: all 0.3s ease;
    border-radius: 3px;
    border-color: ${themeGet("primary.0", "#008489")};
    background-color: ${themeGet("primary.0", "#008489")};
    &::after {
      content: none;
    }
    &:hover,
    &:focus {
      border-color: ${themeGet("secondary.0", "#F7350D")};
      background-color: ${themeGet("secondary.0", "#F7350D")};
    }
    &[disabled],
    &[disabled]:hover,
    &[disabled]:focus,
    &[disabled]:active {
      color: rgba(0, 0, 0, 0.5);
      background: #f5f5f5;
      border-color: #d9d9d9;
    }
  }
`;

export const ColoredButtonWrapper = styled.div`
  ${(props) => {
    return (
      props.fullWidth &&
      css`
        width: 100%;
        max-width: none !important;
        button {
          width: 100%;
        }
      `
    );
  }};
  .ant-btn-primary {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-color: ${props => props.borderColor || props.color  || themeGet("primary.0", "#008489")};
    background-color: ${props => props.color || themeGet("primary.0", "#008489")};
    color: ${props => props.textColor || "white"};

    &:hover,
    &:focus {
      background-color:  ${props => props.hoverColor || props.color || themeGet("secondary.0", "#F7350D")};
      border-color:  ${props => props.hoverBorderColor || props.hoverColor || props.color || themeGet("secondary.0", "#F7350D")};
      color: ${props => props.hoverTextColor  || props.textColor || "white"};
    }
    &[disabled],
      &[disabled]:hover,
      &[disabled]:focus,
      &[disabled]:active {
        color: rgba(0, 0, 0, 0.5);
        background: #d9d9d9;
        border-color: #d9d9d9;
      }
  }
`

export default Wrapper;
