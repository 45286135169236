import React from "react";
import useSWR from "swr";
import { get } from "lodash";
import { Helmet } from "react-helmet";

import Container from "components/UI/Container/Container";
import Loader from "components/Loader/Loader";
import SiteDataRichText from "components/SiteDataRichText/SiteDataRichText";
import { useBaseData } from "context/BaseDataProvider";

const PrivacyPolicy = () => {
  const { preferences: { orgName } } = useBaseData() 
  const { data: siteData, isValidating } = useSWR(`${process.env.REACT_APP_API_URL}/site-data/privacy-policy`)

  if (!siteData && isValidating) {
    return <Loader />
  }
  return (
    <>
      <Helmet>
        <title>{orgName} | Privacy Policy</title>
      </Helmet>
      <Container>
        <SiteDataRichText html={get(siteData, 'data', "")} style={{ marginTop: 20 }} />
      </Container>
    </>
  );
}

export default PrivacyPolicy