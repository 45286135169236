import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";

const DeliveryDetailsWrapper = styled.div`
  .ant-alert,
  .ant-card {
    margin-bottom: 10px;

    &.bordered {
      background-color: #f8f8f8;
      transition: transform .2s;
      box-shadow: 0 0 5px 0 rgb(0 0 0 / 10%);
}


    }
  }
`;

export const AddressCardWrapper = styled.span`
  .ant-card {
    margin-top: 10px;
    border-width: 3px;
    border-radius: 3px;

    .ant-card-actions {
      li {
        margin: 0;
        .button-wrapper {
          button[type="button"] {
            background: #f7f7f7;
            color: ${themeGet("primary.0", "#008489")};
          }
        }
      }
    }
    &.selected,
    &:hover {
      border-color: ${themeGet("primary.0", "#008489")};
      .ant-card-actions {
        .button-wrapper {
          button[type="button"] {
            background-color: ${themeGet("primary.0", "#008489")};
            color: white;
          }
        }
      }
    }
  }
`;

export const SlotWrapper = styled.div`
  .ant-radio-button-wrapper {
    margin-right: 10px;
  }
`;
export default DeliveryDetailsWrapper;
