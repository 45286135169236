import React from 'react'
import { Divider, Radio } from 'antd';
import { DateTime } from 'luxon';
import useSWR from 'swr';
import { FieldTimeOutlined } from '@ant-design/icons';
import { useTheme } from 'styled-components';
import { get } from 'lodash';

import Text from 'components/UI/Text/Text';
import Heading from 'components/UI/Heading/Heading';
import Box from 'components/UI/Box/Box';
import { useBusinessOrderCart } from 'context/BusinessOrderCartProvider';
import { SlotWrapper } from './DeliveryDetails.style';

const Slots = () => {
    const { state, setDeliverySlot } = useBusinessOrderCart();
    const { data: slots } = useSWR(`${process.env.REACT_APP_API_URL}/delivery-slot/${state.businessId}`);

    if (!slots || !Array.isArray(slots)) {
        return <Text content="Please wait while we find available slots" />
    }

    if (Array.isArray(slots) && !slots.length) {
        return <Text color="red" content="No Slots Available at the moment" />
    }
    return (
        <Radio.Group buttonStyle="solid" value={state.slot?.date} onChange={(e) => {
            setDeliverySlot(slots.find(slot => slot.date === e.target.value))
        }}>
            {
                slots.map(slot => {
                    return (
                        <Radio.Button key={slot.id} value={slot.date} disabled={!slot.available} style={{ marginBottom: 10 }}>
                            <Text content={DateTime.fromISO(slot.date).toFormat('dd MMM yyyy')} />
                        </Radio.Button>
                    )
                })
            }
        </Radio.Group>
    )
}

const SlotSelector = () => {
    const theme = useTheme()
    return (
        <SlotWrapper>
            <Heading
                as="h3"
                content={
                    <Box>
                        <FieldTimeOutlined style={{ color: get(theme, "primary.0", '#ff6105'), marginRight: 5 }} />Delivery Slot
                    </Box>
                }
            />
            <Divider type="horizontal" />
            <Slots />
        </SlotWrapper>
    )
}

export default SlotSelector
