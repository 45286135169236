//import React, { useState} from 'react';
import { withRouter } from 'react-router-dom';
// import { FiSearch } from 'react-icons/fi';
// import { setStateToUrl } from 'library/helpers/url_handler';
// import { SEARCH_BUSINESS_PAGE } from 'settings/constant';
// import { NavbarSearchWrapper } from './Header.style';
// import { Input } from 'antd';
//import { kebabCase } from 'lodash';

const NavbarSearch = (props) => {
  //const [categories, setCategories] = useState([]);

  // const goToSearchPage = () => {
  //   const query = {
  //     category: categories.map(c => kebabCase(c)),
  //   };
  //   const search = setStateToUrl(query);
  //   props.history.push({
  //     pathname: SEARCH_BUSINESS_PAGE,
  //     search: search,
  //   });
  // };

  return null;

  // return (
  //   <NavbarSearchWrapper className="navbar_search">
  //     <Input
  //           type="text"
  //           defaultValue=""
  //           value={categories || []}
  //           placeholder="Search for anything like Resturant, Saloon & Spa"
  //           size="large"
  //           onChange={(e) => setCategories([e.target.value])}
  //         />
  //     <FiSearch onClick={goToSearchPage} />
  //   </NavbarSearchWrapper>
  // );
};

export default withRouter(NavbarSearch);
