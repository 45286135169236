import React from 'react'
import { Card } from 'antd';
import { CheckCircleTwoTone } from '@ant-design/icons';

import useWindowSize from 'library/hooks/useWindowSize';
import Button from "components/UI/Button/Button";
import Text from 'components/UI/Text/Text';
import Box from 'components/UI/Box/Box';

const SelectedAddress = ({  address: item, onChangeAddress }) => {
  const { width } = useWindowSize();

  return (
    <>
      <Card
        bordered
        title={
          <Box flexBox flexDirection="row" justifyContent="space-between" alignItems="center">
            <Text fontSize="16px" fontWeight={600} content={`Delivery address`} />
            <CheckCircleTwoTone style={{ fontSize: "20px"}} twoToneColor="#52c41a" />
          </Box>
        }
      >
        <Box flexBox flex={1} flexDirection="row">
          <Box flexBox flex={1} justifyContent="space-between">
            <Box flexBox flex={1} flexDirection="column">
              <Box>
                <Text as="span" fontWeight={600} content={item.name} />
                <Text as="span" content={`, ${item.address}, ${item.city}, ${item.state}, ${item.pin}`} />
              </Box>
              {
                width <= 767 &&
                <Box mt={2} mb={2} flexBox flexDirection="row">
                  <Button onClick={onChangeAddress}>Change</Button>
                </Box>
              }
            </Box>
            {width > 767 &&
              <Box flexBox>
                <Button onClick={onChangeAddress}>Change</Button>
              </Box>}
          </Box>
        </Box>
      </Card>
    </>
  );
}

export default SelectedAddress
