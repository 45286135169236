import React from "react";

import Box from "components/UI/Box/Box";

const StickyBottomBox = ({ children, ...props }) => {
  return (
    <Box
      flexBox
      flexDirection="row"
      alignItems="center"
      justifyContent="center"
      height={60}
      width="100%"
      style={{
        borderTopLeftRadius: 16,
        borderTopRightRadius: 16,
        backgroundColor: "#ffffff",
        boxShadow: "0 1px 10px rgba(0,0,0,0.16)",
        position: "fixed",
        bottom: 0,
        zIndex: 10
      }}
      {...props}
    >
      {children}
    </Box>
  );
};

export default StickyBottomBox;
