import React from "react";
import { Link, useHistory } from "react-router-dom";
import { minBy } from "lodash";

import Text from "components/UI/Text/Text";
import ReactMultiCarousel from "components/UI/ReactMultiCarousel/ReactMultiCarousel";
import { useBaseData } from "context/BaseDataProvider";
import { useAuth } from "context/AuthProvider";
import ProductCardContent from "./ProductCardContent";
import ProductPrice from "./ProductPrice";

const ProductCard = ({
  id: productId,
  brand,
  productName,
  photos,
  variants,
  link,
  onActionButtonClick,
  styleProps = {},
}) => {
  const history = useHistory();
  const { loggedIn } = useAuth();
  const { getAssetUrl } = useBaseData();

  const variant = minBy(variants, "sellingPrice");
  const isOutOfStock = variant.stockQuantity <= 0;
  return (
    <ProductCardContent
      isCarousel={true}
      brand={brand?.brandName}
      productName={
        <Text
          content={[
            productName,
            ["Default"].includes(variant.size) ? "" : `(Size: ${variant.size})`,
          ]
            .filter(Boolean)
            .join(" ")}
        />
      }
      showActionButton={loggedIn && !isOutOfStock}
      showFallbackMessage={isOutOfStock}
      onActionButtonClick={() => onActionButtonClick(variant)}
      onContentClick={() => history.push(link)}
      price={<ProductPrice isLoggedIn={loggedIn} variant={variant} />}
      styleProps={styleProps}
    >
      <ReactMultiCarousel
        id={productId}
        arrows={photos.length > 1}
        transitionDuration={1000}
        draggable={true}
        swipeable={true}
        infinite={true}
        focusOnSelect={false}
        renderDotsOutside={false}
        showDots={true}
        // autoPlay={photos.length > 1}
        // autoPlaySpeed={2000}
        responsive={{
          xxl: { items: 1 },
          xl: { items: 1 },
          lg: { items: 1 },
          md: { items: 1 },
          sm: { items: 1 },
          xs: { items: 1 },
        }}
      >
        {photos.map(({ path }, index) => (
          <Link key={path} to={link}>
            <img
              src={getAssetUrl(path)}
              alt={productName}
              key={index}
              draggable={false}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "fill",
                position: "relative",
                aspectRatio: "3/4",
              }}
            />
          </Link>
        ))}
      </ReactMultiCarousel>
    </ProductCardContent>
  );
};

ProductCard.defaultProps = {};

export default ProductCard;
