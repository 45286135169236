import React from "react";

import BusinessCartWrapper from "./BusinessCart.style";
import CartList from "./CartList";

const BusinessCart = ({ coverHeight = 0, ...props }) => {
  return (
    <BusinessCartWrapper coverHeight={coverHeight}>
      <CartList {...props} />
    </BusinessCartWrapper>
  );
};

export default BusinessCart;
