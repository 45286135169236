import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";

export const CartListWrapper = styled.div`
  .ant-row {
    flex-basis: 100%;
  }
  .ant-col {
    display: flex;
  }
  .ant-typography {
    &.out-of-stock {
      font-size: 18px;
      font-weight: 600;
      color: ${themeGet("error.0", "#F9503D")};
    }
  }
`;

export const ProceedToPay = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 10;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid #f0f0f0;
  background: #fff;
  padding: 16px 22px;
  box-shadow: 0 -2px 10px 0 rgba(0, 0, 0, 0.1);
  button {
    height: auto;
    padding: 11px 22px;
    border-radius: 3px;
    font-size: 15px;
    text-transform: uppercase;
  }
`;

export default CartListWrapper;
