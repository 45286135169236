import React from "react";
import { Checkbox, Collapse, Space } from "antd";
import { DownOutlined } from "@ant-design/icons";

import Box from "components/UI/Box/Box";
import Text from "components/UI/Text/Text";

const { Panel } = Collapse;

const FilterOrderStatus = ({ label, value, onChange }) => {
  return (
    <Box bg="#f6f6f6" width="100%" mb={2}>
      <Collapse
        defaultActiveKey="1"
        accordion={true}
        expandIconPosition="end"
        expandIcon={() => <DownOutlined />}
      >
        <Panel header={label} key="1">
          <FilterOrderStatusPanel value={value} onChange={onChange} />
        </Panel>
      </Collapse>
    </Box>
  );
};

export const FilterOrderStatusPanel = ({ value, onChange }) => {
  const orderStatus = [
    { key: "processing", value: "Processing" },
    { key: "shipped", value: "On the way" },
    { key: "delivered", value: "Delivered" },
    { key: "cancelled", value: "Cancelled" },
    { key: "returned", value: "Returned" },
  ];
  return (
    <Checkbox.Group defaultValue={value} value={value} onChange={onChange}>
      <Space direction="vertical">
        {orderStatus.map((status) => (
          <Checkbox key={status.key} value={status.key}>
            <Text
              fontSize={["10px", "10px", "12px", "12px", "14px", "14px"]}
              content={status.value}
            />
          </Checkbox>
        ))}
      </Space>
    </Checkbox.Group>
  );
};

export default FilterOrderStatus;
