import styled from "styled-components";

export const MyOrdersWrapper = styled.div`
  padding-bottom: 20px;
`;

export const OrderListWrapper = styled.div`
  .ant-list {
    .ant-row {
      flex: 1;
    }
  }
`;
