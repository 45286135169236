import React from "react";
import { useHistory } from "react-router-dom";
import { loadScript } from "library/helpers/utils";
import { fetcher } from "library/hooks/useDataApi";
import { TECHNICAL_ERROR_PAGE } from "settings/constant";
import { Button } from "./PayWithInstamojo.style";

const INSTAMOJO_CHECKOUT_URL = "https://d2xwmjc4uy2hr5.cloudfront.net/im-embed/im-embed.min.js";

const PayWithInstamojo =  ({
  btnStyle = {},
  btnRef,
  buttonText = "",
  orderAPI,
  orderBody = {},
  errorPage,
  paymentFor = "",
  onScriptLoaded = () => { },
  onOrderCreated = () => { },
}) => {
  const history = useHistory();

  const displayInstamojo = async () => {
    const res = await loadScript(INSTAMOJO_CHECKOUT_URL);
    if (!res) {
      if(errorPage) {
        return history.push(errorPage, { errorMessage : "Your payment could not be processed. Please try after sometime."});
      }
      return history.push(TECHNICAL_ERROR_PAGE);
    }
    onScriptLoaded();

    const data = await fetcher(`${orderAPI}`, "POST", {}, JSON.stringify({
      ...orderBody, gateway: "INSTAMOJO", callbackProps: { paymentFor, gateway: "INSTAMOJO" }
    }));
    if (data.errors) {
      if(errorPage) {
        return history.push(errorPage, { errorMessage : data.errors[0].userMessage});
      }
      return history.push(TECHNICAL_ERROR_PAGE);
    } else {
      onOrderCreated(data);
      window.location.replace(`${data.result.longurl}?embed=form`)
    }
  };
  return (
    <Button ref={btnRef} onClick={displayInstamojo} style={btnStyle}>{buttonText}</Button>
  )
};

export default PayWithInstamojo;
