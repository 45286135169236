import React from "react";
import useSWR from "swr";
import { get } from "lodash";

import { responsive } from "./utils";
import Box from "components/UI/Box/Box";
import Heading from "components/UI/Heading/Heading";
import Container from "components/UI/Container/Container";
import SectionHeading from "./Sections/SectionHeading";
import Banner from "./Sections/Banner";
import PhotoGrid from "./Sections/PhotoGrid";
import ProductGrid from "./Sections/ProductGrid";
import Carousel from "./Sections/Carousel";
import FiveProductGallery from "./Sections/FiveProductGallery";

const CommonPage = ({ pageCode = "" }) => {
  if (!pageCode) {
    return <></>;
  }
  return <Page pageCode={pageCode} />;
};

const Page = ({ pageCode = "" }) => {
  const { data: page, isValidating } = useSWR(
    `${process.env.REACT_APP_API_URL}/page?code=${pageCode}`
  );

  const isLoading = !page && isValidating;

  if (isLoading) {
    return <></>;
  }

  const { pageSections = [], siteSections = [] } = get(page, "0", {});
  return (
    <>
      {pageSections.map((pageSection, index) => (
        <Box
          key={index}
          m={responsive(get(pageSection, "margin"), [0])}
          bg={get(pageSection, "bg") || "#ffffff"}
        >
          <Container noGutter fluid={get(pageSection, "fluid", true)}>
            {pageSections[index].title && (
              <Heading
                fontSize={[20, 30, 40, 50, 60]}
                my={[12, 16, 20, 24]}
                content={pageSections[index].title}
                textAlign={pageSections[index].alignTitle}
              />
            )}
            <Section section={siteSections[index]} />
          </Container>
        </Box>
      ))}
    </>
  );
};

const Section = ({ section }) => {
  if (section.componentType === "SectionHeading") {
    return <SectionHeading section={section} />;
  }

  if (section.componentType === "Banner") {
    return <Banner section={section} />;
  }

  if (section.componentType === "PhotoGrid") {
    return <PhotoGrid section={section} />;
  }

  if (section.componentType === "ProductGrid") {
    return <ProductGrid section={section} />;
  }

  if (section.componentType === "Carousel") {
    return <Carousel section={section} />;
  }

  if (section.componentType === "FiveProductGallery") {
    return <FiveProductGallery section={section} />;
  }

  return null;
};

export default CommonPage;
