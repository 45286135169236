import { useBaseData } from 'context/BaseDataProvider';
import React from 'react';
import { withScriptjs, withGoogleMap, GoogleMap } from 'react-google-maps';

const Map = withScriptjs(
  withGoogleMap((props) => <GoogleMap {...props} options={{ ...props.options, disableDefaultUI: true, fullscreenControl: true, zoomControl: true, scrollwheel: false }}>{props.children}</GoogleMap>)
);

const MapWrapper = (props) => {
  const { preferences: { googleMapApiKey } } = useBaseData()
  return (
    <Map
      googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&key=${googleMapApiKey}&libraries=geometry,drawing,places`}
      loadingElement={<div style={{ height: `100%` }} />}
      {...props}
    >
      {props.children}
    </Map>
  );
};

export default MapWrapper;
