import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const Button = styled.button`
  cursor: pointer;
  min-width: 104px;
  min-height: 37px;
  border: 0;
  font-size: 15px;
  line-height: 18px;
  font-weight: 700;
  border-radius: 3px;
  color: ${themeGet('text.0', '#2C2C2C')};
  background-color: ${themeGet('color.2', '#F7F7F7')};
  transition: background-color 0.25s ease;
`;