import React from "react";
import { Button as AntdButton } from "antd";
import Wrapper, { ColoredButtonWrapper } from "./Button.style";

const Button = ({
  wrapperClassName = "",
  wrapperStyles = {},
  fullWidth = false,
  ...props
}) => {
  return (
    <Wrapper
      className={["button-wrapper", wrapperClassName].join(" ")}
      fullWidth={fullWidth}
      style={wrapperStyles}
      onClick={(e) => e.stopPropagation()}
    >
      <AntdButton {...props} />
    </Wrapper>
  );
};

export const ColoredButton = ({
  fullWidth = false,
  color,
  textColor,
  hoverColor,
  hoverTextColor,
  borderColor,
  hoverBorderColor,
  ...props
}) => {
  return (
    <ColoredButtonWrapper
      fullWidth={fullWidth}
      color={color}
      textColor={textColor}
      hoverColor={hoverColor}
      hoverTextColor={hoverTextColor}
      borderColor={borderColor}
      hoverBorderColor={hoverBorderColor}
      onClick={(e) => e.stopPropagation()}
    >
      <AntdButton type="primary" {...props} />
    </ColoredButtonWrapper>
  );
};

export default Button;
