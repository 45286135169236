import styled from "styled-components";

export const StoreWrapper = styled.div``;

export const ProductsWrapper = styled.div`
  max-width: 1920px;
  padding: 30px;

  @media only screen and (max-width: 767px) {
    padding: 20px;
  }

  @media only screen and (max-width: 575px) {
    padding: 0;
    padding-bottom: 65px;
  }
`;

export default StoreWrapper;
