import React from 'react';
import MarkerClusterer from 'react-google-maps/lib/components/addons/MarkerClusterer';
import MapWrapper from './MapWrapper';
import StaticMapWithDetailedMarker from './StaticMapWithDetailedMarker';

const Map = (props) => {
  const { multiple, data, defaultCenter = { lat: 28.6139391, lng: 77.2090212 }, height = "400px", width="auto", defaultZoom=15 } = props;

  const handleClustererClick = (clicked) => {
   // const markerClusterer = clicked.getMarkers();
  };

  return (
    <>
      <MapWrapper
        containerElement={<div style={{ height: height, width: width }} />}
        mapElement={<div style={{ height: '100%' }} />}
        defaultZoom={defaultZoom}
        defaultCenter={defaultCenter}
      >
        <MarkerClusterer
          gridSize={60}
          averageCenter
          defaultEnableRetinaIcons={true}
          onClick={handleClustererClick}
        >
          <StaticMapWithDetailedMarker data={multiple ? data : [data[0]]} />
        </MarkerClusterer>
      </MapWrapper>

    </>
  );
};

export default Map;
