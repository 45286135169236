import React, { Fragment } from "react";
import { withRouter, matchPath } from "react-router-dom";
import { Layout as LayoutWrapper } from "antd";
import LayoutProvider from "context/LayoutProvider";
// import CookiePolicy from 'components/CookiePolicy/CookiePolicy';

import {
  SEARCH_BUSINESS_PAGE,
  LOGIN_PAGE,
  SIGN_UP_PAGE,
  INVITE_PAGE,
  SIGN_UP_CONFIRM_PAGE,
  ACCOUNT_SETTINGS_PAGE,
  ADD_MY_BUSINESS_PAGE,
  JOIN_AS_MERCHANT,
  FORGET_PASSWORD_PAGE,
  MANAGE_MY_BUSINESS_PAGE,
  MANAGE_MY_SELLER_STORE_PAGE,
  PAYMENT_PROCESSING_PAGE,
  PAYMENT_CALLBACK_PAGE,
  ADMIN_PANEL_PAGE,
  STORE_PRODUCT_SEARCH_PAGE,
  STORE_PRODUCT_PAGE,
  STORE_CHECKOUT_PAGE,
  BUSINESS_ORDER_CHECKOUT_PAGE,
  MY_LOCAL_ORDER_DETAILS_PAGE,
  BUSINESS_DETAILS_PAGE,
  BUSINESS_PRODUCTS_PAGE,
  BUSINESS_CATEGORIES_PAGE,
  BUSINESS_POS_PAGE
} from "settings/constant";
import Header from "./Header/Header";
import FooterSection from "./Footer/Footer";
const { Content } = LayoutWrapper;

const LayoutWithContent = ({ children }) => {
  return <Content>{children}</Content>;
};

const LayoutWithHeaderAndContentAndBlankFooter = ({ children }) => {
  return (
    <Fragment>
      <Header />
      <Content>{children}</Content>
    </Fragment>
  );
};

const LayoutWithHeaderAndContentAndFooter = ({ children }) => {
  return (
    <Fragment>
      <Header />
      <Content>{children}</Content>
      <FooterSection />
    </Fragment>
  );
};

export default withRouter(function Layout({ children, location }) {
  const pagesWithContentOnly = [
    LOGIN_PAGE,
    FORGET_PASSWORD_PAGE,
    INVITE_PAGE,
    SIGN_UP_PAGE,
    SIGN_UP_CONFIRM_PAGE,
    PAYMENT_PROCESSING_PAGE,
    PAYMENT_CALLBACK_PAGE,
    BUSINESS_POS_PAGE,
    MANAGE_MY_BUSINESS_PAGE,
    MANAGE_MY_SELLER_STORE_PAGE,
    ADMIN_PANEL_PAGE,
  ];
  const pagesWithHeaderAndContentAndNoFooter = [
    SEARCH_BUSINESS_PAGE,
    JOIN_AS_MERCHANT,
    ADD_MY_BUSINESS_PAGE,
    ACCOUNT_SETTINGS_PAGE,
    STORE_PRODUCT_SEARCH_PAGE,
    STORE_PRODUCT_PAGE,
    STORE_CHECKOUT_PAGE,
    BUSINESS_DETAILS_PAGE,
    BUSINESS_PRODUCTS_PAGE,
    BUSINESS_CATEGORIES_PAGE,
    BUSINESS_ORDER_CHECKOUT_PAGE,
    MY_LOCAL_ORDER_DETAILS_PAGE
  ];

  const hasAnyMatch = (routes = []) => !!routes.find(route => matchPath(location.pathname, route))

  return (
    <LayoutProvider>
      {hasAnyMatch(pagesWithContentOnly) && (
        <LayoutWithContent>{children}</LayoutWithContent>
      )}
      {hasAnyMatch(pagesWithHeaderAndContentAndNoFooter) && (
        <LayoutWithHeaderAndContentAndBlankFooter>
          {children}
        </LayoutWithHeaderAndContentAndBlankFooter>
      )}
      {!hasAnyMatch(pagesWithContentOnly) &&
        !hasAnyMatch(pagesWithHeaderAndContentAndNoFooter) && (
          <LayoutWithHeaderAndContentAndFooter>
            {children}
          </LayoutWithHeaderAndContentAndFooter>
        )}
      {/* <CookiePolicy/> */}
    </LayoutProvider>
  );
});
