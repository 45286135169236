import React from "react";
import { Card, Divider } from "antd";
import { ClipLoader } from "react-spinners";
import { EditOutlined } from "@ant-design/icons";
import { Link, generatePath } from "react-router-dom";
import { round } from "lodash";

import Text from "components/UI/Text/Text";
import Box from "components/UI/Box/Box";
import Button from "components/UI/Button/Button";
import Image from "components/UI/Image/Image";
import TextLink from "components/UI/TextLink/TextLink";
import { useBaseData } from "context/BaseDataProvider";
import { useStoreCart } from "context/StoreCartProvider";
import ReturnExchange from "components/ReturnExchange/ReturnExchange";
import { formatCurrency } from "library/helpers/utils";
import useWindowSize from "library/hooks/useWindowSize";
import { STORE_PRODUCT_PAGE } from "settings/constant";

const ConfirmedCart = ({ isActive, delivery }) => {
  const { width } = useWindowSize();
  const { getAssetUrl } = useBaseData();
  const { cart = [], setShowCart } = useStoreCart();

  return (
    <>
      <Card
        bordered
        className={isActive ? "is-active" : ""}
        bodyStyle={{ padding: 10 }}
        title={
          <Box
            flexBox
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Text
              fontSize={["14px", "14px", "14px", "16px"]}
              fontWeight={600}
              content={`Items in cart`}
            />
            <Button onClick={() => setShowCart(true)} type="link" shape="round">
              <EditOutlined />
              <Text
                fontSize={["14px", "14px", "14px", "16px"]}
                fontWeight="600"
                as="span"
                content="Edit"
                style={{ textDecoration: "underline" }}
              />
            </Button>
          </Box>
        }
      >
        <Box flexBox flex={1} flexDirection="row">
          <Box flexBox flex={1} justifyContent="space-between">
            <Box flexBox flex={1} flexDirection="column">
              <Box flexBox flex={1} flexDirection="column">
                {cart.map((item, index) => {
                  const { productId, variantId, quantity, meta } = item;
                  const variant = meta.variants.find(
                    (variant) => variant.id === variantId
                  );
                  const itemDelivery = delivery.items.find(
                    (product) => product.productId === productId
                  );
                  const isOutOfStock = variant.stockQuantity === 0;
                  const isPartialOutOfStock = quantity > variant.stockQuantity;
                  const link = generatePath(STORE_PRODUCT_PAGE, {
                    productId: productId,
                    variantId: variantId,
                  });
                  return (
                    <>
                      {index !== 0 && <Divider />}
                      <Box flexBox flexDirection="row">
                        <Link to={link}>
                          <Image
                            src={getAssetUrl(meta?.photos[0].path)}
                            alt={meta?.productName}
                            width="100px"
                            m={1}
                          />
                        </Link>
                        <Box
                          flexBox
                          flex={1}
                          flexDirection="column"
                          justifyContent="center"
                          ml={3}
                          mr={3}
                        >
                          <Text
                            className="brand-name"
                            fontWeight="600"
                            content={meta.brand.brandName}
                          />
                          <Box flexBox flexDirection="row" alignItems="center">
                            <TextLink
                              className="product-name"
                              fontWeight="400"
                              to={link}
                              content={[
                                `${meta.productName}`,
                                variant.size !== "Default"
                                  ? `(Size : ${variant.size})`
                                  : "",
                              ]
                                .filter(Boolean)
                                .join(" ")}
                            />
                            {quantity > 1 && <Text
                              ml={1}
                              fontWeight={400}
                              fontSize={14}
                              color="gray"
                              content={`x ${quantity}`}
                            />}
                          </Box>

                          <Text
                            className="sold-by"
                            fontWeight="300"
                            fontSize="12"
                            content={`Sold By: ${meta.seller?.name}`}
                          />
                          <Box
                            flexBox
                            flexDirection="row"
                            justifyContent="flex-start"
                          >
                            <Text
                              fontSize={14}
                              fontWeight={700}
                              as="span"
                              content={formatCurrency(
                                variant.sellingPrice * quantity,
                                0,
                                true
                              )}
                            />
                            {variant.maximumPrice !== variant.sellingPrice && (
                              <>
                                <Text
                                  color="gray"
                                  as="span"
                                  content={formatCurrency(
                                    variant.maximumPrice * quantity,
                                    0,
                                    true
                                  )}
                                  pl={1}
                                  pr={2}
                                  style={{ textDecoration: "line-through" }}
                                />
                                <Text
                                  className="discount"
                                  as="span"
                                  color="indianred"
                                  content={`(${round(
                                    variant.discountPercent
                                  )}% OFF)`}
                                  pl={1}
                                />
                              </>
                            )}
                          </Box>
                          <Box
                            flexBox
                            flexDirection="row"
                            alignItems="center"
                            mt={1}
                          >
                            {isOutOfStock || isPartialOutOfStock ? (
                              <Text
                                fontWeight={600}
                                fontSize="12px"
                                color={
                                  isOutOfStock || isPartialOutOfStock
                                    ? "indianred"
                                    : "black"
                                }
                                content={
                                  isOutOfStock
                                    ? "Out of stock"
                                    : `Only ${variant.stockQuantity} in stock`
                                }
                              />
                            ) : (
                              <ReturnExchange
                                returnDays={meta.productType.returnDays}
                                exchangeDays={meta.productType.exchangeDays}
                              />
                            )}
                          </Box>
                          {width <= 767 && (
                            <Box
                              flexBox
                              flexDirection="row"
                              alignItems="center"
                            >
                              <DeliveryDetails
                                loading={delivery.loading}
                                itemDelivery={itemDelivery}
                              />
                            </Box>
                          )}
                        </Box>
                        {width > 767 && (
                          <Box flexBox flexDirection="row" alignItems="center">
                            <DeliveryDetails
                              loading={delivery.loading}
                              itemDelivery={itemDelivery}
                            />
                          </Box>
                        )}
                      </Box>
                    </>
                  );
                })}
                <Text
                  fontWeight="600"
                  textDecoration="italic"
                  content={cart.length > 8 ? `+ ${cart.length - 8} items` : ""}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Card>
    </>
  );
};

const DeliveryDetails = ({ loading, itemDelivery }) => {
  const {
    preferences: { primaryColor },
  } = useBaseData();
  if (loading) {
    return <ClipLoader size={20} color={primaryColor} />;
  }
  if (!loading && !itemDelivery) {
    return (
      <Text
        fontSize={12}
        fontWeight={400}
        color={"orange"}
        content={"Select address to calculate delivery"}
      />
    );
  }
  return (
    <Text
      fontSize={[12, 12, 14]}
      fontWeight={600}
      color={itemDelivery.servicable ? "green" : "red"}
      content={
        itemDelivery.servicable
          ? `Delivery in ${itemDelivery.etd}-${itemDelivery.etd + 3} days`
          : "Not deliverable"
      }
    />
  );
};

export default ConfirmedCart;
