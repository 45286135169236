import React from 'react';
import Image from 'components/UI/Image/Image';
import UnderMaintenanceWrapper, { ContentWrapper } from './500.style';

import UnderMaintenance from 'assets/images/under-maintenance.jpg';

export default ({ staticContext = {} }) => {
  staticContext.status = 500;
  return (
    <UnderMaintenanceWrapper>
      <ContentWrapper>
        <Image src={UnderMaintenance} alt="Under Maintenance" />
      </ContentWrapper>
    </UnderMaintenanceWrapper>
  );
};
