import React, { useEffect, useState } from "react";
import { Row, Col, Badge, Divider, Drawer, Tabs } from "antd";

import Text from "components/UI/Text/Text";
import Box from "components/UI/Box/Box";
import Button from "components/UI/Button/Button";
import { setStateToUrl, getStateFromUrl } from "library/helpers/url_handler";
import FilterOrderStatus, { FilterOrderStatusPanel } from "./FilterOrderStatus";
import FilterOrderType, { FilterOrderTypePanel } from "./FilterOrderType";
import SortOptions, { SortOptionsPanel } from "./SortOptions";
import { FaFilter, FaSort } from "react-icons/fa";

const FilterLabel = ({ text = "", count = 0 }) => {
  return (
    <Box
      flexBox
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Text
        fontSize={["10px", "10px", "12px", "12px", "14px", "14px"]}
        content={text}
      />
      <Badge size="small" count={count} />
    </Box>
  );
};

const SortAndFilter = ({ history, location }) => {
  const searchParams = getStateFromUrl(location);

  const onChange = (value, type) => {
    const query = {
      ...searchParams,
      [type]: value,
    };
    const search = setStateToUrl(query);
    history.push({
      pathname: location.pathname,
      search: search,
    });
  };

  useEffect(() => {
    if (!["nearby", "store"].includes(searchParams.orderType)) {
      onChange("store", "orderType");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Row>
      <Col xs={24}>
        <SortOptions
          label={<FilterLabel text="Sort By" />}
          value={searchParams.sortBy}
          onChange={(value) => onChange(value, "sortBy")}
        />
        <FilterOrderStatus
          label={<FilterLabel text="Order Status" />}
          value={searchParams.orderState?.split(",")}
          onChange={(value) => onChange(value?.join(","), "orderState")}
        />
        <FilterOrderType
          label={<FilterLabel text="Order Type" />}
          value={searchParams.orderType?.split(",")}
          onChange={(value) => onChange(value, "orderType")}
        />
      </Col>
    </Row>
  );
};

export const MobileSortAndFilter = ({ history, location }) => {
  const searchParams = getStateFromUrl(location);

  const [showFilter, setShowFilter] = useState(false);
  const [showSort, setShowSort] = useState(false);
  const [query, setQuery] = useState(getStateFromUrl(location));

  useEffect(() => {
    if (!["nearby", "store"].includes(searchParams.orderType)) {
      onChange("store", "orderType");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (value, type) => {
    const newQuery = {
      [type]: value,
    };
    console.log({ newQuery })
    setQuery(newQuery);
  };

  const onCancel = () => {
    setQuery(getStateFromUrl(location));
    setShowFilter(false);
    setShowSort(false);
  };

  const onApply = () => {
    const search = setStateToUrl({ ...getStateFromUrl(location), ...query });
    setShowFilter(false);
    setShowSort(false);
    history.push({
      pathname: location.pathname,
      search: search,
    });
  };

  return (
    <>
      <Box
        flexBox
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        <Button fullWidth type="link" onClick={() => setShowSort(true)}>
          <Box
            flexBox
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
          >
            <FaSort style={{ height: 18 }} />
            <Text fontSize={[14]} ml={2} content="Sort" />
          </Box>
        </Button>
        <Divider type="vertical" style={{ color: "gray" }} />
        <Button fullWidth type="link" onClick={() => setShowFilter(true)}>
          <Box
            flexBox
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
          >
            <FaFilter style={{ height: 18 }} />
            <Text fontSize={[14]} ml={2} content="Filter" />
          </Box>
        </Button>
      </Box>
      <Drawer
        title={<Text fontSize={[14]} content="Sort by" />}
        placement="bottom"
        height={265}
        headerStyle={{ height: 65 }}
        contentWrapperStyle={{
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16,
          overflow: "hidden",
        }}
        onClose={onCancel}
        open={showSort}
        closable={false}
        extra={
          <Button type="dashed" onClick={onApply}>
            Apply
          </Button>
        }
        bodyStyle={{ padding: 16 }}
      >
        <Box>
          <SortOptionsPanel
            label={<FilterLabel text="Sort By" />}
            value={query.sortBy}
            onChange={(value) => onChange(value, "sortBy")}
          />
        </Box>
      </Drawer>
      <Drawer
        title={<Text fontSize={[14]} content="Select Filters" />}
        placement="bottom"
        height={365}
        headerStyle={{ height: 65 }}
        closable={false}
        onClose={onCancel}
        open={showFilter}
        contentWrapperStyle={{
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16,
          overflow: "hidden",
        }}
        extra={
          <Button type="dashed" onClick={onApply}>
            Apply
          </Button>
        }
        bodyStyle={{ padding: 0 }}
      >
        <Tabs
          defaultActiveKey="1"
          tabPosition="left"
          type="card"
          style={{ height: 300 }}
          items={[
            {
              label: <FilterLabel text="Order Status" />,
              key: "orderState",
              style: { padding: 12, height: "100%", overflowY: "scroll" },
              children: (
                <Box>
                  <FilterOrderStatusPanel
                    value={query.orderState?.split(",")}
                    onChange={(value) =>{
                      console.log({ value })
                      onChange(value?.join(","), "orderState")
                    }
                    }
                  />
                </Box>
              ),
            },
            {
              label: <FilterLabel text="Order Type" />,
              key: "orderType",
              style: { padding: 12, height: "100%", overflowY: "scroll" },
              children: (
                <Box>
                  <FilterOrderTypePanel
                    value={query.orderType?.split(",")}
                    onChange={(value) => onChange(value, "orderType")}
                  />
                </Box>
              ),
            },
          ]}
        />
      </Drawer>
    </>
  );
};

export default SortAndFilter;
