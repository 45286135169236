import React from "react";
import { Checkbox, Collapse, Space } from "antd";
import { DownOutlined } from "@ant-design/icons";

import Text from "components/UI/Text/Text";
import Box from "components/UI/Box/Box";
import useDataApi from "library/hooks/useDataApi";

const { Panel } = Collapse;

const FilterSubCategory = ({ label, value, onChange }) => {
  return (
    <Box bg="#f6f6f6" width="100%" mb={2}>
      <Collapse
        defaultActiveKey={value ? "1" : null}
        accordion={true}
        expandIconPosition="end"
        expandIcon={() => <DownOutlined />}
      >
        <Panel
          header={label}
          key="1"
        >
          <FilterSubCategoryPanel value={value} onChange={onChange} />
        </Panel>
      </Collapse>
    </Box>
  );
};

export const FilterSubCategoryPanel = ({ value, onChange }) => {
  const url = `${process.env.REACT_APP_API_URL}/search/store-sub-category`;
  const { data: subCategories = [] } = useDataApi(`${url}`, 1000);

  return (
    <Checkbox.Group defaultValue={value} value={value} onChange={onChange}>
      <Space direction="vertical">
        {subCategories.map((subCategory) => (
          <Checkbox
            key={subCategory.subCategoryCode}
            value={subCategory.subCategoryCode}
          >
            <Text
              fontSize={["10px", "10px", "12px", "12px", "14px", "14px"]}
              content={subCategory.subCategoryName}
            />
          </Checkbox>
        ))}
      </Space>
    </Checkbox.Group>
  );
};

export default FilterSubCategory;
