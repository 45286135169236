import React from 'react';
import ErrorPageWrapper, { ContentWrapper } from './TechnicalError.style';

import { Result } from 'antd';
import { NavLink, useLocation } from 'react-router-dom';

export default () => {
  const location = useLocation();
  
  return (
    <ErrorPageWrapper>
      <ContentWrapper>
      <Result
        status="error"
        title={location.state?.title || "Technical Error Occurred"}
        subTitle={location.state?.description || "It seems like something is not right here. Please try after sometime."}
        extra={[<NavLink key="home" to="/">Go To Home</NavLink>]}
      />
      </ContentWrapper>
    </ErrorPageWrapper>
  );
};
