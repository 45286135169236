import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { ArrowRightOutlined } from '@ant-design/icons';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import { BUSINESS_PRODUCTS_PAGE } from 'settings/constant';
import { fetcher } from 'library/hooks/useDataApi';
import { showBoomOrderNowButton } from 'library/helpers/utils';
import TextLink from 'components/UI/TextLink/TextLink';
import Favourite from 'components/UI/Favorite/Favorite';
import Rating from 'components/UI/Rating/Rating';
import Text from 'components/UI/Text/Text';
import Box from 'components/UI/Box/Box';
import { AuthContext } from 'context/AuthProvider';
import { useBaseData } from 'context/BaseDataProvider';
import BusinessCardContent from './BusinessCardContent';

const responsive = {
  desktop: {
    breakpoint: {
      max: 3000,
      min: 1024,
    },
    items: 1,
    paritialVisibilityGutter: 40,
  },
  mobile: {
    breakpoint: {
      max: 464,
      min: 0,
    },
    items: 1,
    paritialVisibilityGutter: 30,
  },
  tablet: {
    breakpoint: {
      max: 1024,
      min: 464,
    },
    items: 1,
    paritialVisibilityGutter: 30,
  },
};

const BusinessCard = ({
  id,
  slug,
  name,
  address,
  description,
  photos,
  link,
  isBoomPartner,
  isOpen,
  isBoomActive,
  rating,
  ratingCount,
  hideRatingText,
  topLeftReviewTextProps,
  topLeftReviewTextStyle,
  actionBtnProps,
  hideFooterButton = false,
  maximumCarouselPhotos = 10
}) => {
  const { getAssetUrl } = useBaseData();
  const { loggedIn, user, setUser } = useContext(AuthContext);
  const handleFavorite = async (event) => {
    try {
      const result = await fetcher(`${process.env.REACT_APP_API_URL}/my-favorites/${id}`, "PUT", {}, JSON.stringify({ favorite: event }))
      if (result?.user) {
        setUser(result.user)
      }
    }
    catch (e) {
      // Handle errors here ex: Timeout or server
    }
  }

  const canPlaceOrder = showBoomOrderNowButton({ isBoomPartner, isOpen, isBoomActive });
  return (
    <BusinessCardContent
      isCarousel={true}
      favorite={
        loggedIn ? <Favourite
          defaultValue={!!(user?.favorites || []).find(favorite => favorite === id)}
          onClick={handleFavorite}
        /> : null
      }
      location={address}
      linkTo={`${link}/${slug}/${id}`}
      title={name}
      description={description}
      boomIcon={null}
      topLeftReviewText={topLeftReviewTextProps ?
         <Text {...topLeftReviewTextStyle} {...topLeftReviewTextProps} /> :
         isBoomPartner && canPlaceOrder ? <Text px={2} bg={"#4caf50"} content="OPEN"/> : null}
      offer={null}
      rating={<Rating rating={rating} ratingCount={ratingCount} type="bulk" hideRatingText={hideRatingText} />}
      actionBtn={actionBtnProps ? <TextLink {...actionBtnProps} /> : null}
      footerBtn={(canPlaceOrder && !hideFooterButton) ?
        <Link to={`${BUSINESS_PRODUCTS_PAGE}/${slug}/${id}`} style={{ width: "100%" }}>
          <Box flexBox flex={1} flexDirection="row" alignItems="center" justifyContent="space-between">
            <Text color="white" content="Order Now" /><ArrowRightOutlined style={{ color: "white" }} />
          </Box>
        </Link>
        : null
      }
    >
      <Carousel
        additionalTransfrom={0}
        arrows
        autoPlaySpeed={3000}
        containerClass="container"
        dotListClass=""
        draggable
        focusOnSelect={false}
        infinite
        itemClass=""
        renderDotsOutside={false}
        responsive={responsive}
        showDots={true}
        sliderClass=""
        slidesToSlide={1}
      >
        {photos.slice(0, maximumCarouselPhotos).map(({ path }, index) => (
          <img
            src={getAssetUrl(path)}
            alt={name}
            key={index}
            draggable={false}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              position: 'relative',
            }}
          />
        ))}
      </Carousel>
    </BusinessCardContent>
  );
};

BusinessCard.defaultProps = {
  topLeftReviewTextStyle: {
    fontSize: ['13px', '13px', '15px'],
    fontWeight: '700',
    color: 'white',
    padding: "0 5px",
    backgroundColor: "#f29100"
  }
}

export default BusinessCard;
