import styled from "styled-components";

export const OrderDetailsWrapper = styled.div`
  .ant-list {
    border-color: #f0f0f0;
    margin-bottom: 20px;

    .ant-list-header {
      .ant-col {
        margin-bottom: 10px;
        &.order-status {
          @media only screen and (max-width: 575px) {
            display: block;
          }
          display: flex;
          .ant-tag {
            margin-right: 0;
          }
        }
      }
      .order-actions {
        .action-button {
          display: flex;
          flex: 1;
          justify-content: flex-end;
          margin-bottom: 10px;
          button {
            width: 100%;
            max-width: 220px;
            @media only screen and (max-width: 575px) {
              max-width: 100%;
            }
          }
        }
      }
    }
    .ant-list-item {
        display: block;
        width: 100%;
    }
  }
`;

