import React, { useState, useEffect, useContext } from 'react';
import { fetcher } from 'library/hooks/useDataApi';
import { get } from 'react-hook-form';
import blankBanner from "assets/images/blank-banner.svg";

export const BaseDataContext = React.createContext();

const BaseDataProvider = (props) => {
  const [state, setState] = useState({
    preferences: {},
  })

  useEffect(() => {
    fetchPreferences();
  }, [])

  const fetchPreferences = async () => {
    try {
      const result = await fetcher(`${process.env.REACT_APP_API_URL}/site-preferences`)
      if (!result.errors) {
        setState(state => ({ ...state, preferences: result }))
      }
    }
    catch (e) {
      // Handle errors here ex: Timeout or server or fetch
      console.log(e);
    }
  };

  const getAssetUrl = (path) => {
    const assetsBucket = get(state, 'preferences.assetsBucketUrl', null)
    if (!path) {
      return null;
    }
    if (!assetsBucket) {
      return blankBanner;
    }
    return `${assetsBucket}/${path}`
  }

  return (
    <BaseDataContext.Provider
      value={{ ...state, getAssetUrl }}
    >
      <>{props.children}</>
    </BaseDataContext.Provider>
  );
};

export const useBaseData = () => useContext(BaseDataContext)

export default BaseDataProvider;
