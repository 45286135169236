import { themeGet } from "@styled-system/theme-get";
import styled from "styled-components";

const SiteDataWrapper = styled.div`
  > div {
    border-radius: 3px;
    padding: 58px 60px 30px;
    color: ${themeGet('text.0', '#2C2C2C')};
    background-color: ${themeGet('color.2', '#F7F7F7')};

    @media only screen and (max-width: 991px) {
      margin-top: 45px;
      margin-bottom: 15px;
      padding: 45px 50px;
    }
    @media only screen and (max-width: 767px) {
      padding: 0 30px;
    }
    @media only screen and (max-width: 480px) {
      padding: 0 25px;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-bottom: 5px;
    }

    h1,
    h2 {
      font-size: 25px;
      font-weight: 700;
      padding-top: 10px;
      &:first-child {
        padding-top: 0;
      }
    }

    h4,
    h5,
    h6 {
      font-size: 15px;
    }
    
    ul,
    ol {
      margin-bottom: 27px;
      li {
        list-style: disc;
        line-height: 24px;
      }
    }
    p {
        margin-bottom: 0;
        line-height: 24px;
    }
    a {
      color: ${themeGet('text.0', '#2C2C2C')}; 
      font-weight: 600
    }
  }
`;

export default SiteDataWrapper;