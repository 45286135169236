import React, { useContext } from 'react';
import { Link, Redirect, useLocation } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { MdLockOpen } from 'react-icons/md';
import { Input, Switch, Button, Alert, InputNumber } from 'antd';

import { getStateFromUrl } from 'library/helpers/url_handler';
import { FORGET_PASSWORD_PAGE } from 'settings/constant';
import FormControl from 'components/UI/FormControl/FormControl';
import FormError from 'components/UI/FormError/FormError';
import { AuthContext } from 'context/AuthProvider';
import { FieldWrapper, SwitchWrapper, Label } from '../Auth.style';

const SignInForm = () => {
  const location = useLocation();
  const searchParams = getStateFromUrl(location);
  const { signIn, errors: signInErrors, resetErrors, loggedIn } = useContext(AuthContext);
  const { control, errors, handleSubmit } = useForm();
  const onSubmit = async (data) => {
    signIn(data);
    setTimeout(() => {
      resetErrors();
    }, 2000)
  };
  if (loggedIn) {
    return <Redirect to={{ pathname: location.state?.previousPath || '/', search: location.state?.previousSearch }} />;
  }

  return (
    <form className="signin_form" onSubmit={handleSubmit(onSubmit)}>
      {(location.state?.loginRequiredMessage || searchParams?.sessionExpired === "true") && <Alert message="Please login to continue" type="warning" showIcon />}
      <FormControl
        label="Mobile"
        htmlFor="mobile"
        error={
          errors.mobile && (
            <>
              {errors.mobile?.type === 'required' && (
                <span>Please enter your mobile number!</span>
              )}
              {errors.mobile?.type === 'pattern' && (
                <span>Please enter your valid 10 digit mobile number!</span>
              )}
            </>
          )
        }
      >
        <Controller
          as={<InputNumber
            addonBefore="+91"
            stringMode={true}
            controls={false}
            precision={0}
          />}
          type="mobile"
          id="mobile"
          name="mobile"
          defaultValue=""
          control={control}
          rules={{
            required: true,
            pattern: /^[0-9]{10}$/,
          }}
        />
      </FormControl>
      <FormControl
        label="Password"
        htmlFor="password"
        error={
          errors.password && (
            <>
              {errors.password?.type === 'required' && (
                <span>Please enter your password!</span>
              )}
              {errors.password?.type === 'minLength' && (
                <span>Password must be at least 6 characters!</span>
              )}
              {errors.password?.type === 'maxLength' && (
                <span>Password must not be longer than 20 characters!</span>
              )}
            </>
          )
        }
      >
        <Controller
          as={<Input.Password />}
          defaultValue=""
          control={control}
          id="password"
          name="password"
          rules={{ required: true, minLength: 6, maxLength: 20 }}
        />
      </FormControl>
      <FieldWrapper>
        <SwitchWrapper>
          <Controller
            render={({ value, onChange }) => <Switch checked={value} onChange={onChange} />}
            name="rememberMe"
            defaultValue={false}
            control={control}
          />
          <Label>Remember Me</Label>
        </SwitchWrapper>
        <Link to={FORGET_PASSWORD_PAGE}>Forgot Password ?</Link>
      </FieldWrapper>
      <Button
        className="action_btn"
        type="primary"
        htmlType="submit"
        size="large"
        style={{ width: '100%' }}
      >
        <MdLockOpen />
        Login
      </Button>
      <FormError errors={signInErrors} />
    </form>
  );
};

export default SignInForm;
