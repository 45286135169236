import React from 'react';
import { Redirect } from 'react-router';
import { Row, Col, Alert } from 'antd';
import { get } from 'lodash';
import useSWR from 'swr';
import Sticky from 'react-stickynode';

import Container from 'components/UI/Container/Container';
import Box from 'components/UI/Box/Box';
import Text from 'components/UI/Text/Text';
import Button from 'components/UI/Button/Button';
import Loader from 'components/Loader/Loader';
import ProceedToPay from './ProceedToPay/ProceedToPay';
import PageWrapper from './Business.style';
import { useBusinessOrderCart } from 'context/BusinessOrderCartProvider';
import { showBoomOrderNowButton } from 'library/helpers/utils';
import CheckoutProcessor from './CheckoutProcessor/CheckoutProcessor';
import BusinessCart from './BusinessCart/BusinessCart';
import history from 'library/helpers/history';

const BusinessOrderCheckout = ({ match, location }) => {
  return (
    <PageWrapper>
      <BusinessOrderCheckoutBody businessId={match.params?.businessId} slug={match.params?.slug} location={location} />
      <RedirectHomeWhenEmptyCart />
    </PageWrapper>
  );
};

const BusinessOrderCheckoutBody = ({ businessId, slug, location }) => {
  const url = `${process.env.REACT_APP_API_URL}/business-details/${businessId}`;
  const { data: business, isValidating } = useSWR(url);

  if (!business && isValidating) {
    return <Loader />;
  }

  if (!business || !get(business, "0.id", null)) {
    return (
      <Box flexBox flexDirection="column" alignItems="center" p={4} style={{paddingTop: "20vh"}}>
        <Text fontSize={[16, 20]} fontWeight={600} content="The store is not available at the moment. Please refresh or retry" mb={2} />
        <Button type="primary" onClick={() => history.push("/")}>BACK TO HOME</Button>
      </Box>
    )
  }

  if (!showBoomOrderNowButton(business[0])) {
    return (
      <Box flexBox flexDirection="column" alignItems="center" p={4} style={{paddingTop: "20vh"}}>
        <Text fontSize={[16, 20]} fontWeight={600} content="Oh! The store is closed at the moment. Please try placing order from different store." mb={2} />
        <Button type="primary" onClick={() => history.push("/")}>BACK TO HOME</Button>
      </Box>
    )
  }

  return (
    <>
      <Container className="business-checkout-container" >
        <Row gutter={0} style={{ paddingTop: 20 }}>
          <Col lg={16} xs={24} className="business-checkout-selector" >
            {location.state?.errorMessage && <Alert message={location.state?.errorMessage} type="error" showIcon />}
            <CheckoutProcessor business={business[0]} />
          </Col>

          <Col lg={8} xs={24}>
            <Sticky innerZ={9999} top={100}>
              <BusinessCart showDeliveryCharge coverHeight={0} />
            </Sticky>
          </Col>

        </Row>
      </Container>

      <ProceedToPay
        showPricing
        showFullButton
        btnTextCOD="PLACE ORDER"
        btnText="PAY NOW"
        nextPage="PAYMENT"
      />

    </>
  )
}

const RedirectHomeWhenEmptyCart = () => {
  const { state } = useBusinessOrderCart();
  if (get(state, "items", []).length === 0) {
    return <Redirect to="/" />
  }
  return <></>
}

export default BusinessOrderCheckout;
