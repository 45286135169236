import styled from "styled-components";

export const ForgotPasswordConfirmWrapper = styled.div`
  background: #f1f1f1;
  margin: 0 auto;
  min-height: 100vh;
  padding: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    margin: 30px 0;
    height: 24px;
  }

  .ant-alert {
    max-width: 400px;
    margin: 5px 0 15px;
    padding: 5px 10px;
  }
`;

export const VerificationBox = styled.div`
  background-color: #ffffff;
  padding: 30px;
  max-width: 350px;
`;


export const OtpWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  h3 {
    width: 100%;
    text-align: center;
  }
`;
export const FormWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .otp-input {
    width: 2.5rem !important;
    height: 2.5rem;
    &:focus-visible {
      outline-color: black;
    }
  }
  img {
    margin: 30px 0;
    height: 24px;
  }
  .signin_form {
    label {
      margin-bottom: 0;
    }
  }
`;

export const TextWrapper = styled.div`
  margin: 0 auto;
  display: flex;
  display: flex;
  justify-content: center;
  align-items: center;  
  flex-direction: column;

  button {
    width: 150px;
    margin-top: 10px;
  }
  img {
    margin: 30px 0;
    height: 24px;
  }
`;
