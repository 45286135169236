import React, { useRef, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Alert } from "antd";

import PageWrapper, { ContentWrapper } from "./PaymentProcessing.style";
import PayWithRazorpay from "components/PayWithRazorpay/PayWithRazorpay";
import { HOME_PAGE, PAYMENT_CALLBACK_PAGE, PAYMENT_PROCESSING_PAGE } from "settings/constant";
import Loader from "components/Loader/Loader";
import PayWithInstamojo from "components/PayWithInstamojo/PayWithInstamojo";
import { getSearchStringFromObject } from "library/helpers/url_handler";

export default () => {
  const history = useHistory();
  const location = useLocation();
  const razorpayBtnRef = useRef();
  const instamojoBtnRef = useRef();
  const [processing, setProcessing] = useState(false);

  const orderData = useRef({});

  // Hold success Props value because we will clear the state once order is created.
  const paymentFor = location.state?.paymentFor;

  useEffect(() => {
    if (!processing && location.state?.gateway) {
      if (location.state?.gateway === "RAZORPAY") {
        razorpayBtnRef.current.click();
      }
      if (location.state?.gateway === "INSTAMOJO") {
        instamojoBtnRef.current.click();
      }
    }
    else {
      history.push(HOME_PAGE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageWrapper>
      <ContentWrapper>
        {!processing && (
          <>
            <Loader />
            <Alert message="Please wait we process your payment. Please do not click back, close or refresh button." />
          </>
        )}
        <PayWithRazorpay
          btnRef={razorpayBtnRef}
          btnStyle={{ visibility: "hidden" }}
          onOrderCreated={(result) => {
            orderData.current = result;
            history.replace(PAYMENT_PROCESSING_PAGE, null);
            setProcessing(true);
          }}
          onModalDismissed={() => {
            if (location.state?.paymentProps?.errorPage) {
              history.replace(`${location.state?.paymentProps?.errorPage}`, { errorMessage: "We could not process your payment as you closed or cancelled the payment" })
            }
            else {
              history.replace(`${PAYMENT_CALLBACK_PAGE}${getSearchStringFromObject({
                gateway: "RAZORPAY",
                paymentFor,
                orderId: orderData.current?.data?.orderId || "",
                paymentStatus: "Failed"
              })}`)
            }
          }}
          callbackQueryParams={{
            callbackPath: PAYMENT_CALLBACK_PAGE,
            gateway: "RAZORPAY",
            paymentFor,
          }}
          {...(location.state?.paymentProps || {})}
        />
        <PayWithInstamojo
          btnRef={instamojoBtnRef}
          btnStyle={{ visibility: "hidden" }}
          onOrderCreated={(result) => {
            orderData.current = result;
            history.replace(PAYMENT_PROCESSING_PAGE, null);
            setProcessing(true);
          }}
          paymentFor={paymentFor}
          {...(location.state?.paymentProps || {})}
        />
      </ContentWrapper>
    </PageWrapper>
  );
};
