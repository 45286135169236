import styled from "styled-components";

export const OrderDetailsWrapper = styled.div`
  margin-bottom: 10px;
  .ant-card.ant-card-bordered {
    border-color: #E6E6E6;
  }
  .ant-divider.ant-divider-vertical {
    border-color: #E6E6E6;
    text-align: center;
    height: 100%;
  }
`;
