import React from "react";
import { get } from "lodash";
import { LazyLoadImage } from "react-lazy-load-image-component";

import SectionGrid from "components/SectionGrid/SectionGrid";
import Image from "components/UI/Image/Image";
import Box from "components/UI/Box/Box";
import Text from "components/UI/Text/Text";
import { useBaseData } from "context/BaseDataProvider";
import LinkTo from "components/UI/LinkTo/LinkTo";
import { getImageDimensions, responsive } from "../utils";

const PhotoGrid = ({ section }) => {
  const { getAssetUrl } = useBaseData();
  const configuration = get(section, "configuration", {});
  return (
    <SectionGrid
      data={section.components.map((item, index) => ({ ...item, id: index }))}
      limit={section.components.length}
      columnWidth={responsive(get(configuration, "columnWidth"), [0.5, 0.2])}
      renderComponent={(component) => (
        <LinkTo to={component.linkTo}>
          <Box
            flexBox
            flexDirection="column"
            justifyContent="center"
            p={responsive(get(configuration, "padding"), 0)}
          >
            <Image
              as={LazyLoadImage}
              alt=""
              width="100%"
              style={{
                aspectRatio: getImageDimensions(component.photo[0]?.meta)
                  .aspectRatio,
              }}
              src={getAssetUrl(component.photo[0]?.path)}
              p={responsive(get(configuration, "imagePadding"), 0)}
              borderRadius={responsive(get(configuration, "imageRadius"), 0)}
            />
            <Text
              textAlign="center"
              fontWeight={responsive(get(configuration, "fontWeight"), 300)}
              fontSize={responsive(get(configuration, "fontSize"), 14)}
              content={component.name}
              mt={1}
            />
          </Box>
        </LinkTo>
      )}
    />
  );
};

export default PhotoGrid;
