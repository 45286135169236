import styled from "styled-components";

const ProfileMenuWrapper = styled.div`
  .ant-menu {
    max-height: calc(100vh - 82px - 1px);
    overflow-y: scroll;

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    & {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
`;

export default ProfileMenuWrapper;
