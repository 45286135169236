import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";

const CheckoutProcessorWrapper = styled.div`
  .outer {
    // border-left: 3px solid ${themeGet("primary.0", "#008489")};
  }
`;

export default CheckoutProcessorWrapper;
