import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

const Wrapper = styled.div`
  margin-bottom: 24px;

  .field-label-wrapper {
    display: flex;
    flex-direction: row;
  }

  .field-label {
    display: block;
    color: #000;
    font-size: 14px;
    line-height: 15px;
    font-weight: 300;
    margin-bottom: 10px;
  }

  .field-tooltip {
    margin-left: 4px;
    margin-top: 2px;
    font-size: 12px;
    height: 12px;
    width: 12px;
    color: #9e9e9e
  }

  .ant-input-number-group-wrapper{
    width: 100%;
  }


  .ant-input,
  .ant-picker,
  .ant-input-number,
  .ant-input-password {
    width: 100%;
    min-height: 48px;
    border-radius: 3px;
    border-color: ${themeGet('border.3', '#E6E6E6')};
    &:focus,
    &.ant-input-focused,
    &.ant-picker-focused,
    &.ant-input-number-focused,
    &.ant-input-password-focused {
      box-shadow: 0 0 0 1px rgba(0, 132, 137, 0.2);
    }
  }

  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: ${themeGet('border.3', '#E6E6E6')};
    box-shadow: 0 0 0 1px rgba(0, 132, 137, 0.2);
  }


  .ant-input.ant-input-sm {
    min-height: 38px;
  }

  .ant-input-affix-wrapper {
    padding-top: 0;
    padding-bottom: 0;
  }

  .ant-slider {
    margin-top: 35px;
    &:hover {
      .ant-slider-track {
        background-color: ${themeGet('primary.0', '#E6E6E6')};
      }
      .ant-slider-handle:not(.ant-tooltip-open) {
        border-color: ${themeGet('primary.0', '#E6E6E6')};
      }
    } 
    .ant-slider-track {
      background-color: ${themeGet('primary.0', '#E6E6E6')};
    }
    .ant-slider-handle {
      border-color: ${themeGet('primary.0', '#E6E6E6')};
      &:focus {
        border-color: ${themeGet('primary.0', '#E6E6E6')};
        box-shadow: 0 0 0 1px rgba(0, 132, 137, 0.2);
      }
    }
  }

  .ant-checkbox-wrapper {
    .ant-checkbox {
      + span {
        color: ${themeGet('text.0', '#2C2C2C')};
      }
      .ant-checkbox-inner {
        border-radius: 0;
        border-color: ${themeGet('text.1', '#909090')};
      }
      &.ant-checkbox-checked {
        .ant-checkbox-inner {
          border-color: ${themeGet('primary.0', '#008489')};
          background-color: ${themeGet('primary.0', '#008489')};
        }
      }
    }
  }

  .ant-input-number {
    .ant-input-number-input {
      min-height: 46px;
    }
    &:hover {
      border-color: ${themeGet('border.3', '#E6E6E6')};
    }
  }
  .ant-input-number-handler:hover {
    .ant-input-number-handler-up-inner,
    .ant-input-number-handler-down-inner {
      color: ${themeGet('primary.0', '#008489')};
    }
  }

  .ant-picker {
    &:hover {
      border-color: ${themeGet('border.3', '#E6E6E6')};
    }
  }

  .ant-switch {
    min-width: 36px;
    height: 21px;
    &.ant-switch-checked {
      background-color: ${themeGet('primary.0', '#008489')};
    }
    &::after {
      width: 17px;
      height: 17px;
    }
    &:focus {
      box-shadow: none;
    }
    .ant-click-animating-node {
      display: none;
    }
  }
  
  .ant-select {
    width: 100%;
    &:focus {
      outline: none;
    }
    &.ant-select-single:not(.ant-select-customize-input) {
      .ant-select-selector {
        border-radius: 3px;
        border-color: ${themeGet('border.3', '#E6E6E6')};
        min-height: 48px;
        .ant-select-selection-search-input {
          min-height: 48px;
        }
        .ant-select-selection-item,
        .ant-select-selection-search,
        .ant-select-selection-placeholder {
          display: flex;
          align-items: center;
        }
        &:focus {
          outline: none;
        }
      }
    }


    &.ant-select-multiple {
      .ant-select-selector {
        border-radius: 3px;
        border-color: ${themeGet('border.3', '#E6E6E6')};
        min-height: 48px;
        .ant-select-selection-search-input {
          min-height: 48px;
        }
        .ant-select-selection-item,
        .ant-select-selection-search,
        .ant-select-selection-placeholder {
          display: flex;
          align-items: center;
        }
        &:focus {
          outline: none;
        }
      }
    }
    &:not(.ant-select-disabled):hover{
      .ant-select-selector {
        border-color: ${themeGet('border.3', '#E6E6E6')};
        box-shadow: 0 0 0 1px rgba(0, 132, 137, 0.2);
      }
    } 
    &.ant-select-focused {
      &:not(.ant-select-disabled).ant-select-multiple{
        .ant-select-selector {
          border-color: ${themeGet('border.3', '#E6E6E6')};
          box-shadow: 0 0 0 1px rgba(0, 132, 137, 0.2);
        }
      }
      &.ant-select-single:not(.ant-select-customize-input) {
        .ant-select-selector {
          box-shadow: 0 0 0 1px rgba(0, 132, 137, 0.2);
        }
      }
    }
  }

  &.has-error {
    .ant-input,
    .ant-input-number,
    .ant-input-password,
    .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      border-color: ${themeGet('error.0', '#F9503D')};
    }
    .feedback {
      color: ${themeGet('error.0', '#F9503D')};
    }
    .ant-input-password {
      .ant-input {
        border-color: transparent;
      }
    }
  }

  .drag_and_drop_uploader {
    & ~ ant-upload-list-item-name + .feedback {
      margin-top: 10px
    }
  }

  .ant-input-password {
    .ant-input {
      min-height: auto;
      border-radius: initial;
      border-color: transparent;
      &:focus {
        box-shadow: none;
      }
    }
  }

  .ant-radio-group-solid {
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      border-color: ${themeGet('primary.0', '#008489')};
      background-color: ${themeGet('primary.0', '#008489')};
      &::before {
        content: none;
      }
      &:hover {
        color: #fff;
        &::before {
          content: none;
        }
      }
    }
    .ant-radio-button-wrapper {
      &:hover {
        color: ${themeGet('primary.0', '#008489')};
      }
    }
  }
  .ant-input[disabled],
  .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    color: rgba(0, 0, 0, 0.5);
  }

  button[type='submit'] {
    border-radius: 3px;
    height: 47px;
    font-size: 15px;
    font-weight: 700;
    text-transform: capitalize;
    padding-left: 28px;
    padding-right: 28px;
    line-height: 1;
    border-color: ${themeGet('primary.0', '#008489')};
    background-color: ${themeGet('primary.0', '#008489')};

    &::after {
      content: none;
    }
    &:hover,
      &:focus {
        border-color: ${themeGet('secondary.0', '#F7350D')};
        background-color: ${themeGet('secondary.0', '#F7350D')};
      }

  }
`;

export default Wrapper;
