import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";

const FinalCheckoutPage = styled.div`
  padding-bottom: 100px;

  .ant-card {
    border-color: #f0f0f0;
    .ant-card-head {
      background: #f6f6f6;
    }
    &.is-active {
      border-width: 0 12 12px;
      .ant-card-head {
        background: #f0f0f0;
      }
    }
  }
  .ant-list {
    border-color: #f0f0f0;
    .ant-list-header {
      background: #f6f6f6;
      padding: 16px 24px;
    }
    &.is-active {
      border-width: 0 12 12px;
      .ant-list-header {
        background: #f0f0f0;
      }
    }
  }

  .ant-steps {
    .ant-steps-item-container {
      outline: none;
      display: flex;
      align-items: center;
    }

    .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon,
    .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
      color: ${themeGet("primary.0", "#008489")};
    }

    .ant-steps-item-finish
      > .ant-steps-item-container
      > .ant-steps-item-content
      > .ant-steps-item-title,
    .ant-steps-item-process
      > .ant-steps-item-container
      > .ant-steps-item-content
      > .ant-steps-item-title {
      font-weight: 600;
      color: ${themeGet("primary.0", "#008489")};
    }
    .ant-steps-item-finish
      > .ant-steps-item-container
      > .ant-steps-item-content
      > .ant-steps-item-title::after {
      background-color: ${themeGet("primary.0", "#008489")};
    }
  }
`;

export default FinalCheckoutPage;
