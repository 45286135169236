import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";

export const PriceArea = styled.div`
  color: ${themeGet("text.0", "#2C2C2C")};
  font-size: 12px;
  font-weight: 300;
  display: flex;
  align-items: center;
  & .discount {
    color: ${themeGet("primary.0", "#008489")};
  }
`;