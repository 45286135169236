import React, { useEffect } from "react";
import { Radio, Card } from "antd";
import { get } from "lodash";

import { useBusinessOrderCart } from "context/BusinessOrderCartProvider";
import Text from "components/UI/Text/Text";
import DeliveryDetails from "../DeliveryDetails/DeliveryDetails";
import PickupDetails from "../PickupDetails/PickupDetails";
import CheckoutProcessorWrapper from "./CheckoutProcessor.style";
import { isB2B } from "library/helpers/utils";

const CheckoutProcessor = ({ business }) => {
  const { state, setCheckoutOption, setPaymentOption } = useBusinessOrderCart();

  const availableCheckoutOptions = get(business, "checkoutOptions", []);
  const acceptsCOD = get(business, "acceptsCOD", "NO");
  const acceptsPickupCOD = get(business, "acceptsPickupCOD", "NO");

  useEffect(() => {
    if (availableCheckoutOptions.length > 0) {
      const checkoutOption = availableCheckoutOptions.includes(state?.checkoutOption) ? state?.checkoutOption : availableCheckoutOptions[0]
      if ((checkoutOption === "PICKUP" && acceptsPickupCOD !== "YES") || (checkoutOption === "DELIVERY" && acceptsCOD !== "YES")) {
        setPaymentOption("PREPAID");
      }
      setCheckoutOption(checkoutOption);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (shouldDisableCOD()) {
      setPaymentOption("PREPAID")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.checkoutOption]);

  const shouldDisableCOD = () => {
    if (state.checkoutOption === "PICKUP") {
      return acceptsPickupCOD !== "YES"
    }
    if (state.checkoutOption === "DELIVERY") {
      return acceptsCOD !== "YES"
    }
    return true
  }

  return (
    <CheckoutProcessorWrapper>
      <Card style={{ marginBottom: 10 }} className="outer">
        <Text mb={2} fontWeight={600} fontSize={[16, 16, 18]} content="How do you want this order?" />
        <Radio.Group onChange={(e) => setCheckoutOption(e.target.value)} value={state.checkoutOption}>
          <Radio value={"DELIVERY"} disabled={!availableCheckoutOptions.includes("DELIVERY")}>
            <Text content={isB2B ? "Store Delivery" : "Home Delivery"} />
            <Text color="red" content={availableCheckoutOptions.includes("DELIVERY") ? "" : "(Not Available)"} />
          </Radio>
          <Radio value={"PICKUP"} disabled={!availableCheckoutOptions.includes("PICKUP")}>
            <Text content="Self Pickup" />
            <Text color="red" content={availableCheckoutOptions.includes("PICKUP") ? "" : "(Not Available)"} />
          </Radio>
        </Radio.Group>
      </Card>

      <Card style={{ marginBottom: 10 }} className="outer">
        <Text mb={2} fontWeight={600} fontSize={[16, 16, 18]} content="How do you like to pay?" />
        <Radio.Group onChange={(e) => setPaymentOption(e.target.value)} value={state.paymentOption}>
          <Radio value={"COD"} disabled={shouldDisableCOD()}>
            <Text content={isB2B ? "Pay to Company" : "Cash on Delivery"} />
            <Text color="red" content={shouldDisableCOD() ? "(Not Available)" : ""} />
          </Radio>
          <Radio value={"PREPAID"}>
            <Text content="Pay Online" />
          </Radio>
        </Radio.Group>
      </Card>

      {state.checkoutOption === "DELIVERY" ? (
        <DeliveryDetails business={business} />
      ) : null}
      {state.checkoutOption === "PICKUP" ? (
        <PickupDetails business={business} />
      ) : null}
    </CheckoutProcessorWrapper>
  );
};

export default CheckoutProcessor;
