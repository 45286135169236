import React, { useState, useEffect, useContext } from 'react';
import { fetcher } from 'library/hooks/useDataApi';
import { removeState, saveState } from 'library/helpers/localStorage';
import { isB2B } from 'library/helpers/utils';

export const AuthContext = React.createContext();
const cookieName = isB2B ? "_bt" : "_at"

const AuthProvider = (props) => {
  const [loading, setLoading] = useState(true);
  const [loggedIn, setLoggedIn] = useState(false);
  const [user, setUser] = useState({});
  const [errors, setErrors] = useState([]);
  const [maintenance, setMaintenance] = useState(false);

  useEffect(() => {
    reAuthenticate()
  }, [])

  const signIn = async (params) => {
    try {
      const result = await fetcher(`${process.env.REACT_APP_API_URL}/sign-in`, "POST", {}, JSON.stringify(params))
      if (result.errors) {
        setErrors(result.errors);
      }
      else {
        saveState(cookieName, result.auth_token);
        setUser(result.user)
        setLoggedIn(true);
      }
    }
    catch (e) {
      // Handle errors here ex: Timeout or server
    }
  };

  const onSignupConfirmed = (auth_token, user) => {
    saveState(cookieName, auth_token);
    setUser(user)
    setLoggedIn(true);
  }

  const onForgotPasswordConfirmed = (auth_token, user) => {
    saveState(cookieName, auth_token);
    setUser(user)
    setLoggedIn(true);
  }

  const reAuthenticate = async () => {
    try {
      const result = await fetcher(`${process.env.REACT_APP_API_URL}/me`)
      if (!result.errors) {
        setUser(result.user)
        setLoggedIn(true);
      }
      setLoading(false);
    }
    catch (e) {
      // Handle errors here ex: Timeout or server or fetch
      console.log(e);
      setLoading(false);
      if (e.status === 500) {
        setMaintenance(true);
      }
    }
  };

  const logOut = () => {
    removeState(cookieName);
    setUser(null);
    setLoggedIn(false);
    setLoading(false);
  };

  const resetErrors = () => {
    setErrors([])
  }

  const hasAnyRole = (roles = []) => {
    let hasRole = false;
    for (const role of roles) {
      if (user?.roles.includes(role)) {
        hasRole = true;
      }
    }
    return hasRole;
  }

  const hasAnyPermission = (permissions = []) => {
    let hasPermission = false;
    for (const permission of permissions) {
      if (user?.permissions.includes(permission)) {
        hasPermission = true;
      }
    }
    return hasPermission;
  }

  return (
    <AuthContext.Provider
      value={{
        setUser,
        user,
        loggedIn,
        errors,
        loading,
        maintenance,
        resetErrors,
        onSignupConfirmed,
        onForgotPasswordConfirmed,
        logOut,
        signIn,
        reAuthenticate,
        hasAnyRole,
        hasAnyPermission,
      }}
    >
      <>{props.children}</>
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

export default AuthProvider;
