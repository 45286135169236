import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";

const Wrapper = styled.div`
  margin-bottom: 15px;

  &.form-error {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .heading {
      margin-top: 10px;
      font-weight: 600;
      color: ${themeGet("error.0", "#F9503D")};
    }

    .arrow {
      display: flex;
      justify-content: center;
      width: 100%;
    }

    .feedback-container {
      padding-left: 20px;
    }

    .feedback {
      color: ${themeGet("error.0", "#F9503D")};
      list-style-type: disc;
    }
  }
`;

export default Wrapper;
