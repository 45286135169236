import React, { useEffect, useState } from "react";
import { Col, Collapse, InputNumber, Row, Slider } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { get } from "lodash";

import Box from "components/UI/Box/Box";
import useDebounce from "library/hooks/useDebounce";
import Text from "components/UI/Text/Text";
import FormControl from "components/UI/FormControl/FormControl";

const { Panel } = Collapse;

const FilterDiscount = ({ label, value, onChange }) => {
  return (
    <Box bg="#f6f6f6" width="100%" mb={2}>
      <Collapse
        defaultActiveKey={value ? "1" : null}
        accordion={true}
        expandIconPosition="end"
        expandIcon={() => <DownOutlined />}
      >
        <Panel header={label} key="1">
          <FilterDiscountPanel value={value} onChange={onChange} />
        </Panel>
      </Collapse>
    </Box>
  );
};

export const FilterDiscountPanel = ({ value, onChange }) => {
  const MAX_DISCOUNT = 100;
  const [discountRange, setDiscountRange] = useState([
    Number(get(value, "0", 0)),
    Number(get(value, "1", MAX_DISCOUNT)),
  ]);
  const debouncePriceRange = useDebounce(discountRange.join(","), 1000);

  useEffect(() => {
    if (discountRange[0] === 0 && discountRange[1] === MAX_DISCOUNT) {
      onChange([]);
    } else {
      onChange(discountRange);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncePriceRange]);

  return (
    <>
      <Slider
        range
        min={0}
        max={MAX_DISCOUNT}
        value={discountRange}
        onChange={(value) => setDiscountRange(value)}
        tooltip={{
          formatter: (value) => `${value}%`,
        }}
        marks={{
          0: <Text fontSize={[10]} content="0%" />,
          [MAX_DISCOUNT]: <Text color="#f50" fontWeight={600} fontSize={[10]} content="100%" />,
        }}
      />
      <Row gutter={24}>
        <Col xs={12}>
          <FormControl>
            <InputNumber
              style={{ width: "100%", height: 40 }}
              min={0}
              max={discountRange[1]}
              value={discountRange[0]}
              formatter={(value) => `${value}%`}
              parser={(value) => value.replace("%", "")}
              onChange={(value) => setDiscountRange([value, discountRange[1]])}
            />
          </FormControl>
        </Col>
        <Col xs={12}>
          <FormControl>
            <InputNumber
              style={{ width: "100%", height: 40 }}
              min={discountRange[0]}
              max={MAX_DISCOUNT}
              value={discountRange[1]}
              formatter={(value) => `${value}%`}
              parser={(value) => value.replace("%", "")}
              onChange={(value) => setDiscountRange([discountRange[0], value])}
            />
          </FormControl>
        </Col>
      </Row>
    </>
  );
};

export default FilterDiscount;
