import { useState, useReducer, useEffect } from 'react';
import { addQueryToUrl } from 'library/helpers/url_handler';
import { loadState, removeState } from 'library/helpers/localStorage';
import { LOGIN_PAGE } from 'settings/constant';
import { isB2B } from 'library/helpers/utils';

const cookieName = isB2B ? "_bt" : "_at"

export async function fetcher(url, method = 'GET', headers = {}, body = JSON.stringify({})) {
  const authToken = loadState(cookieName);
  let options = {
    method,
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
      ...(authToken ? { Authorization: `Bearer ${authToken}` } : {}),
      ...headers
    },
  };
  if (method === 'POST' || method === 'PUT') options = { ...options, body };

  return fetch(url, options)
    .then(async (res) => {
      if (!res.ok) {
        // It might be not a right way to do.
        if (!["/sign-in"].includes(window.location.pathname) && ![`${process.env.REACT_APP_API_URL}/me`].includes(url) && res.status === 401) {
          removeState(cookieName)
          window.location.replace(`${window.location.origin}${LOGIN_PAGE}?sessionExpired=true`)
          return
        }
        return Promise.reject({
          status: res.status,
          info: await res
            .clone()
            .json()
            .catch(() => res.text()),
        })
      }
      return Promise.resolve(
        await res
          .clone()
          .json()
          .catch(() => res.text()),
      )
    })
    .catch((error) => Promise.reject(error))
}

function dataFetchReducer(state, action) {
  switch (action.type) {
    case 'FETCH_INIT':
      return {
        ...state,
        loading: true,
        error: false,
      };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        data: Array.isArray(action.payload) ? [...state.data, ...action.payload] : state.data,
        loading: false,
        error: false,
      };
    case 'FETCH_ERROR':
      return {
        ...state,
        loading: false,
        error: true,
      };
    case 'FETCH_FAILURE':
      return {
        ...state,
        loading: false,
        error: true,
      };
    case 'UPDATE_DATA':
      return {
        ...state,
        data: action.payload,
      };
    default:
      throw new Error();
  }
}

const useDataApi = (initialUrl, initialLimit = 20, lazy = false) => {
  const [options, setOptions] = useState({ url: initialUrl, cursor: { offset: 0, limit: initialLimit }, resetKey: "" })
  const [showLoadMore, setShowLoadMore] = useState(true);
  const [lazyFetch, setLazyFetch] = useState(lazy);

  const [state, dispatch] = useReducer(dataFetchReducer, {
    loading: lazy ? false : true,
    error: false,
    data: [],
  });

  useEffect(() => {
    let didCancel = false;

    const fetchData = async () => {
      dispatch({ type: 'FETCH_INIT' });

      try {
        const fetchUrl = addQueryToUrl(options.url, { limit: options.cursor.limit, skip: (options.cursor.offset * options.cursor.limit) })
        const result = await fetcher(fetchUrl);
        if (result.errors) {
          dispatch({ type: 'FETCH_ERROR' });
        }
        else if (!didCancel) {
          if (!result.length || result.length < options.cursor.limit) {
            setShowLoadMore(false);
          }
          dispatch({ type: 'FETCH_SUCCESS', payload: result });
        }
      } catch (error) {
        if (!didCancel) {
          dispatch({ type: 'FETCH_FAILURE' });
        }
      }
    };
    if (!lazyFetch) {
      fetchData();
    }
    else {
      dispatch({ type: 'FETCH_SUCCESS', payload: [] });
      setLazyFetch(false);
    }
    return () => {
      didCancel = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options.url, options.cursor.limit, options.cursor.offset, options.resetKey]);

  const loadMoreData = () => {
    setOptions(options => ({ ...options, cursor: { ...options.cursor, offset: options.cursor.offset + 1 } }));
  }

  const doFetch = (newUrl, resetKey = "") => {
    dispatch({ type: 'UPDATE_DATA', payload: [] });
    setOptions({ url: newUrl, cursor: { offset: 0, limit: initialLimit }, resetKey });
    setShowLoadMore(true);
  };

  const updateData = (newData) => {
    dispatch({ type: 'UPDATE_DATA', payload: newData });
  }

  return { ...state, limit: initialLimit, showLoadMore, loadMoreData, doFetch, dispatch, updateData };
};

export default useDataApi;
