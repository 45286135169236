import React, { useState } from "react";
import { List, Tag, Radio, Drawer, Col, Row } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import useWindowSize from "library/hooks/useWindowSize";
import Button from "components/UI/Button/Button";
import Text from "components/UI/Text/Text";
import Box from "components/UI/Box/Box";
import UpsertAddressForm from "components/AddressForm/UpsertAddressForm";

const SelectAddress = ({ address = [], addressId, onChangeAddressId }) => {
  const defaultAddressCount = 3;
  const { width } = useWindowSize();
  const [showAllAddress, setShowAllAddress] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [currentAddress, setCurrentAddress] = useState({});

  const handleOpenDrawer = (record) => {
    setCurrentAddress(record);
    setShowDrawer(true);
  };

  const onClose = () => {
    setCurrentAddress({});
    setShowDrawer(false);
  };

  const onSubmitSuccess = () => {
    setCurrentAddress({});
    setShowDrawer(false);
  };

  const onAddNewAddress = () => {
    setCurrentAddress({});
    setShowDrawer(true);
  };

  return (
    <>
      <Row>
        <Col xs={24}>
          <List
            bordered
            className="is-active"
            dataSource={address.slice(
              0,
              showAllAddress ? 1000 : defaultAddressCount
            )}
            header={
              <Box
                flexBox
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Text
                  fontSize={["14px", "14px", "14px", "16px"]}
                  fontWeight={600}
                  content={`Delivery address`}
                />
                <Button onClick={onAddNewAddress} type="link" shape="round">
                  <PlusOutlined />
                  <Text
                    fontSize={["14px", "14px", "14px", "16px"]}
                    fontWeight="600"
                    as="span"
                    content="Add new address"
                    style={{ textDecoration: "underline" }}
                  />
                </Button>
              </Box>
            }
            footer={
              address.length > defaultAddressCount ? (
                <Box
                  flexBox
                  flexDirection="row"
                  justifyContent="center"
                  alignItems="center"
                  fontSize={["14px", "14px", "14px", "16px"]}
                >
                  <Button
                    type="link"
                    onClick={() =>
                      setShowAllAddress((showAllAddress) => !showAllAddress)
                    }
                  >
                    {showAllAddress ? "Collapse Address" : "Load All Address"}
                  </Button>
                </Box>
              ) : null
            }
            locale={{ emptyText: "Please add a new address" }}
            renderItem={(item) => (
              <List.Item>
                <Box flexBox flex={1} flexDirection="row">
                  <Box flexBox alignItems="center" height="22px">
                    <Radio
                      value={item.id}
                      onChange={(e) => onChangeAddressId(e.target.value)}
                      checked={addressId === item.id}
                    />
                  </Box>
                  <Box flexBox flex={1} justifyContent="space-between">
                    <Box flexBox flex={1} flexDirection="column">
                      <Box flexBox>
                        <Text
                          fontSize={["12px", "12px", "14px", "14px"]}
                          fontWeight={600}
                          mr={2}
                          content={item.name}
                        />
                        <Text
                          fontSize={["12px", "12px", "14px", "14px"]}
                          fontWeight={600}
                          content={item.mobileNo}
                          mr={2}
                        />
                        {width > 767 && item.addressType && (
                          <Box>
                            <Tag color="orange">{item.addressType}</Tag>
                          </Box>
                        )}
                      </Box>
                      <Box flexBox flexDirection="column">
                        <Text
                          fontSize={["12px", "12px", "14px", "14px"]}
                          content={`${item.address}, ${item.city}, ${item.state}, ${item.pin}`}
                        />
                        <Text
                          fontSize={["12px", "12px", "14px", "14px"]}
                          content={item.landmark}
                        />
                      </Box>
                      <Box
                        mt={1}
                        mb={2}
                        flexBox
                        // flexDirection={deliverable === "NO" ? "column" : "row"}
                      >
                        {/* {selectedAddressId === item.id && (
                          <Box mr={2} mt={1}>
                            {deliverable === "LOADING" && (
                              <Box flexBox flexDirection="Row">
                                <Spin
                                  indicator={
                                    <LoadingOutlined
                                      style={{ fontSize: 24 }}
                                      spin
                                    />
                                  }
                                />
                                <Text ml={2} fontWeight="600" content="Checking Delivery" />
                              </Box>
                            )}
                            {deliverable === "NO" && (
                              <Text
                                fontSize="18px"
                                fontWeight="600"
                                color="#ff6161"
                                content="Not deliverable to this location"
                              />
                            )}
                          </Box>
                        )} */}
                        {width <= 767 && (
                          <Box mt={1}>
                            <Button
                              type="dashed"
                              onClick={() => handleOpenDrawer(item)}
                            >
                              <Text fontWeight="600" content="Edit" />
                            </Button>
                          </Box>
                        )}
                      </Box>
                    </Box>
                    {width > 767 && (
                      <Box flexBox>
                        <Button
                          type="dashed"
                          onClick={() => handleOpenDrawer(item)}
                        >
                          <Text fontWeight="600" content="Edit" />
                        </Button>
                      </Box>
                    )}
                  </Box>
                </Box>
              </List.Item>
            )}
          />
        </Col>
      </Row>
      <Drawer
        title={currentAddress.id ? "Update Address" : "Add Address"}
        width={width > 767 ? "50vw" : "100vw"}
        destroyOnClose
        onClose={onClose}
        open={showDrawer}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <UpsertAddressForm
          showMap={false}
          mode={currentAddress.id ? "UPDATE" : "CREATE"}
          onSubmitSuccess={onSubmitSuccess}
          currentAddress={currentAddress}
        />
      </Drawer>
    </>
  );
};

export default SelectAddress;
