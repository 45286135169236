import React from "react";
import { Row, Col, List, Modal } from "antd";
import { DeleteOutlined, ShoppingCartOutlined } from "@ant-design/icons";
import { Link, generatePath } from "react-router-dom";
import { round } from "lodash";

import Button from "components/UI/Button/Button";
import Image from "components/UI/Image/Image";
import Text from "components/UI/Text/Text";
import Box from "components/UI/Box/Box";
import TextLink from "components/UI/TextLink/TextLink";
import InputIncDec from "components/UI/InputIncDec/InputIncDec";
import ReturnExchange from "components/ReturnExchange/ReturnExchange";
import { useBaseData } from "context/BaseDataProvider";
import { formatCurrency } from "library/helpers/utils";
import { STORE_PRODUCT_PAGE } from "settings/constant";
import { CartListWrapper } from "./StoreCartList.style";

const StoreCartList = ({ cart, addOrRemoveInCart }) => {
  const { getAssetUrl } = useBaseData();

  if (!cart.length) {
    return (
      <Box
        flexBox
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        mt="20vh"
      >
        <ShoppingCartOutlined style={{ fontSize: 40 }} />
        <Text fontWeight="600" fontSize="18" content="Your cart is empty!" />
        <Text
          fontWeight="400"
          fontSize="16"
          content="Explore our wide selection and find something you like"
        />
      </Box>
    );
  }
  return (
    <CartListWrapper>
      <List
        dataSource={cart}
        header={null}
        renderItem={(item) => {
          const { productId, variantId, quantity, meta } = item;
          const variant = meta.variants.find(
            (variant) => variant.id === variantId
          );
          const isLowInStock = variant.stockQuantity < 5;
          const isOutOfStock = variant.stockQuantity === 0;
          const isPartialOutOfStock = quantity > variant.stockQuantity;
          const link = generatePath(STORE_PRODUCT_PAGE, {
            productId: productId,
            variantId: variantId,
          });
          return (
            <List.Item>
              <Box flexBox flex={1} flexDirection="row">
                <Box
                  flexBox
                  flex={1}
                  flexDirection="row"
                  justifyContent="center"
                  alignItems="flex-start"
                >
                  <Row>
                    <Col xs={24} sm={16}>
                      <Box>
                        <Link
                          to={link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Image
                            src={getAssetUrl(meta?.photos[0].path)}
                            alt={meta.productName}
                            width="100px"
                            m={"0 0 8px"}
                          />
                        </Link>
                        <Button
                          size="small"
                          fullWidth
                          icon={<DeleteOutlined />}
                          wrapperStyles={{ width: 100 }}
                          style={{
                            color: "indianred",
                            borderColor: "indianred",
                          }}
                          onClick={() => {
                            Modal.confirm({
                              title: "Remove Item",
                              okText: "Remove",
                              content: (
                                <div>
                                  Do you want to remove{" "}
                                  <b>{meta.productName}</b> from cart ?
                                </div>
                              ),
                              onOk: () =>
                                addOrRemoveInCart(
                                  productId,
                                  variantId,
                                  -quantity
                                ),
                            });
                          }}
                        >
                          Remove
                        </Button>
                      </Box>
                      <Box
                        flexBox
                        flex={1}
                        flexDirection="column"
                        justifyContent="center"
                        ml={3}
                        mr={3}
                      >
                        <Text
                          className="brand-name"
                          fontWeight="600"
                          content={meta.brand.brandName}
                        />
                        <TextLink
                          className="product-name"
                          fontWeight="400"
                          to={link}
                          target="_blank"
                          rel="noopener noreferrer"
                          content={[
                            `${meta.productName}`,
                            variant.size !== "Default"
                              ? `(Size : ${variant.size})`
                              : "",
                          ]
                            .filter(Boolean)
                            .join(" ")}
                        />
                        <Text
                          className="sold-by"
                          fontWeight="300"
                          fontSize="12"
                          content={`Sold By: ${meta.seller?.name}`}
                        />
                        <Box
                          flexBox
                          flexDirection="row"
                          justifyContent="flex-start"
                        >
                          <Text
                            fontSize={14}
                            fontWeight={700}
                            as="span"
                            content={formatCurrency(
                              variant.sellingPrice * quantity,
                              0,
                              true
                            )}
                          />
                          {variant.maximumPrice !== variant.sellingPrice && (
                            <>
                              <Text
                                color="gray"
                                as="span"
                                content={formatCurrency(
                                  variant.maximumPrice * quantity,
                                  0,
                                  true
                                )}
                                pl={1}
                                pr={2}
                                style={{ textDecoration: "line-through" }}
                              />
                              <Text
                                className="discount"
                                as="span"
                                color="indianred"
                                content={`(${round(
                                  variant.discountPercent
                                )}% OFF)`}
                                pl={1}
                              />
                            </>
                          )}
                        </Box>
                        <Box
                          flexBox
                          flexDirection="row"
                          alignItems="center"
                          mt={1}
                        >
                          <ReturnExchange
                            returnDays={meta.productType.returnDays}
                            exchangeDays={meta.productType.exchangeDays}
                          />
                        </Box>
                      </Box>
                    </Col>
                    <Col xs={24} sm={8}>
                      <Box
                        flexBox
                        flex={1}
                        flexDirection="column"
                        alignItems="flex-end"
                        justifyContent="center"
                      >
                        <InputIncDec
                          value={quantity}
                          disablePlus={quantity >= variant.stockQuantity}
                          disableMinus={quantity <= 1}
                          decrement={() =>
                            addOrRemoveInCart(productId, variantId, -1)
                          }
                          increment={() =>
                            addOrRemoveInCart(productId, variantId, 1)
                          }
                        />
                        {
                          <Text
                            fontWeight={300}
                            fontSize="12px"
                            color={
                              isOutOfStock || isPartialOutOfStock
                                ? "indianred"
                                : "black"
                            }
                            content={
                              isOutOfStock
                                ? "Out of stock"
                                : `Only ${variant.stockQuantity} in stock`
                            }
                            style={{
                              visibility:
                                isOutOfStock ||
                                isPartialOutOfStock ||
                                isLowInStock
                                  ? "visible"
                                  : "hidden",
                            }}
                          />
                        }

                        {/* <Box
                          flexBox
                          flex={1}
                          flexDirection="column"
                          alignItems="flex-end"
                        >
                          
                          <Box ml={3}>
                            {quantity <= variant.stockQuantity ? (
                              <Typography.Text className="delivery-date">
                                {" "}
                                Delivery in 7-10 days
                              </Typography.Text>
                            ) : (
                              <Typography.Text className="out-of-stock">
                                {quantity === 1
                                  ? "Out of Stock"
                                  : `Only ${variant.stockQuantity} in stock`}
                              </Typography.Text>
                            )}
                          </Box>
                        </Box> */}
                      </Box>
                    </Col>
                  </Row>
                </Box>
              </Box>
            </List.Item>
          );
        }}
      />
    </CartListWrapper>
  );
};

export default StoreCartList;
