import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";

const PageWrapper = styled.div`
  padding-bottom: 90px;

  .business-details-body {
    margin-top: 30px;
    @media only screen and (max-width: 480px) {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }

  .business-products,
  .business-checkout-selector {
    .ant-alert {
      margin-bottom: 10px
    }
    @media only screen and (min-width: 992px) {
      padding-right: 24px;
    }
  }

  .cart-list-drawer-button {
    @media only screen and (min-width: 992px) {
      display: none !important;
    }
  }

  .back-to-categories-container {
    @media only screen and (max-width: 767px) {
      width: 100%;
      padding: 0 15px;
    }
  }
`;

export const CategoryMenuWrapper = styled.div`
  .cover-description-container {
    padding-bottom: 20px;
  }
  .categories-list {
    .ant-card-type-inner .ant-card-body {
      padding: 10px 20px;
      @media only screen and (max-width: 480px) {
        width: 100%;
        padding: 10px;
      }
    }
  }
  .category-card {
    border-radius: 20px;

    @media only screen and (max-width: 480px) {
      border-radius: 10px;
    }
    .category-card-cover {
      background: linear-gradient(
        to right,
        #f46b45,
        #eea849
      ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
      border-radius: 20px;
      height: 100px;

      @media only screen and (max-width: 480px) {
        height: 75px;
        border-radius: 10px;
      }
    }
  }
`;

export const LocationMeta = styled.div`
  color: ${themeGet("text.1", "#909090")};
  font-size: 13px;
  font-weight: 400;
`;

export const Title = styled.h2`
  color: ${themeGet("text.0", "#2C2C2C")};
  font-size: 25px;
  line-height: 34px;
  font-weight: 700;
  margin: 0 0 4px;
`;

export const RatingMeta = styled.div`
  display: flex;
  align-items: center;
  color: ${themeGet("color.1", "#FFFFFF")};
  font-size: 18px;
  margin-top: 10px;
  @media only screen and (max-width: 767px) {
    font-size: 13px;
  }

  i {
    color: ${themeGet("primary.0", "#008489")};
  }

  svg {
    fill: ${themeGet("primary.0", "#008489")};
  }

  strong {
    font-weight: 700;
    margin-left: 8px;
    margin-top: -2px;
  }
`;

export const Text = styled.p`
  color: ${themeGet("text.0", "#2C2C2C")};
  font-size: 15px;
  line-height: 24px;
  font-weight: 400;
  margin: 0 0 30px;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const TextButton = styled.div`
  margin: 30px 0 0;
  @media only screen and (max-width: 767px) {
    margin-top: 19px;
  }
`;

export const TobBarWrapper = styled.div`
  .sticky-outer-wrapper {
    .sticky-inner-wrapper {
      background-color: ${themeGet("color.1", "#ffffff")};

      .scrollbar {
        box-shadow: none;
        border-top: 1px solid ${themeGet("border.3", "#E6E6E6")};
        border-bottom: 1px solid ${themeGet("border.3", "#E6E6E6")};

        .scrollbar_left {
          margin-right: 25px;
          padding-left: 25px;

          a {
            color: ${themeGet("text.0", "#2C2C2C")};
            font-size: 15px;
            padding: 28px 20px;
            text-transform: capitalize;
            transition: color 0.2s ease-in-out;

            &:first-child {
              padding-left: 0;
            }

            @media only screen and (max-width: 1200px) {
              padding: 18px 20px;
            }

            &:hover {
              color: ${themeGet("primary.0", "#008489")};
            }

            &.active {
              font-weight: 700;
              color: ${themeGet("primary.0", "#008489")};
              border-bottom: 3px solid ${themeGet("primary.0", "#008489")};
            }
          }
        }

        .scrollbar_right {
          padding-right: 25px;

          button,
          .ant-btn {
            @media only screen and (max-width: 767px) {
              min-width: 50px;
              svg {
                margin-right: 0;
              }
              > span {
                display: none;
              }
            }
          }
        }
      }
    }

    &.isSticky {
      .sticky-inner-wrapper {
        > div {
          border: 0;
          box-shadow: 0 2px 5px -2px rgba(0, 0, 0, 0.16);
        }
      }
    }
  }
`;

export const SearchWrapper = styled.div`
  border-left: 1px solid ${themeGet("border.3", "#E6E6E6")};
  border-right: 1px solid ${themeGet("border.3", "#E6E6E6")};
  padding: 10px 20px;
  background: #f6f6f6;
  @media only screen and (max-width: 991px) {
    border: none;
  }
  .ant-input {
    width: 100%;
    border-radius: 3px;
    border-color: ${themeGet("border.3", "#E6E6E6")};

    &:hover,
    &:focus {
      &
        + .ant-input-group-addon
        .ant-input-search-button:not(.ant-btn-primary) {
        border-color: ${themeGet("border.3", "#E6E6E6")};
      }
    }
  }
`;

export const ButtonGroup = styled.div`
  button,
  button.ant-btn {
    margin: 0 5px;
    height: 37px;
    min-width: 90px;
    padding: 0 5px;
    border: 1px solid ${themeGet("border.3", "#E6E6E6")};
    color: ${themeGet("text.0", "#2C2C2C")};
    font-size: 15px;
    font-weight: 700;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.2s ease;
    svg {
      width: 18.309px;
      height: 15.537px;
      margin-right: 10px;
      path {
        fill: transparent;
        stroke: ${themeGet("text.0", "#2C2C2C")};
        stroke-width: 1.5px;
      }
    }

    &.active {
      svg {
        path {
          stroke: #FC5C63;
          fill: #FC5C63;
        }
      }
    }

    &:focus {
      outline: none;
    }
    &:hover {
      background-color: ${themeGet("color.2", "#F7F7F7")};
    }
    &:after {
      display: none;
    }

    &:first-child {
      margin-left: 0;
      svg {
        position: relative;
        top: 1px;
        path {
          stroke-width: 1.8px;
        }
      }
    }

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const CoverImage = styled.div`
  background-color: ${themeGet("secondary.0", "#ffffff")};
  background-position: center center;
  background-size: cover;
  height: ${(props) => (props.height ? props.height : 180)}px;
  position: relative;

  @media only screen and (max-width: 767px) {
    height: ${(props) => (props.height ? props.height : 120)}px;
    border: none;
  }

  .image_gallery_button {
    background: ${themeGet("color.1", "#ffffff")};
    border-radius: 3px;
    font-size: 15px;
    font-weight: 700;
    color: #2c2c2c;
    border: 0;
    height: 37px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);

    &:hover,
    &:focus {
      background: ${themeGet("color.2", "#F7F7F7")};
      color: ${themeGet("text.0", "#2C2C2C")};
    }
  }
`;

export const CoverImageLayer = styled.div`
  background-color: rgba(47, 38, 57, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  .cover-description-container {
    flex: 1;
  }
`;

export const DescriptionWrapper = styled.div``;

export const ProductImage = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 3px;
  overflow: hidden;
  border: 2px solid ${themeGet("color.1", "#ffffff")};
  box-shadow: 0 3px 6px ${themeGet("boxShadow.1", "rgba(26, 68, 116, 0.16)")};
  @media only screen and (max-width: 480px) {
    width: 50px;
    height: 50px;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const SearchDropDownContainer = styled.div`
    padding: 10px 0;
    border: 1px solid #f1f1f1;
    background: #f6f6f6;
`;

export default PageWrapper;
