import styled from 'styled-components';

const PageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 890px;
  padding: 0 25px;
  margin: 60px auto;

  img {
    max-width: 560px;
    @media only screen and (min-width: 1901px) {
      max-width: 100%;
    }
    @media only screen and (max-width: 667px) {
      max-width: 100%;
    }
  }
`;

export const ContentWrapper = styled.div``;

export default PageWrapper;
