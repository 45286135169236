import React from "react";
import { Link } from "react-router-dom";
import Logo from "components/UI/Logo/Logo";
import { LOGIN_PAGE } from "settings/constant";
import { useBaseData } from "context/BaseDataProvider";
import ForgotPasswordForm from "./ForgotPasswordForm";
import authPageImage from 'assets/images/sign-in.svg';
import Wrapper, {
  Text,
  FormWrapper,
  BannerWrapper,
  BannerImageWrapper,
  ImgQuote,
} from "../Auth.style";

export default function ForgotPassWord() {
  const { preferences: { fullLogoUrl } } = useBaseData()
  return (
    <Wrapper>
      <FormWrapper>
        <Logo withLink linkTo="/" src={fullLogoUrl} />
        <ForgotPasswordForm />
        <Text>
          Remember your password! &nbsp;
          <Link to={LOGIN_PAGE}>Login</Link>
        </Text>
      </FormWrapper>
      <BannerWrapper>
        <BannerImageWrapper>
          <img src={authPageImage} alt="" />
          <ImgQuote>
            "Networking is an investment in your business. It takes time and when done correctly can yield great results for years to come."
          </ImgQuote>
        </BannerImageWrapper>
      </BannerWrapper>
    </Wrapper>
  );
}
