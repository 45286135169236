import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { Input, Button, Alert, InputNumber } from "antd";
import FormControl from "components/UI/FormControl/FormControl";

import FormError from "components/UI/FormError/FormError";
import { fetcher } from "library/hooks/useDataApi";
import { FORGET_PASSWORD_CONFIRM_PAGE } from "settings/constant";
import { addQueryToUrl } from "library/helpers/url_handler";

const ForgotPasswordForm = () => {
  const history = useHistory();
  const [serverErrors, setServerErrors] = useState([]);
  const [message, setMessage] = useState("");
  const { control, errors, watch, handleSubmit } = useForm({
    mode: "onSubmit",
  });
  const newPassword = watch("newPassword");
  const confirmPassword = watch("confirmPassword");

  const onSubmit = async (data) => {
    setMessage("");
    setServerErrors([]);
    try {
      const result = await fetcher(
        `${process.env.REACT_APP_API_URL}/forgot-password`,
        "POST",
        {},
        JSON.stringify(data)
      );
      if (result.errors) {
        setServerErrors(result.errors);
      } else {
        history.push(
          addQueryToUrl(FORGET_PASSWORD_CONFIRM_PAGE, {
            otpId: result.otpId,
            firstName: result.user.firstName,
            mobile: data.mobile,
          })
        );
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <form className="forgot_password_form" onSubmit={handleSubmit(onSubmit)}>
        <FormControl
          label="Registered Mobile No"
          htmlFor="mobile"
          error={
            errors.mobile && (
              <>
                {errors.mobile?.type === "required" && (
                  <span>This field is required!</span>
                )}
                {errors.mobile?.type === "pattern" && (
                  <span>Please enter your valid 10 digit mobile number!</span>
                )}
              </>
            )
          }
        >
          <Controller
            as={
              <InputNumber
                addonBefore="+91"
                stringMode={true}
                controls={false}
                precision={0}
              />
            }
            type="mobile"
            id="mobile"
            name="mobile"
            placeholder="Enter your mobile number associated to account"
            defaultValue=""
            control={control}
            rules={{
              required: true,
              pattern: /^[0-9]{10}$/,
            }}
          />
        </FormControl>
        <FormControl
          label="New Password"
          htmlFor="newPassword"
          error={
            errors.newPassword && (
              <>
                {errors.newPassword?.type === "required" && (
                  <span>This field is required!</span>
                )}
                {errors.newPassword?.type === "minLength" && (
                  <span>Password must be at lest 6 characters!</span>
                )}
                {errors.newPassword?.type === "maxLength" && (
                  <span>Password must not be longer than 20 characters!</span>
                )}
              </>
            )
          }
        >
          <Controller
            as={<Input.Password />}
            defaultValue=""
            control={control}
            id="newPassword"
            name="newPassword"
            placeholder="Enter a strong password of minimum 6 letters"
            rules={{ required: true, minLength: 6, maxLength: 20 }}
          />
        </FormControl>
        <FormControl
          label="Confirm password"
          htmlFor="confirmPassword"
          error={
            confirmPassword &&
            newPassword !== confirmPassword && (
              <span>Your password is not same!</span>
            )
          }
        >
          <Controller
            as={<Input.Password />}
            defaultValue=""
            control={control}
            id="confirmPassword"
            placeholder="Enter your password again"
            name="confirmPassword"
          />
        </FormControl>
        <Button
          className="action_btn"
          type="primary"
          htmlType="submit"
          size="large"
          style={{ width: "100%" }}
        >
          Update Password
        </Button>
        {message && <Alert message={message} type="success" showIcon />}
        <FormError errors={serverErrors} />
      </form>
    </>
  );
};

export default ForgotPasswordForm;
