import React, { useContext, useState, useRef } from "react";
import { Menu } from "antd";
import useOnClickOutside from "library/hooks/useOnClickOutside";
import { NavLink, withRouter, useHistory } from "react-router-dom";
import { AuthContext } from "context/AuthProvider";
import { useStoreCart } from "context/StoreCartProvider";
import {
  MY_ORDERS_PAGE,
  ACCOUNT_SETTINGS_PAGE,
  ADMIN_PANEL_PAGE,
} from "settings/constant";
import ProfileDetails from "./ProfileDetails";
import ProfileMenuWrapper from "./ProfileMenu.style";
import MyBusinessNavLink from "./MyBusinessNavLink";
import ProfilePicStub from "assets/images/profile-pic-stub.jpg";
import Logo from "components/UI/Logo/Logo";
import MyStoreSellerNavLink from "./MyStoreSellerNavLink";

const ProfileMenu = ({ user }) => {
  const history = useHistory();
  const { logOut, hasAnyPermission, hasAnyRole } = useContext(AuthContext);
  const { updateCart } = useStoreCart()
  const [isOpen, setIsOpen] = useState(false);

  const handleDropdown = () => {
    setIsOpen((isOpen) => !isOpen);
  };
  const closeDropdown = () => {
    setIsOpen(false);
  };
  const dropdownRef = useRef(null);

  useOnClickOutside(dropdownRef, () => setIsOpen(false));

  const handleLogout = () => {
    logOut();
    updateCart([]);
    history.push("/");
  };

  return (
    <ProfileMenuWrapper className="avatar-dropdown" ref={dropdownRef}>
      <div className="dropdown-handler" onClick={handleDropdown}>
        <Logo src={ProfilePicStub} />
      </div>
      <Menu className={`dropdown-menu ${isOpen ? "active" : "hide"}`}>
        <Menu.Item key="0">
          <ProfileDetails user={user} closeDropdown={closeDropdown} />
        </Menu.Item>
        <Menu.Item onClick={closeDropdown} key="2">
          <NavLink to={MY_ORDERS_PAGE}>My Orders</NavLink>
        </Menu.Item>
        <Menu.Item onClick={closeDropdown} key="5">
          <NavLink to={ACCOUNT_SETTINGS_PAGE}>Account Settings</NavLink>
        </Menu.Item>
        {hasAnyRole(["MERCHANT"])  && (
          <Menu.Item onClick={closeDropdown} key="3">
            <MyBusinessNavLink />
          </Menu.Item>
        )}
        {user.sellers?.length > 0 && (
          <Menu.Item onClick={closeDropdown} key="4">
            <MyStoreSellerNavLink />
          </Menu.Item>
        )}
        {hasAnyPermission(["SHOW_ADMIN_PANEL"]) && (
          <Menu.Item onClick={closeDropdown} key="6">
            <NavLink to={ADMIN_PANEL_PAGE}>Admin Panel</NavLink>
          </Menu.Item>
        )}
        <Menu.Item key="7">
          <button onClick={handleLogout}>Log Out</button>
        </Menu.Item>
      </Menu>
    </ProfileMenuWrapper>
  );
};

export default withRouter(ProfileMenu);
