import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";

export const ProfileDetails = styled.div`
  cursor: auto;
  min-width: 300px;
`;

export const NameWrapper = styled.div`
  padding: 20px;
  @media only screen and (max-width: 991px) {
    display: none;
  }
`;

export const DetailsWrapper = styled.div`
  background-color: ${themeGet("primary.0", "#008489")};
  padding: 20px;

  .ant-row {
    margin-top: 10px;
    color: ${themeGet("color.1", "#ffffff")};

    .ant-col {
      flex : 1;
      margin-right: 10px;
    }

    &:first-child {
      margin-top:0;
    }
  }

  .ant-typography {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 600;
    margin-top: 5px;
    color: ${themeGet("color.1", "#ffffff")};
    @media only screen and (max-width: 991px) {
      margin-top: 0;
    }
    .ant-typography-copy {
      color: ${themeGet("color.1", "#ffffff")};
    }
    .diskounto-coin-icon {
      margin-right: 5px;
    }
  }
}
`;

export default ProfileDetails;
