import React from 'react';
import isEmpty from 'lodash/isEmpty';
import Map from 'components/Map/Map';
import { FixedMap } from './NearbyBusiness.style';

const ListingMap = (props) => {
  const { data, loading, defaultCenter } = props;
  if (isEmpty(data) || loading) return <div>Loading</div>;

  return (
    <FixedMap>
      <Map data={data} multiple={true} defaultCenter={defaultCenter} />
    </FixedMap>
  );
};

export default ListingMap;
