import React from "react";

import { formatCurrency } from "library/helpers/utils";
import Text from "components/UI/Text/Text";
import { PriceArea } from "./ProductPrice.style";

const ProductPrice = ({ isLoggedIn, variant }) => {
    const { maximumPrice, sellingPrice, guestPrice } = variant;
  
    const sp = isLoggedIn ? sellingPrice : guestPrice;
    const mrp = maximumPrice;
    const discount = maximumPrice - (isLoggedIn ? sellingPrice : guestPrice);
  
    return (
      <PriceArea>
        <Text
          fontSize={14}
          fontWeight={700}
          as="span"
          content={formatCurrency(sp, 0, true)}
        />
        {sp !== mrp && (
          <>
            <Text
              color="gray"
              as="span"
              content={formatCurrency(mrp, 0, true)}
              pl={1}
              pr={2}
              style={{ textDecoration: "line-through" }}
            />
            <Text
              className="discount"
              as="span"
              content={`${formatCurrency(discount)} OFF`}
              pl={1}
            />
          </>
        )}
      </PriceArea>
    );
  };

  export default ProductPrice