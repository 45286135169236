import React, { useContext, useState } from "react";
import { Col, Input, Radio, Row, Select } from "antd";
import { Controller, useForm } from "react-hook-form";
import useSWR from "swr";

import { fetcher } from "library/hooks/useDataApi";
import { AuthContext } from "context/AuthProvider";
import FormControl from "components/UI/FormControl/FormControl";
import Button from "components/UI/Button/Button";
import FormError from "components/UI/FormError/FormError";
import MapWithSearchBox from "components/Map/MapSearchBox";
import { mapDataHelper } from "components/Map/mapDataHelper";
import { FormContent, FormAction, AffixBottom } from "./AddressForm.style";
import useSearchLocation from "library/hooks/useSearchLocation";
import { includes, lowerCase } from "lodash";

const initialState = {
  name: "",
  mobileNo: "",
  pin: "",
  locality: "",
  address: "",
  city: "",
  state: null,
  location: null,
  landmark: "",
  alternateMobileNo: "",
  addressType: "",
};

const UpsertAddressForm = ({
  mode,
  showMap = true,
  currentAddress,
  onSubmitSuccess,
}) => {
  const { data: stateData = [] } = useSWR(
    `${process.env.REACT_APP_API_URL}/state`
  );
  const { currentLocation } = useSearchLocation();
  const { setUser } = useContext(AuthContext);
  const [address] = useState({ ...initialState, ...currentAddress });
  const [serverErrors, setServerErrors] = useState([]);

  const { control, errors, formState, setValue, handleSubmit } = useForm();

  const onSubmit = async (data) => {
    setServerErrors([]);
    const method = mode === "CREATE" ? "POST" : "PUT";
    const path =
      mode === "CREATE" ? "/address" : `/address/${currentAddress.id}`;
    try {
      const result = await fetcher(
        `${process.env.REACT_APP_API_URL}${path}`,
        method,
        {},
        JSON.stringify({ ...data })
      );
      if (result.errors) {
        setServerErrors(result.errors);
      } else {
        setUser(result.user);
        onSubmitSuccess(result.user);
      }
    } catch (e) {
      // Handle errors here ex: Timeout or server
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormContent>
        <Row gutter={30}>
          {showMap ? (
            <Col xs={24}>
              <FormControl
                label="Set your location"
                htmlFor="addressType"
                error={errors.location && <span>This field is required!</span>}
              >
                <Controller
                  render={({ value, onChange }) => (
                    <MapWithSearchBox
                      name="location"
                      containerStyles={{ height: 300 }}
                      defaultValue={
                        address.location?.coordinates ||
                        (currentLocation
                          ? [currentLocation.lat, currentLocation.lng]
                          : null)
                      }
                      updateValue={(newValue) => {
                        const tempLocationData = mapDataHelper(newValue);
                        if (tempLocationData.length) {
                          if (tempLocationData[0].city) {
                            setValue("city", tempLocationData[0].city);
                          }
                          if (tempLocationData[0].state_short) {
                            const state = stateData.find(
                              (s) =>
                                s.stateCode ===
                                  tempLocationData[0].state_short ||
                                s.state === tempLocationData[0].state_long
                            );
                            if (state) {
                              setValue("stateCode", state.stateCode);
                            }
                          }
                          if (tempLocationData[0].zipcode) {
                            setValue("pin", tempLocationData[0].zipcode);
                          }
                          onChange({
                            type: "Point",
                            coordinates: [
                              tempLocationData[0].lat,
                              tempLocationData[0].lng,
                            ],
                            data: tempLocationData[0],
                          });
                        }
                      }}
                      markerProps={{
                        draggable: true,
                      }}
                    />
                  )}
                  id="location"
                  name="location"
                  defaultValue={address.location}
                  control={control}
                  rules={{ required: true }}
                />
              </FormControl>
            </Col>
          ) : null}
          <Col xs={24}>
            <FormControl
              label="Address Type"
              htmlFor="addressType"
              error={errors.addressType && <span>This field is required!</span>}
            >
              <Controller
                render={({ value, onChange }) => (
                  <Radio.Group
                    options={[
                      { label: "Home", value: "HOME" },
                      { label: "Office", value: "OFFICE" },
                    ]}
                    value={value}
                    defaultValue={address.addressType}
                    onChange={(e) => onChange(e.target.value)}
                  />
                )}
                id="addressType"
                name="addressType"
                defaultValue={address.addressType}
                control={control}
                rules={{ required: true }}
              />
            </FormControl>
          </Col>
          <Col xs={24} lg={12}>
            <FormControl
              label="Receiver name"
              htmlFor="name"
              error={errors.name && <span>Enter a valid name</span>}
            >
              <Controller
                as={<Input />}
                id="name"
                name="name"
                placeholder="Name"
                defaultValue={address.name}
                control={control}
                rules={{ required: true }}
              />
            </FormControl>
          </Col>
          <Col xs={24} lg={12}>
            <FormControl
              label="Mobile No"
              htmlFor="mobileNo"
              error={
                errors.mobileNo && (
                  <span>Enter a valid 10 digit mobile number</span>
                )
              }
            >
              <Controller
                as={<Input />}
                id="mobileNo"
                name="mobileNo"
                placeholder="10 digit mobile number"
                defaultValue={address.mobileNo}
                control={control}
                rules={{ required: true, maxLength: 10, minLength: 10 }}
              />
            </FormControl>
          </Col>
          <Col xs={24} lg={12}>
            <FormControl
              label="Pincode"
              htmlFor="pin"
              error={errors.pin && <span>Enter a valid 6 digit PIN</span>}
            >
              <Controller
                as={<Input />}
                id="pin"
                name="pin"
                placeholder="Pincode"
                defaultValue={address.pin}
                control={control}
                rules={{ required: true, minLength: 6, maxLength: 6 }}
              />
            </FormControl>
          </Col>
          <Col xs={24} lg={12}>
            <FormControl
              label="Area, Colony, Street, Sector, Village"
              htmlFor="locality"
              error={
                errors.locality && (
                  <span>Enter few more details about locality</span>
                )
              }
            >
              <Controller
                as={<Input />}
                id="locality"
                name="locality"
                placeholder="Locality"
                defaultValue={address.locality}
                control={control}
                rules={{ required: true }}
              />
            </FormControl>
          </Col>
          <Col xs={24}>
            <FormControl
              label="Flat, House no, Building, Company, Apartment"
              htmlFor="address"
              error={
                errors.address && (
                  <span>Enter few more details about flat, house no</span>
                )
              }
            >
              <Controller
                as={<Input.TextArea rows={3} />}
                id="address"
                name="address"
                placeholder="Address (Area and Street)"
                defaultValue={address.address}
                control={control}
                rules={{ required: true }}
              />
            </FormControl>
          </Col>
          <Col xs={24} lg={12}>
            <FormControl
              label="Town / City"
              htmlFor="city"
              error={errors.city && <span>Enter your city name</span>}
            >
              <Controller
                as={<Input />}
                id="city"
                name="city"
                placeholder="City/District/Town"
                defaultValue={address.city}
                control={control}
                rules={{ required: true }}
              />
            </FormControl>
          </Col>
          <Col xs={24} lg={12}>
            <FormControl
              label="State / Province / Region"
              htmlFor="stateCode"
              error={errors.stateCode && <span>Please select your state</span>}
            >
              <Controller
                as={
                  <Select
                    getPopupContainer={(triggerNode) =>
                      triggerNode.parentElement
                    }
                    showSearch
                    filterOption={(inputValue, option) => {
                      return (
                        includes(
                          lowerCase(option.label),
                          lowerCase(inputValue)
                        ) ||
                        includes(lowerCase(option.value), lowerCase(inputValue))
                      );
                    }}
                    options={stateData.map((item) => ({
                      label: item.state,
                      value: item.stateCode,
                    }))}
                  />
                }
                id="stateCode"
                name="stateCode"
                placeholder="Select State"
                defaultValue={address.stateCode}
                control={control}
                rules={{ required: true }}
              />
            </FormControl>
          </Col>
          <Col xs={24} lg={12}>
            <FormControl
              label="Landmark"
              htmlFor="landmark"
              error={
                errors.landmark && (
                  <span>Enter few more details about nearby landmark</span>
                )
              }
            >
              <Controller
                as={<Input />}
                id="landmark"
                name="landmark"
                placeholder="Landmark"
                defaultValue={address.landmark}
                control={control}
                rules={{ required: true }}
              />
            </FormControl>
          </Col>
          <Col xs={24} lg={12}>
            <FormControl
              label="Alternate mobile No"
              htmlFor="alternateMobileNo"
              error={
                errors.alternateMobileNo && (
                  <span>Enter a valid 10 digit alternate mobile number</span>
                )
              }
            >
              <Controller
                as={<Input />}
                id="alternateMobileNo"
                name="alternateMobileNo"
                placeholder="Alternate Mobile No (Optional)"
                defaultValue={address.alternateMobileNo}
                control={control}
                rules={{ required: false }}
              />
            </FormControl>
          </Col>
        </Row>
      </FormContent>
      <AffixBottom>
        <FormError errors={serverErrors} />
        <FormAction>
          <Button
            loading={formState.isSubmitting}
            htmlType="submit"
            type="primary"
            size="large"
          >
            Save
          </Button>
        </FormAction>
      </AffixBottom>
    </form>
  );
};

export default UpsertAddressForm;
