import React from 'react'
import { Modal } from 'antd'

import { fetcher } from 'library/hooks/useDataApi'
import Box from 'components/UI/Box/Box'
import Button from 'components/UI/Button/Button'
import Text from 'components/UI/Text/Text'
import { useBaseData } from 'context/BaseDataProvider'

const MyOrderActions = ({ order, onOrderChange = () => { } }) => {
  const { preferences: { contactNumbers = [], contactEmails = [] }, getAssetUrl } = useBaseData()
  const handleOrder = async (event) => {
    try {
      const result = await fetcher(`${process.env.REACT_APP_API_URL}/nearby/my-orders/${order.orderId}`, "PUT", {}, JSON.stringify({ event }));
      if (result.errors) {
        Modal.error({
          title: "Something Error Occurred",
          content: result.errors[0].userMessage
        })
      } else {
        switch (event) {
          case 'COMPLETE': {
            Modal.success({
              title: "Congrats!",
              content: "The order is marked delivered"
            })
            break;
          }
          default: { }
        }

        onOrderChange(result.order);
      }
    } catch (e) {
      // Handle errors here ex: Timeout or server
    }
  }
  return (
    <Box flexBox flexDirection="column" justifyContent="flex-end" className="order-actions">
      {order.orderState === "CREATED" && (
        <Button
          //type="primary"
          wrapperClassName="action-button"
          onClick={() => {
            Modal.confirm({
              title: "Please Confirm",
              content: <div>Are you sure! This is irreversible process. The applicable refund will be processed to source within 5-7 working days ?</div>,
              onOk: () => handleOrder("CANCEL"),
              okText: "Cancel Order",
              cancelText: "Don't Cancel"
            })
          }}
        >
          Cancel Order
        </Button>
      )}
      {["BOOM_DELIVERY", "BOOM_PICKUP", "BOOM_OFFLINE"].includes(order.orderType) && order.orderState === "SHIPPED" && (
        <Button
          wrapperClassName="action-button"
          onClick={() => {
            Modal.confirm({
              title: "Please Confirm",
              content: <div>Are you sure! After confirmation you will not be allowed to return or replace items ?</div>,
              onOk: () => handleOrder("DELIVER"),
              okText: "Mark as Delivered"
            })
          }}
        >
          Mark as Delivered
        </Button>
      )}
      {order.orderState === "COMPLETE" && order.invoice?.path && (
        <Button
          onClick={() => {
            window.open(getAssetUrl(order.invoice?.path), '_blank')
          }}
          wrapperClassName="action-button">
          Download Invoice
        </Button>
      )}
      <Button
        wrapperClassName="action-button"
        onClick={() => {
          Modal.info({
            title: "Contact Us",
            content: (
              <>
                <Box flexBox flexDirection="column">
                  <Text content="For any issues or complains please contact us on below email or phone" />
                  <b>
                    {contactEmails.map(email => <a style={{ display: "block" }} href={`mailto:${email}`}>{email}</a>)}
                    {contactNumbers.map(number => <a style={{ display: "block" }} href={`tel:${number}`}>{number}</a>)}
                  </b>
                </Box>
              </>
            ),
          })
        }}
      >
        Contact Us
      </Button>
    </Box>
  )
}

export default MyOrderActions
