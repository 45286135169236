import React from "react";
import { Link } from "react-router-dom";

import { SIGN_UP_PAGE } from "settings/constant";
import { useBaseData } from "context/BaseDataProvider";
import Logo from "components/UI/Logo/Logo";
import authPageImage from "assets/images/sign-in.svg";
import SignInForm from "./SignInForm";
import Wrapper, {
  Text,
  FormWrapper,
  BannerWrapper,
  BannerImageWrapper,
  ImgQuote,
} from "../Auth.style";

const SignIn = () => {
  const {
    preferences: { fullLogoUrl },
  } = useBaseData();
  return (
    <Wrapper>
      <FormWrapper>
        <Logo withLink linkTo="/" src={fullLogoUrl} />
        <SignInForm />
        <Text>
          Don't Have an Account?&nbsp;
          <Link to={SIGN_UP_PAGE}>Registration</Link>
        </Text>
      </FormWrapper>
      <BannerWrapper>
        <BannerImageWrapper>
          <img src={authPageImage} alt="" />
          <ImgQuote>
            "Networking is not about just connecting people. It's about
            connecting people with people, people with ideas, and people with
            opportunities."
          </ImgQuote>
        </BannerImageWrapper>
      </BannerWrapper>
    </Wrapper>
  );
};

export default SignIn;
