import React from "react";
import { NavLink } from "react-router-dom";
import { Divider } from "antd";

import { STORE_PRODUCT_SEARCH_PAGE } from "settings/constant";
import Container from "components/UI/Container/Container";
import Box from "components/UI/Box/Box";
import useDataApi from "library/hooks/useDataApi";
import NavigationArea from "./StoreNavMenu.style";
import Text from "components/UI/Text/Text";

const StoreNavMenu = () => {
  const url = `${process.env.REACT_APP_API_URL}/search/store-sub-category`;
  const { data: subCategories = [] } = useDataApi(`${url}`, 1000);
  return (
    <NavigationArea>
      <Container fluid={true} className="navigation-container">
        <Box flexBox flexDirection="row" className="links-container">
          {subCategories.map((item) => (
            <NavLink
              key={item.id}
              exact
              to={`${STORE_PRODUCT_SEARCH_PAGE}?subCategory=${item.subCategoryCode}`}
              style={{ padding: 0 }}
            >
              <Box flexBox flexDirection="column" alignItems="center" p={["4px 8px", "6px 8px", "4px 8px", "4px 8px", "4px 12px", "4px 12px"]}>
                <Text fontWeight="400" fontSize={["10px", "10px", "12px", "12px", "14px", "14px"]} content={item.subCategoryName} />
              </Box>
            </NavLink>
          ))}
        </Box>
      </Container>
      <Divider style={{ margin: 0 }}/>
    </NavigationArea>
  );
};

export default StoreNavMenu;
