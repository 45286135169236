import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";
const UnderMaintenanceWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    height: 100vh;
    @media only screen and (min-width: 1901px) {
      max-width: 100%;
      height: auto;
    }
    @media only screen and (max-width: 667px) {
      max-width: 100%;
      height: auto;
    }
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;

  h2 {
    font-size: 18px;
    font-weight: 700px;
    margin: 56px auto 30px;
    color: ${themeGet("primary.0", "#008489")};
  }

  a {
    height: 37px;
    padding: 0 14px;
    font-size: 15px;
    box-shadow: none;
    font-weight: 700;
    text-shadow: none;
    display: inline-flex;
    align-items: center;
    border-radius: 3px;
    color: ${themeGet("color.1", "#ffffff")};
    border-color: ${themeGet("primary.0", "#008489")};
    background-color: ${themeGet("primary.0", "#008489")};
    &:focus,
    &:hover {
      outline: 0;
      color: ${themeGet("color.1", "#ffffff")};
      border-color: ${themeGet("secondary.0", "#f7350d")};
      background-color: ${themeGet("secondary.0", "#f7350d")};
    }
  }
`;

export default UnderMaintenanceWrapper;
