import React from "react";
import { Col, Row } from "antd";
import Sticky from "react-stickynode";

import SortAndFilter, { MobileSortAndFilter } from "./SortAndFilter";
import NearbyOrders from "./NearbyOrders";
import StoreOrders from "./StoreOrders";
import { MyOrdersWrapper } from "./MyOrders.style";
import { getStateFromUrl } from "library/helpers/url_handler";
import Text from "components/UI/Text/Text";
import Container from "components/UI/Container/Container";
import Box from "components/UI/Box/Box";
import StickyBottomBox from "components/StickeyBottomBox/StickyBottomBox";

const MyOrders = ({ history, location }) => {
  const searchParams = getStateFromUrl(location);
  return (
    <MyOrdersWrapper>
      <Container fluid={true} noGutter>
        <Box
          flexBox
          alignItems="center"
          justifyContent="center"
          height={[100, 125, 150]}
          mb={3}
          bg="#f1f1f1"
        >
          <Text
            fontSize={[20, 24, 30]}
            as="h2"
            fontWeight="600"
            content="My Orders"
          />
        </Box>
      </Container>
      <Container fluid={false} noGutter>
        <Row gutter={24}>
          <Col xs={0} md={8} lg={5}>
            <Sticky
              top={91}
              innerZ={999}
              activeClass="isFilterSticky"
              bottomBoundary=".ant-layout-content"
            >
              <SortAndFilter history={history} location={location} />
            </Sticky>
          </Col>
          <Col xs={24} md={16} lg={19} id="orders">
            {searchParams.orderType === "store" && (
              <StoreOrders history={history} location={location} />
            )}
            {searchParams.orderType === "nearby" && (
              <NearbyOrders history={history} location={location} />
            )}
          </Col>
          <Col xs={24} sm={0}>
            <StickyBottomBox>
              <MobileSortAndFilter history={history} location={location} />
            </StickyBottomBox>
          </Col>
        </Row>
      </Container>
    </MyOrdersWrapper>
  );
};

export default MyOrders;
