import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { List, Tag, Modal, Empty } from "antd";
import styled from "styled-components";
import themeGet from "@styled-system/theme-get";

import useDataApi from "library/hooks/useDataApi";
import {
  ADD_MY_BUSINESS_PAGE,
  MANAGE_MY_BUSINESS_PATH,
} from "settings/constant";
import Box from "components/UI/Box/Box";
import TextLink from "components/UI/TextLink/TextLink";
import Button from "components/UI/Button/Button";
import Text from "components/UI/Text/Text";

export const TextLinkWrapper = styled.div`
  a {
    color: black;
    &:hover {
      color: ${themeGet('text.2', '#777777')};
    }
  }
`;

const MyBusinessNavLink = ({
  text = "Business Panel",
  hideIfOnlyBusiness = false,
  className = ""
}) => {
  const history = useHistory();
  const { data: business = [] } = useDataApi(
    `${process.env.REACT_APP_API_URL}/my-business`,
    1000
  );
  const [showModal, setShowModal] = useState();

  const getApprovalStatus = (item) => {
    if (item.approved) {
      return { content: "Active", color: "green" };
    } else if (item.status === "REJECTED") {
      return { content: "Disabled", color: "red" };
    } else {
      return { content: "In Review", color: "cyan" };
    }
  };

  const handleClick = () => {
    setShowModal(true);
  };

  if (hideIfOnlyBusiness && (!business || business.length === 0)) {
    return <></>;
  }

  return (
    <>
      {/* eslint-disable jsx-a11y/anchor-is-valid */}
      <a onClick={handleClick} className={className} >{text}</a>
      <Modal
        title={
          <Box>
            <Box
              flexBox
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              pr={4}
            >
              <Text content="Select A Business" />
              {business.length !== 0 && (
                <Button
                  type="secondary"
                  onClick={() => history.push(ADD_MY_BUSINESS_PAGE)}
                >
                  ADD NEW BUSINESS
                </Button>
              )}
            </Box>
          </Box>
        }
        destroyOnClose
        onCancel={() => {
          setShowModal(false);
        }}
        open={showModal}
        footer={null}
      >
        {!business || business.length === 0 ? (
          <Empty description={"It seems you have not listed any business"}>
            <Button
              size="large"
              type="primary"
              onClick={() => history.push(ADD_MY_BUSINESS_PAGE)}
            >
              ADD NEW BUSINESS
            </Button>
          </Empty>
        ) : (
          <List
            bordered={false}
            locale={{ emptyText: "It seems you have not listed any business" }}
            rowKey={(item) => item.id}
            dataSource={business || []}
            renderItem={(item) => {
              const statusProps = getApprovalStatus(item);
              return (
                <List.Item>
                  <Box
                    key={item.id}
                    flexBox
                    flex={1}
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <TextLinkWrapper>
                      <TextLink
                        to={`${MANAGE_MY_BUSINESS_PATH}/${item.slug}/${item.id}`}
                        onClick={() => setShowModal(false)}
                        fontWeight="600"
                        style={{ textTransform: "uppercase" }}
                        content={item.name}
                      />
                    </TextLinkWrapper>
                    <Tag color={statusProps.color}>{statusProps.content}</Tag>
                  </Box>
                </List.Item>
              );
            }}
          />
        )}
      </Modal>
    </>
  );
};

export default MyBusinessNavLink;
