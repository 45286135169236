import React from "react";

import Footer from "components/Footer/Footer";

const FooterSection = () => {
  return (
    <Footer />
  );
};

export default FooterSection;
