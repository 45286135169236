import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ReactMultiCarouselWapper from "./ReactMultiCarousel.style";

const ReactMultiCarousel = ({
  id,
  gap = 0,
  additionalTransfrom = 0,
  autoPlay=false,
  autoPlaySpeed = 0,
  transitionDuration = 0,
  arrows = true,
  showDots = true,
  draggable = true,
  swipeable = true,
  infinite = true,
  focusOnSelect = false,
  renderDotsOutside = false,
  responsive = {
    xs: { items: 1 },
    sm: { items: 1 },
    md: { items: 1 },
    lg: { items: 1 },
    xl: { items: 1 },
    xxl: { items: 1 },
  },
  ...props
}) => {
  return (
    <ReactMultiCarouselWapper>
      <Carousel
        containerClass={`react-multi-carousel`}
        dotListClass={`react-multi-carousel__dot `}
        itemClass={`react-multi-carousel__item`}
        sliderClass={`react-multi-carousel__slide`}
        additionalTransfrom={additionalTransfrom}
        autoPlay={autoPlay}
        autoPlaySpeed={autoPlaySpeed}
        transitionDuration={transitionDuration}
        arrows={arrows}
        showDots={showDots}
        draggable={draggable}
        swipeable={swipeable}
        infinite={infinite}
        focusOnSelect={focusOnSelect}
        renderDotsOutside={renderDotsOutside}
        responsive={{
          xxl: { breakpoint: { max: 10000, min: 1600 }, ...responsive.xxl },
          xl: { breakpoint: { max: 1599, min: 1200 }, ...responsive.xl },
          lg: { breakpoint: { max: 1199, min: 992 }, ...responsive.lg },
          md: { breakpoint: { max: 991, min: 768 }, ...responsive.md },
          sm: { breakpoint: { max: 767, min: 576 }, ...responsive.sm },
          xs: { breakpoint: { max: 575, min: 0 }, ...responsive.xs },
        }}
        {...props}
      />
    </ReactMultiCarouselWapper>
  );
};

export default ReactMultiCarousel;
