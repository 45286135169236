import React from "react";
import { get } from "lodash";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { useBaseData } from "context/BaseDataProvider";
import Image from "components/UI/Image/Image";
import Box from "components/UI/Box/Box";
import ReactMultiCarousel from "components/UI/ReactMultiCarousel/ReactMultiCarousel";
import LinkTo from "components/UI/LinkTo/LinkTo";
import { getImageDimensions, responsive } from "../utils";

const Carousel = ({ section }) => {
  const { getAssetUrl } = useBaseData();

  const itemsPerView = responsive(
    get(section, "configuration.itemsPerView"),
    []
  );
  const infinite =
    ["true", true].includes(get(section, "configuration.infinite")) || false;
  const autoPlaySpeed = get(section, "configuration.autoPlaySpeed") || 0;
  const transitionDuration =
    get(section, "configuration.transitionDuration") || 0;

  return (
    <ReactMultiCarousel
      id={section.id}
      arrows={true}
      showDots={true}
      autoPlay={Number(autoPlaySpeed) > 0}
      autoPlaySpeed={Number(autoPlaySpeed)}
      transitionDuration={Number(transitionDuration)}
      draggable={true}
      swipeable={true}
      infinite={infinite}
      focusOnSelect={false}
      renderDotsOutside={false}
      responsive={{
        xxl: { items: itemsPerView[5] },
        xl: { items: itemsPerView[4] },
        lg: { items: itemsPerView[3] },
        md: { items: itemsPerView[2] },
        sm: { items: itemsPerView[1] },
        xs: { items: itemsPerView[0] },
      }}
    >
      {section.components.map((component, index) => (
        <LinkTo key={index} to={component.linkTo}>
          <Box p={responsive(get(section, "configuration.imagePadding"), 0)}>
            <Image
              as={LazyLoadImage}
              alt=""
              width="100%"
              style={{
                aspectRatio: getImageDimensions(component.photo[0]?.meta)
                  .aspectRatio,
              }}
              src={getAssetUrl(component.photo[0]?.path)}
              borderRadius={responsive(
                get(section, "configuration.imageRadius"),
                0
              )}
            />
          </Box>
        </LinkTo>
      ))}
    </ReactMultiCarousel>
  );
};

export default Carousel;
