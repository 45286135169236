import React, { useEffect, useState } from "react";
import { Col, Collapse, InputNumber, Row, Slider } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { get } from "lodash";

import Box from "components/UI/Box/Box";
import useDebounce from "library/hooks/useDebounce";
import FormControl from "components/UI/FormControl/FormControl";
import Text from "components/UI/Text/Text";

const { Panel } = Collapse;

const FilterPrice = ({ label, value, onChange }) => {
  return (
    <Box bg="#f6f6f6" width="100%" mb={2}>
      <Collapse
        defaultActiveKey={value ? "1" : null}
        accordion={true}
        expandIconPosition="end"
        expandIcon={() => <DownOutlined />}
      >
        <Panel header={label} key="1">
          <FilterPricePanel value={value} onChange={onChange} />
        </Panel>
      </Collapse>
    </Box>
  );
};

export const FilterPricePanel = ({ value, onChange }) => {
  const MAX_PRICE = 10000;
  const [priceRange, setPriceRange] = useState([
    Number(get(value, "0", 0)),
    Number(get(value, "1", MAX_PRICE)),
  ]);
  const debouncePriceRange = useDebounce(priceRange.join(","), 1000);

  useEffect(() => {
    if (priceRange[0] === 0 && priceRange[1] === MAX_PRICE) {
      onChange([]);
    } else {
      onChange(priceRange);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncePriceRange]);

  return (
    <>
      <Slider
        range
        min={0}
        max={MAX_PRICE}
        value={priceRange}
        onChange={(value) => setPriceRange(value)}
        tooltip={{
          formatter: (value) => `₹${value}`,
        }}
        marks={{
          0: <Text fontSize={[10]} content="₹0" />,
          [MAX_PRICE]: <Text color="#f50" fontWeight={600} fontSize={[10]} content="Max" />,
        }}
      />
      <Row gutter={24}>
        <Col span={12}>
          <FormControl>
            <InputNumber
              style={{ width: "100%", height: 40 }}
              min={0}
              max={priceRange[1]}
              value={priceRange[0]}
              formatter={(value) => `₹${value}`}
              parser={(value) => value.replace("₹", "")}
              onChange={(value) => setPriceRange([value, priceRange[1]])}
            />
          </FormControl>
        </Col>
        <Col span={12}>
          <FormControl>
            <InputNumber
              style={{ width: "100%", height: 40 }}
              min={priceRange[0]}
              max={MAX_PRICE}
              value={priceRange[1]}
              formatter={(value) => `₹${value}`}
              parser={(value) => value.replace("₹", "")}
              onChange={(value) => setPriceRange([priceRange[0], value])}
            />
          </FormControl>
        </Col>
      </Row>
    </>
  );
};

export default FilterPrice;
