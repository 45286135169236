import React from "react";
import PropTypes from "prop-types";
import GridCardWrapper, {
  ImageWrapper,
  FavoriteIcon,
  ContentWrapper,
  LocationArea,
  TitleArea,
  DescriptionArea,
  RatingArea,
  BoomIcon,
  TopLeftReviewText,
  MetaWrapper,
  OfferWrapper,
  ButtonGroup,
  FooterButton
} from "./BusinessCardContent.style";
import { Link } from "react-router-dom";

const BusinessCardContent = ({
  className,
  favorite,
  location,
  title,
  description,
  offer,
  rating,
  actionBtn,
  footerBtn,
  boomIcon,
  topLeftReviewText,
  linkTo,
  children,
}) => {
  let classes = actionBtn ? `has_btn ${className}` : className;
  return (
    <GridCardWrapper className={`grid_card ${classes}`.trim()} >
      <ImageWrapper className="media_wrapper">{children}</ImageWrapper>
      <ContentWrapper className="content_wrapper" href={linkTo}>
        <Link to={linkTo}>
        {location && <LocationArea>{location}</LocationArea>}
        {title && <TitleArea>{title}</TitleArea>}
        {description && (
          <DescriptionArea className="description">
            {description}
          </DescriptionArea>
        )}
        <OfferWrapper>{offer}</OfferWrapper>
        <MetaWrapper className="meta_wrapper">

          {rating && <RatingArea className="rating">{rating}</RatingArea>}
          {actionBtn ? (
            <ButtonGroup className="button_group">{actionBtn}</ButtonGroup>
          ) : null}
        </MetaWrapper>
        </Link>
      </ContentWrapper>
      {boomIcon && <BoomIcon>{boomIcon}</BoomIcon>}
      {topLeftReviewText && <TopLeftReviewText>{topLeftReviewText}</TopLeftReviewText>}
      {favorite && <FavoriteIcon>{favorite}</FavoriteIcon>}
      {footerBtn && <FooterButton className="footer-btn">{footerBtn}</FooterButton>}
    </GridCardWrapper>
  );
};

BusinessCardContent.propTypes = {
  className: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  price: PropTypes.string,
  review: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  offer: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  rating: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  location: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  editBtn: PropTypes.element,
  viewDetailsBtn: PropTypes.element,
};

export default BusinessCardContent;
